import { Common_GenericOptions__ToReactElement_Z46A53D36, Common_parseOptions, Common_GenericOptions_Parse_9AE2F7C, Common_GenericOptions__ToReactElement_Z6D3CD4B7 } from "../../Common.fs.js";
import * as react from "react";
import { keyValueList } from "../../../fable-library.3.7.12/MapUtil.js";
import { HTMLAttr } from "../../../Fable.React.7.4.3/Fable.React.Props.fs.js";
import { singleton } from "../../../fable-library.3.7.12/List.js";

export function checkbox(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, Common_parseOptions, "checkbox"), (props, children_1) => react.createElement("label", keyValueList(props, 1), ...children_1), children);
}

export function input(options) {
    return Common_GenericOptions__ToReactElement_Z46A53D36(Common_GenericOptions_Parse_9AE2F7C(options, Common_parseOptions, "checkbox", singleton(new HTMLAttr(159, "checkbox"))), (props) => react.createElement("input", keyValueList(props, 1)));
}

