import { Record } from "../../../fable_modules/fable-library.3.7.12/Types.js";
import { create as create_1, Args$reflection } from "./AnnotationsSection.js";
import { create as create_2, Args$reflection as Args$reflection_1 } from "./NotesSection.js";
import { create, Args$reflection as Args$reflection_2 } from "./AssessmentsSection.js";
import { record_type } from "../../../fable_modules/fable-library.3.7.12/Reflection.js";
import { AsyncResultComputationExpression_asyncResult, AsyncResultComputationExpression_AsyncResultBuilder__Return_1505, AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93, AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B } from "../../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { Pdf_Doc_create } from "../PatientReportHelpers.js";

export class ReportArgs extends Record {
    constructor(annotationsArgs, notesArgs, assessmentsArgs) {
        super();
        this.annotationsArgs = annotationsArgs;
        this.notesArgs = notesArgs;
        this.assessmentsArgs = assessmentsArgs;
    }
}

export function ReportArgs$reflection() {
    return record_type("Client.Patients.PatientReport.Sections.ReportSections.ReportArgs", [], ReportArgs, () => [["annotationsArgs", Args$reflection()], ["notesArgs", Args$reflection_1()], ["assessmentsArgs", Args$reflection_2()]]);
}

export function mkReportSections(args) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => {
        const doc = Pdf_Doc_create();
        doc.deletePage(1);
        return AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, create(doc, args.assessmentsArgs), (_arg1) => {
            const assessmentsSection = _arg1;
            assessmentsSection.addPage();
            return AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, create_1(assessmentsSection, args.annotationsArgs), (_arg2) => {
                const annotationsSection = _arg2;
                annotationsSection.addPage();
                return AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, create_2(annotationsSection, args.notesArgs), (_arg3) => AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, _arg3));
            });
        });
    });
}

