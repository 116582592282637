import { Union } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { FrameFile$reflection } from "./HistoricalCommon.js";
import { union_type, bool_type, class_type, lambda_type, unit_type, int32_type, option_type, string_type, array_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";

export class ThumbnailSliderProps extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["FrameFiles", "ThumbnailUrls", "SelectedFrameIndex", "OnThumbnailSelected", "ViewportContainer", "IsSingleView"];
    }
}

export function ThumbnailSliderProps$reflection() {
    return union_type("Client.Patients.Historical.ThumbnailSliderBindings.ThumbnailSliderProps", [], ThumbnailSliderProps, () => [[["Item", array_type(FrameFile$reflection())]], [["Item", array_type(option_type(string_type))]], [["Item", int32_type]], [["Item", lambda_type(int32_type, unit_type)]], [["Item", class_type("Browser.Types.HTMLElement", void 0, HTMLElement)]], [["Item", bool_type]]]);
}

