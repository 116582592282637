import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { fetch$ } from "../../fable_modules/Fable.Fetch.2.4.0/Fetch.fs.js";
import { empty } from "../../fable_modules/fable-library.3.7.12/List.js";
import { Union, Record } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { list_type, bool_type, string_type, union_type, record_type, int32_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";
import { AnatomicalRegionViewModel$reflection } from "../../Optiscan.Models/View/AnatomicalRegion.js";

export function dataUrlToBlob(url) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetch$(url, empty()).then((_arg1) => (_arg1.blob())))));
}

export class SelectedSequence extends Record {
    constructor(locationIndex, siteIndex, sequenceIndex) {
        super();
        this.locationIndex = (locationIndex | 0);
        this.siteIndex = (siteIndex | 0);
        this.sequenceIndex = (sequenceIndex | 0);
    }
}

export function SelectedSequence$reflection() {
    return record_type("Client.Patients.Historical.Common.SelectedSequence", [], SelectedSequence, () => [["locationIndex", int32_type], ["siteIndex", int32_type], ["sequenceIndex", int32_type]]);
}

export class LoadingState extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadingFileList", "LoadingDicoms", "Reloading", "Loaded", "Failed"];
    }
}

export function LoadingState$reflection() {
    return union_type("Client.Patients.Historical.Common.LoadingState", [], LoadingState, () => [[], [], [], [], []]);
}

export class SessionAnnotationNoteState extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Editing", "Deleting", "Nothing"];
    }
}

export function SessionAnnotationNoteState$reflection() {
    return union_type("Client.Patients.Historical.Common.SessionAnnotationNoteState", [], SessionAnnotationNoteState, () => [[], [], []]);
}

export class FrameFile extends Record {
    constructor(filePath, flagged) {
        super();
        this.filePath = filePath;
        this.flagged = flagged;
    }
}

export function FrameFile$reflection() {
    return record_type("Client.Patients.Historical.Common.FrameFile", [], FrameFile, () => [["filePath", string_type], ["flagged", bool_type]]);
}

export class AnatomicalRegionIntrimType extends Record {
    constructor(frameCount, locationName, siteName, sequenceName) {
        super();
        this.frameCount = (frameCount | 0);
        this.locationName = locationName;
        this.siteName = siteName;
        this.sequenceName = sequenceName;
    }
}

export function AnatomicalRegionIntrimType$reflection() {
    return record_type("Client.Patients.Historical.Common.AnatomicalRegionIntrimType", [], AnatomicalRegionIntrimType, () => [["frameCount", int32_type], ["locationName", string_type], ["siteName", string_type], ["sequenceName", string_type]]);
}

export class FlaggedImageListItem extends Record {
    constructor(locationIndex, siteIndex, sequenceIndex, frameIndex, locationName, siteName, sequenceName) {
        super();
        this.locationIndex = (locationIndex | 0);
        this.siteIndex = (siteIndex | 0);
        this.sequenceIndex = (sequenceIndex | 0);
        this.frameIndex = (frameIndex | 0);
        this.locationName = locationName;
        this.siteName = siteName;
        this.sequenceName = sequenceName;
    }
}

export function FlaggedImageListItem$reflection() {
    return record_type("Client.Patients.Historical.Common.FlaggedImageListItem", [], FlaggedImageListItem, () => [["locationIndex", int32_type], ["siteIndex", int32_type], ["sequenceIndex", int32_type], ["frameIndex", int32_type], ["locationName", string_type], ["siteName", string_type], ["sequenceName", string_type]]);
}

export const defaultFrameFile = new FrameFile("Unknown", false);

export class ScreenshotInfo extends Record {
    constructor(dataURL, name, description) {
        super();
        this.dataURL = dataURL;
        this.name = name;
        this.description = description;
    }
}

export function ScreenshotInfo$reflection() {
    return record_type("Client.Patients.Historical.Common.ScreenshotInfo", [], ScreenshotInfo, () => [["dataURL", string_type], ["name", string_type], ["description", string_type]]);
}

export class FileMetadata extends Record {
    constructor(locationNames, siteNames, sequenceNames, frames, flaggedImages, frameToSequenceMap) {
        super();
        this.locationNames = locationNames;
        this.siteNames = siteNames;
        this.sequenceNames = sequenceNames;
        this.frames = frames;
        this.flaggedImages = flaggedImages;
        this.frameToSequenceMap = frameToSequenceMap;
    }
}

export function FileMetadata$reflection() {
    return record_type("Client.Patients.Historical.Common.FileMetadata", [], FileMetadata, () => [["locationNames", list_type(string_type)], ["siteNames", list_type(list_type(string_type))], ["sequenceNames", list_type(list_type(list_type(string_type)))], ["frames", list_type(list_type(list_type(list_type(FrameFile$reflection()))))], ["flaggedImages", list_type(FlaggedImageListItem$reflection())], ["frameToSequenceMap", list_type(AnatomicalRegionViewModel$reflection())]]);
}

