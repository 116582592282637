import { Union } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { SessionViewModel$reflection } from "../../Optiscan.Models/View/Session.js";
import { option_type, string_type, array_type, union_type, list_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";
import { ErrorMessage$reflection } from "../../Optiscan.Models/ErrorMessage.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library.3.7.12/Choice.js";
import { Sort_SortInfo$1$reflection } from "../../Optiscan.SharedUI/Sort.js";
import { PatientNoteViewModel$reflection } from "../../Optiscan.Models/View/PatientNote.js";
import { OptiscanUserData$reflection, UserRole$reflection } from "../../Optiscan.Models/Security.js";
import { Security_AuthErrorMessage$reflection, Security_UserInfo$2$reflection } from "../../fable_modules/Webbler.Models.1.2.6/Api.fs.js";
import { AuthorizedUserInformation$reflection } from "../../Optiscan.Models/View/AuthorizedUserInformation.js";

export class PatientDetailsMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["GetSessions", "GetSessionsResult", "UpdateSessionScheduledListSortInfo", "UpdateSessionCompletedListSortInfo", "GetNotes", "GetNotesResult", "GetNoteAuthors", "GetNoteAuthorsResult", "NoteSelectItem", "NoteUpdateSortInfo", "NoteUpdateSearchInfo", "NoteTriggerSearch", "GoToCreated", "GoToSession", "GoToHistorical", "NavigateToSearch", "CreateGuestLink", "GetAuthorizedUsers", "GetAuthorizedUsersResult"];
    }
}

export function PatientDetailsMsg$reflection() {
    return union_type("Client.Patients.PatientDetails.Types.PatientDetailsMsg", [], PatientDetailsMsg, () => [[], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(SessionViewModel$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(SessionViewModel$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["Item", Sort_SortInfo$1$reflection(SessionViewModel$reflection())]], [["Item", Sort_SortInfo$1$reflection(SessionViewModel$reflection())]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(PatientNoteViewModel$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(PatientNoteViewModel$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["Item", list_type(PatientNoteViewModel$reflection())]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Security_UserInfo$2$reflection(UserRole$reflection(), OptiscanUserData$reflection())), Security_AuthErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Security_UserInfo$2$reflection(UserRole$reflection(), OptiscanUserData$reflection()))]], [["ErrorValue", Security_AuthErrorMessage$reflection()]]])]], [["item", PatientNoteViewModel$reflection()]], [["sortInfo", Sort_SortInfo$1$reflection(PatientNoteViewModel$reflection())]], [["searchInfo", option_type(string_type)]], [["searchInfo", option_type(string_type)]], [["Item", option_type(SessionViewModel$reflection())]], [["Item", option_type(SessionViewModel$reflection())]], [["Item", option_type(SessionViewModel$reflection())]], [], [["Item", SessionViewModel$reflection()]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(AuthorizedUserInformation$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(AuthorizedUserInformation$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]]]);
}

