import { equals, uncurry, createAtom } from "../../fable_modules/fable-library.3.7.12/Util.js";
import { ListDisplayModification_ListDisplayModification$2, Sort_SortInfo$1_getSortComparison_576B9F7E, Sort_SortInfo$1, Sort_SortDirection } from "../../Optiscan.SharedUI/Sort.js";
import { filter, tryFind as tryFind_1, ofArray, singleton, sortWith, item, map, empty } from "../../fable_modules/fable-library.3.7.12/List.js";
import { newGuid } from "../../fable_modules/fable-library.3.7.12/Guid.js";
import { AssessmentViewModel } from "../../Optiscan.Models/View/Assessment.js";
import { Assessment_TypeModule_all } from "../../Optiscan.Models/Shared.js";
import { assessmentEditModal, assessmentsCard, sessionsTableCard, sessionsTableColumns } from "./PatientReportView.js";
import { CrudListModification } from "../../Optiscan.SharedUI/Crud.js";
import { PatientReportMsg, PatientReportModel } from "./PatientReportTypes.js";
import { Cmd_none, Cmd_batch } from "../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { StateTreeNode$6, NavigationMsg$1, StateTreeMsg$4 } from "../../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { Option as Option_3, columns } from "../../fable_modules/Fulma.2.16.0/Layouts/Columns.fs.js";
import { Option, ISize, column } from "../../fable_modules/Fulma.2.16.0/Layouts/Column.fs.js";
import { Common_GenericOption, Screen } from "../../fable_modules/Fulma.2.16.0/Common.fs.js";
import { Option as Option_1, navbar } from "../../fable_modules/Fulma.2.16.0/Components/Navbar.fs.js";
import { Option as Option_2, button } from "../../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import * as react from "react";
import { append, singleton as singleton_1, delay, toList } from "../../fable_modules/fable-library.3.7.12/Seq.js";
import { Fa_IconOption, Fa_i } from "../../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { content, Header_title, header, card } from "../../fable_modules/Fulma.2.16.0/Components/Card.fs.js";
import { FormField_labeledField } from "../../Optiscan.SharedUI/Forms.js";
import { toString } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { Helpers_nothing } from "../../fable_modules/Fable.React.7.4.3/Fable.React.Helpers.fs.js";
import { Navigation_newUrl } from "../../fable_modules/Fable.Elmish.Browser.3.0.4/navigation.fs.js";
import { Page, pageHash } from "../../Pages.js";
import { securedUserManagementApi, securedApi } from "../../Api.js";
import { Common_eraseExceptionToAuthError } from "../../Common/Common.js";
import { error } from "../../Optiscan.SharedUI/Toast.js";
import { ErrorMessage, ErrorMessage_get_describe } from "../../Optiscan.Models/ErrorMessage.js";
import { Security_AuthErrorMessage_get_describe } from "../../fable_modules/Webbler.Models.1.2.6/Api.fs.js";
import { tryFind, ofArray as ofArray_1 } from "../../fable_modules/fable-library.3.7.12/Map.js";
import { map as map_1 } from "../../fable_modules/fable-library.3.7.12/Array.js";
import { PatientNoteViewModel } from "../../Optiscan.Models/View/PatientNote.js";
import { some, map as map_2, defaultArg } from "../../fable_modules/fable-library.3.7.12/Option.js";
import { mkReportSections } from "./Sections/MakeReportSections.js";
import { mkReportArgs } from "./PatientReportGeneration.js";
import { utcNow } from "../../fable_modules/fable-library.3.7.12/DateOffset.js";
import { toString as toString_1 } from "../../fable_modules/fable-library.3.7.12/Date.js";
import { AsyncResult_ofSuccess, AsyncResult_ofError } from "../../fable_modules/AsyncResult.0.3.0/Result.fs.js";

export let pdfDocument = createAtom(void 0);

export function patientReportView(currentUser) {
    return new StateTreeNode$6((deps) => {
        let comparer;
        const initialSortInfo = (lastColumnClicked) => (new Sort_SortInfo$1(lastColumnClicked, new Sort_SortDirection(0)));
        return [new PatientReportModel(deps.patient, deps.maybeSelectedSession, deps.sessions, empty(), empty(), map((assessmentType) => (new AssessmentViewModel(deps.patient.id, newGuid(), assessmentType, "")), Assessment_TypeModule_all), initialSortInfo(item(1, sessionsTableColumns)), singleton(new ListDisplayModification_ListDisplayModification$2(new CrudListModification(0), 0, (comparer = Sort_SortInfo$1_getSortComparison_576B9F7E(initialSortInfo(item(1, sessionsTableColumns))), (list) => sortWith(uncurry(2, comparer), list)))), true, true, true, void 0), Cmd_batch(ofArray([Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientReportMsg(2))), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientReportMsg(6))), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientReportMsg(8)))]))];
    }, (model, dispatch) => {
        let children_2, children, children_8, children_4, children_6;
        return columns(empty(), singleton(column(ofArray([new Option(0, new Screen(0), new ISize(18)), new Option(2, "full-page-content")]), ofArray([navbar(singleton(new Option_1(7, "columns tele-nav")), ofArray([(children_2 = [(children = [button(singleton(new Option_2(18, (_arg1) => {
            dispatch(new StateTreeMsg$4(0, new PatientReportMsg(1, model.session)));
        })), singleton("Back"))], react.createElement("div", {
            className: "tele-nav-back-button navbar-item no-margin",
        }, ...children))], react.createElement("div", {
            className: "navbar-start",
        }, ...children_2)), (children_8 = [(children_4 = toList(delay(() => {
            const isLoading = (model.loadingAnnotations ? true : model.loadingNotes) ? true : model.loadingAssessments;
            return singleton_1(button(ofArray([new Option_2(16, isLoading), new Option_2(13, isLoading), new Option_2(18, (_arg2) => {
                dispatch(new StateTreeMsg$4(0, new PatientReportMsg(10)));
            })]), ofArray([Fa_i(ofArray([new Fa_IconOption(11, "fas fa-plus"), new Fa_IconOption(14, singleton(["style", {
                marginRight: "0.5em",
            }]))]), []), "Generate New Report"])));
        })), react.createElement("div", {
            className: "navbar-item",
        }, ...children_4)), (children_6 = [button(ofArray([new Option_2(18, (_arg3) => {
        }), new Option_2(16, true), new Option_2(4)]), ofArray([Fa_i(ofArray([new Fa_IconOption(11, "fas fa-download"), new Fa_IconOption(14, singleton(["style", {
            marginRight: "0.5em",
        }]))]), []), "Download Current Report"]))], react.createElement("div", {
            className: "navbar-item",
        }, ...children_6))], react.createElement("div", {
            className: "navbar-end",
        }, ...children_8))])), columns(singleton(new Option_3(8, "patient-details-columns")), ofArray([column(ofArray([new Option(0, new Screen(0), new ISize(8)), new Option(2, "patient-details-column")]), ofArray([card(ofArray([new Common_GenericOption(0, "patient-search-card embed-card"), new Common_GenericOption(1, singleton(["style", {
            marginBottom: "2em",
        }]))]), ofArray([header(empty(), singleton(Header_title(empty(), singleton("Patient Details")))), content(empty(), singleton(columns(empty(), ofArray([column(empty(), ofArray([FormField_labeledField("Patient", "ID", model.patient.id, []), FormField_labeledField("Patient", "Site ID", model.patient.siteId, [])])), column(empty(), singleton(FormField_labeledField("Patient", "Date Created", toString(model.patient.dateCreated), [])))]))))])), sessionsTableCard(model, dispatch)])), column(singleton(new Option(2, "column--scrollable")), toList(delay(() => append(singleton_1(assessmentsCard(model.loadingAssessments, model.assessments, (assessment) => {
            dispatch(new StateTreeMsg$4(0, new PatientReportMsg(15, assessment)));
        })), delay(() => {
            const matchValue = model.selectedAssessment;
            return (matchValue != null) ? singleton_1(assessmentEditModal(matchValue, true, (arg_1) => {
                dispatch(new StateTreeMsg$4(0, new PatientReportMsg(16, arg_1)));
            }, (_arg4) => {
                dispatch(new StateTreeMsg$4(0, new PatientReportMsg(17)));
            }, (_arg5) => {
                dispatch(new StateTreeMsg$4(0, new PatientReportMsg(15, void 0)));
            })) : singleton_1(Helpers_nothing);
        })))))]))]))));
    }, uncurry(4, (msg_3) => ((tupledArg) => {
        const token = tupledArg[0];
        return (deps_1) => ((model_1) => {
            let matchValue_3, s_9, matchValue_6;
            if (msg_3.tag === 1) {
                return (msg_3.fields[0] != null) ? [model_1, Cmd_batch(ofArray([Navigation_newUrl(pageHash(new Page(6, model_1.patient.id, msg_3.fields[0].sessionId))), Cmd_OfFunc_result(new StateTreeMsg$4(4, new NavigationMsg$1(5, "Historical Comparison")))]))] : [model_1, Cmd_none()];
            }
            else if (msg_3.tag === 2) {
                const matchValue_1 = model_1.session;
                if (matchValue_1 != null) {
                    const s_8 = matchValue_1;
                    return [model_1, Cmd_OfAsyncWith_either((x) => {
                        Cmd_OfAsync_start(x);
                    }, securedApi(token).getAllNotesForSession, s_8.sessionId, (arg_2) => (new StateTreeMsg$4(0, new PatientReportMsg(3, arg_2))), (arg_3) => (new StateTreeMsg$4(1, Common_eraseExceptionToAuthError(arg_3))))];
                }
                else {
                    return [model_1, error("Error when getting notes: session is None")];
                }
            }
            else if (msg_3.tag === 3) {
                return (msg_3.fields[0].tag === 1) ? [model_1, error(ErrorMessage_get_describe()(msg_3.fields[0].fields[0]))] : [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, msg_3.fields[0].fields[0], model_1.assessments, model_1.sessionListSortInfo, model_1.sessionListModifications, model_1.loadingAssessments, model_1.loadingNotes, model_1.loadingAnnotations, model_1.selectedAssessment), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientReportMsg(4, msg_3.fields[0].fields[0])))];
            }
            else if (msg_3.tag === 4) {
                const ids = map((note) => note.createdById, msg_3.fields[0]);
                return [model_1, Cmd_OfAsyncWith_either((x_1) => {
                    Cmd_OfAsync_start(x_1);
                }, securedUserManagementApi(token).getUsersByIds, ids, (arg_5) => (new StateTreeMsg$4(0, new PatientReportMsg(5, arg_5))), (arg_6) => (new StateTreeMsg$4(1, Common_eraseExceptionToAuthError(arg_6))))];
            }
            else if (msg_3.tag === 5) {
                if (msg_3.fields[0].tag === 1) {
                    return [model_1, error(Security_AuthErrorMessage_get_describe()(msg_3.fields[0].fields[0]))];
                }
                else {
                    const authorsMap = ofArray_1(map_1((author) => [author.id, author.name], msg_3.fields[0].fields[0]));
                    return [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, map((note_1) => {
                        const matchValue_2 = tryFind(note_1.createdById, authorsMap);
                        if (matchValue_2 == null) {
                            return note_1;
                        }
                        else {
                            return new PatientNoteViewModel(note_1.noteId, note_1.patientId, note_1.note, note_1.dateCreated, note_1.createdById, matchValue_2, note_1.maybeSessionId);
                        }
                    }, model_1.sessionNotes), model_1.assessments, model_1.sessionListSortInfo, model_1.sessionListModifications, model_1.loadingAssessments, false, model_1.loadingAnnotations, model_1.selectedAssessment), Cmd_none()];
                }
            }
            else if (msg_3.tag === 6) {
                return [model_1, (matchValue_3 = model_1.session, (matchValue_3 != null) ? ((s_9 = matchValue_3, Cmd_OfAsyncWith_either((x_2) => {
                    Cmd_OfAsync_start(x_2);
                }, securedApi(token).getAllSessionAnnotationsForSessionId, s_9.sessionId, (arg_8) => (new StateTreeMsg$4(0, new PatientReportMsg(7, arg_8))), (arg_9) => (new StateTreeMsg$4(0, new PatientReportMsg(0, arg_9)))))) : error("Error when getting annotations: session is None"))];
            }
            else if (msg_3.tag === 7) {
                return (msg_3.fields[0].tag === 1) ? [model_1, error(ErrorMessage_get_describe()(msg_3.fields[0].fields[0]))] : [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, msg_3.fields[0].fields[0], model_1.sessionNotes, model_1.assessments, model_1.sessionListSortInfo, model_1.sessionListModifications, model_1.loadingAssessments, model_1.loadingNotes, false, model_1.selectedAssessment), Cmd_none()];
            }
            else if (msg_3.tag === 8) {
                return [model_1, Cmd_OfAsyncWith_either((x_3) => {
                    Cmd_OfAsync_start(x_3);
                }, securedApi(token).getAllAssessmentsForPatientId, model_1.patient.id, (arg_11) => (new StateTreeMsg$4(0, new PatientReportMsg(9, arg_11))), (arg_12) => (new StateTreeMsg$4(0, new PatientReportMsg(0, arg_12))))];
            }
            else if (msg_3.tag === 9) {
                return (msg_3.fields[0].tag === 1) ? [model_1, error(ErrorMessage_get_describe()(msg_3.fields[0].fields[0]))] : [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, map((oldAssessment) => defaultArg(tryFind_1((newAssessment) => (newAssessment.assessmentType === oldAssessment.assessmentType), msg_3.fields[0].fields[0]), oldAssessment), model_1.assessments), model_1.sessionListSortInfo, model_1.sessionListModifications, false, model_1.loadingNotes, model_1.loadingAnnotations, model_1.selectedAssessment), Cmd_none()];
            }
            else if (msg_3.tag === 10) {
                return [model_1, Cmd_OfAsyncWith_either((x_4) => {
                    Cmd_OfAsync_start(x_4);
                }, mkReportSections, mkReportArgs(model_1), (arg_14) => (new StateTreeMsg$4(0, new PatientReportMsg(11, arg_14))), (arg_15) => (new StateTreeMsg$4(0, new PatientReportMsg(0, arg_15))))];
            }
            else if (msg_3.tag === 11) {
                if (msg_3.fields[0].tag === 1) {
                    return [model_1, error(ErrorMessage_get_describe()(msg_3.fields[0].fields[0]))];
                }
                else {
                    pdfDocument(msg_3.fields[0].fields[0], true);
                    return [model_1, Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientReportMsg(12)))];
                }
            }
            else if (msg_3.tag === 12) {
                let sessionName;
                const matchValue_4 = model_1.session;
                sessionName = ((matchValue_4 != null) ? matchValue_4.sessionName : "unknown");
                let dateTimeString;
                let copyOfStruct = utcNow();
                dateTimeString = toString_1(copyOfStruct, "yyyy-MM-dd-HH-mm-ss");
                return [model_1, Cmd_OfAsyncWith_either((x_6) => {
                    Cmd_OfAsync_start(x_6);
                }, (filename) => {
                    if (pdfDocument() == null) {
                        return AsyncResult_ofError(new ErrorMessage(2, new Error("Error when saving report: Report is None")));
                    }
                    else {
                        const pdf = pdfDocument();
                        return AsyncResult_ofSuccess(pdf.save(filename));
                    }
                }, `Report_${sessionName}_${dateTimeString}.pdf`, (arg_17) => (new StateTreeMsg$4(0, new PatientReportMsg(13, arg_17))), (arg_18) => (new StateTreeMsg$4(0, new PatientReportMsg(0, arg_18))))];
            }
            else if (msg_3.tag === 13) {
                return (msg_3.fields[0].tag === 1) ? [model_1, error(ErrorMessage_get_describe()(msg_3.fields[0].fields[0]))] : [model_1, Cmd_none()];
            }
            else if (msg_3.tag === 14) {
                return [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, model_1.assessments, msg_3.fields[0], toList(delay(() => append(filter((modification) => (!equals(modification.Source, new CrudListModification(0))), model_1.sessionListModifications), delay(() => {
                    let comparer_1;
                    return singleton_1(new ListDisplayModification_ListDisplayModification$2(new CrudListModification(0), 0, (comparer_1 = Sort_SortInfo$1_getSortComparison_576B9F7E(msg_3.fields[0]), (list_7) => sortWith(uncurry(2, comparer_1), list_7))));
                })))), model_1.loadingAssessments, model_1.loadingNotes, model_1.loadingAnnotations, model_1.selectedAssessment), Cmd_none()];
            }
            else if (msg_3.tag === 15) {
                return [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, model_1.assessments, model_1.sessionListSortInfo, model_1.sessionListModifications, model_1.loadingAssessments, model_1.loadingNotes, model_1.loadingAnnotations, msg_3.fields[0]), Cmd_none()];
            }
            else if (msg_3.tag === 16) {
                return [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, model_1.assessments, model_1.sessionListSortInfo, model_1.sessionListModifications, model_1.loadingAssessments, model_1.loadingNotes, model_1.loadingAnnotations, map_2(msg_3.fields[0], model_1.selectedAssessment)), Cmd_none()];
            }
            else if (msg_3.tag === 17) {
                const matchValue_5 = model_1.selectedAssessment;
                if (matchValue_5 == null) {
                    return [model_1, Cmd_none()];
                }
                else {
                    const assessment_2 = matchValue_5;
                    return [new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, map((a) => {
                        if (a.assessmentId === assessment_2.assessmentId) {
                            return assessment_2;
                        }
                        else {
                            return a;
                        }
                    }, model_1.assessments), model_1.sessionListSortInfo, model_1.sessionListModifications, model_1.loadingAssessments, model_1.loadingNotes, model_1.loadingAnnotations, model_1.selectedAssessment), Cmd_OfAsyncWith_either((x_7) => {
                        Cmd_OfAsync_start(x_7);
                    }, securedApi(token).upsertAssessment, assessment_2, (arg_20) => (new StateTreeMsg$4(0, new PatientReportMsg(18, arg_20))), (arg_21) => (new StateTreeMsg$4(0, new PatientReportMsg(0, arg_21))))];
                }
            }
            else if (msg_3.tag === 18) {
                return (msg_3.fields[0].tag === 1) ? [model_1, error(ErrorMessage_get_describe()(msg_3.fields[0].fields[0]))] : [(matchValue_6 = tryFind_1((a_1) => (a_1.assessmentId === msg_3.fields[0].fields[0].assessmentId), model_1.assessments), (matchValue_6 == null) ? (new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, model_1.assessments, model_1.sessionListSortInfo, model_1.sessionListModifications, true, model_1.loadingNotes, model_1.loadingAnnotations, msg_3.fields[0].fields[0])) : (new PatientReportModel(model_1.patient, model_1.session, model_1.sessions, model_1.sessionAnnotations, model_1.sessionNotes, model_1.assessments, model_1.sessionListSortInfo, model_1.sessionListModifications, true, model_1.loadingNotes, model_1.loadingAnnotations, void 0))), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientReportMsg(8)))];
            }
            else {
                console.error(some(`Error: ${msg_3.fields[0]}`));
                return [model_1, Cmd_none()];
            }
        });
    })));
}

