import { toLocalTime } from "../../../fable_modules/fable-library.3.7.12/DateOffset.js";
import { compare, toString } from "../../../fable_modules/fable-library.3.7.12/Date.js";
import { comparePrimitives, uncurry } from "../../../fable_modules/fable-library.3.7.12/Util.js";
import { ListDisplayModification_ListDisplayModification$2_applyListModifications, Sort_itemViewListSortable, Sort_SortableColumnView$1, Sort_Compare$1 } from "../../../Optiscan.SharedUI/Sort.js";
import { CSSProp } from "../../../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { filter, empty, singleton, ofArray } from "../../../fable_modules/fable-library.3.7.12/List.js";
import { Tooltip_tooltip } from "../../../Optiscan.SharedUI/ComponentVisibility.js";
import * as react from "react";
import { PatientDetailsMsg } from "../PatientDetailsTypes.js";
import { StateTreeMsg$4 } from "../../../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { content, Header_icon, Header_title, header, card } from "../../../fable_modules/Fulma.2.16.0/Components/Card.fs.js";
import { Color_IColor, Common_GenericOption } from "../../../fable_modules/Fulma.2.16.0/Common.fs.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../../../fable_modules/fable-library.3.7.12/Seq.js";
import { Permission, isAllowed } from "../../../Optiscan.Models/Security.js";
import { CrudButton_navigateToChild } from "../../../Optiscan.SharedUI/Crud.js";
import { Option } from "../../../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";

export const sessionTableColumns_DateCreated = new Sort_SortableColumnView$1("Date Created", (svm) => {
    let copyOfStruct;
    return (copyOfStruct = toLocalTime(svm.dateCreated), toString(copyOfStruct, "yyyy-MM-dd HH:mm"));
}, uncurry(2, void 0), new Sort_Compare$1(0, (svm1, svm2) => compare(svm1.dateCreated, svm2.dateCreated)));

export const sessionTableColumns_SessionName = new Sort_SortableColumnView$1("Session Name", (svm) => svm.sessionName, uncurry(2, void 0), new Sort_Compare$1(0, (svm1, svm2) => comparePrimitives(svm1.sessionName, svm2.sessionName)));

export const sessionTableColumns_State = new Sort_SortableColumnView$1("State", (svm) => {
    let matchValue;
    return (matchValue = (svm.sessionState | 0), (matchValue === 0) ? "Session scheduled" : ((matchValue === 1) ? "Session active" : ((matchValue === 2) ? "Session completed" : ((matchValue === 3) ? "Session interrupted" : ((matchValue === 4) ? "Session active" : "Unknown")))));
}, uncurry(2, void 0), new Sort_Compare$1(0, (avm1, avm2) => comparePrimitives(avm1.sessionState, avm2.sessionState)));

export const sessionTableColumns_ScheduledTime = new Sort_SortableColumnView$1("Scheduled Time", (svm) => {
    let copyOfStruct;
    return (copyOfStruct = toLocalTime(svm.scheduledTime), toString(copyOfStruct, "yyyy-MM-dd HH:mm"));
}, uncurry(2, void 0), new Sort_Compare$1(0, (svm1, svm2) => compare(svm1.scheduledTime, svm2.scheduledTime)));

export function sessionTableColumns_Share(dispatch) {
    return new Sort_SortableColumnView$1("Guest Link", (svm) => {
        const toolTipStyling = ofArray([new CSSProp(395, "32px"), new CSSProp(226, "-0.1rem")]);
        const matchValue = svm.sessionState | 0;
        switch (matchValue) {
            case 2:
            case 3: {
                return Tooltip_tooltip("Only scheduled and active telepathology sessions can be shared", toolTipStyling, true, react.createElement("span", {
                    className: "share-link share-link--disabled",
                }, "--"));
            }
            default: {
                return Tooltip_tooltip("Generate Guest Link", toolTipStyling, true, react.createElement("span", {
                    className: "share-link share-link--enabled",
                }));
            }
        }
    }, (svm_1, _arg1) => {
        const matchValue_1 = svm_1.sessionState | 0;
        switch (matchValue_1) {
            case 2:
            case 3: {
                break;
            }
            default: {
                dispatch(new StateTreeMsg$4(0, new PatientDetailsMsg(16, svm_1)));
            }
        }
    }, new Sort_Compare$1(0, (svm1, svm2) => comparePrimitives(svm1.sessionName, svm2.sessionName)));
}

export function sessionScheduledTableColumns(dispatch) {
    return ofArray([sessionTableColumns_SessionName, sessionTableColumns_ScheduledTime, sessionTableColumns_Share(dispatch)]);
}

export function sessionTableColumns(dispatch) {
    return ofArray([sessionTableColumns_DateCreated, sessionTableColumns_SessionName, sessionTableColumns_State, sessionTableColumns_Share(dispatch)]);
}

export function sessionSelectedMouseEvent(toCreated, toLiveTelepathology, toHistorical, item, _arg1) {
    const matchValue = item.sessionState | 0;
    switch (matchValue) {
        case 0: {
            toCreated(item);
            break;
        }
        case 1:
        case 4: {
            toLiveTelepathology(item);
            break;
        }
        case 2:
        case 3: {
            toHistorical(item);
            break;
        }
        default: {
        }
    }
}

export function sessionScheduledTableCard(model, dispatch, toCreated, toLiveTelepathology, toHistorical, currentUser) {
    let children;
    return card(ofArray([new Common_GenericOption(0, " embed-card"), new Common_GenericOption(1, singleton(["style", {
        marginBottom: "2em",
    }]))]), ofArray([header(empty(), toList(delay(() => append(singleton_1(Header_title(empty(), singleton("Scheduled Sessions"))), delay(() => ((isAllowed(new Permission(36))(currentUser.roles) && (!model.accessRequested)) ? singleton_1(Header_icon(empty(), singleton(CrudButton_navigateToChild("Create Session Modal", "Schedule Session", [new Option(0, new Color_IColor(4))], dispatch)))) : empty_1())))))), content(empty(), singleton((children = [Sort_itemViewListSortable("Session", sessionScheduledTableColumns(dispatch), (item, arg40$0040) => {
        sessionSelectedMouseEvent(toCreated, toLiveTelepathology, toHistorical, item, arg40$0040);
    }, ListDisplayModification_ListDisplayModification$2_applyListModifications(model.sessionScheduledListModifications, filter((session) => (session.sessionState === 0), model.sessions)), (_arg1) => empty_1(), model.sessionScheduledListSortInfo, (sortInfo, _arg2) => {
        dispatch(new StateTreeMsg$4(0, new PatientDetailsMsg(2, sortInfo)));
    })], react.createElement("div", {
        className: "table-container",
    }, ...children))))]));
}

export function sessionTableCard(model, dispatch, toCreated, toLiveTelepathology, toHistorical) {
    let children;
    return card(ofArray([new Common_GenericOption(0, " embed-card"), new Common_GenericOption(1, singleton(["style", {
        marginBottom: "2em",
    }]))]), ofArray([header(empty(), singleton(Header_title(empty(), singleton("Sessions")))), content(empty(), singleton((children = [Sort_itemViewListSortable("Session", sessionTableColumns(dispatch), (item, arg40$0040) => {
        sessionSelectedMouseEvent(toCreated, toLiveTelepathology, toHistorical, item, arg40$0040);
    }, ListDisplayModification_ListDisplayModification$2_applyListModifications(model.sessionCompletedListModifications, filter((session) => (session.sessionState !== 0), model.sessions)), (_arg1) => empty_1(), model.sessionCompletedListSortInfo, (sortInfo, _arg2) => {
        dispatch(new StateTreeMsg$4(0, new PatientDetailsMsg(3, sortInfo)));
    })], react.createElement("div", {
        className: "table-container",
    }, ...children))))]));
}

