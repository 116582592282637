import { sessionTableColumns_DateCreated, sessionTableColumns_SessionName } from "../PatientDetails/PatientSessions/PatientSessionsView.js";
import { map, toArray, empty, singleton, ofArray } from "../../fable_modules/fable-library.3.7.12/List.js";
import { content, Header_title, header, card } from "../../fable_modules/Fulma.2.16.0/Components/Card.fs.js";
import { Common_GenericOption } from "../../fable_modules/Fulma.2.16.0/Common.fs.js";
import { ListDisplayModification_ListDisplayModification$2_applyListModifications, Sort_itemViewListSortable } from "../../Optiscan.SharedUI/Sort.js";
import { singleton as singleton_1, delay, toList, empty as empty_1 } from "../../fable_modules/fable-library.3.7.12/Seq.js";
import { PatientReportMsg } from "./PatientReportTypes.js";
import { StateTreeMsg$4 } from "../../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import * as react from "react";
import { Assessment_TypeModule_describe } from "../../Optiscan.Models/Shared.js";
import { HTMLAttr, DOMAttr } from "../../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { Fa_IconOption, Fa_i } from "../../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { keyValueList } from "../../fable_modules/fable-library.3.7.12/MapUtil.js";
import { substring } from "../../fable_modules/fable-library.3.7.12/String.js";
import { Card_foot, Card_body, Card_head, Card_card, background, Option, modal } from "../../fable_modules/Fulma.2.16.0/Components/Modal.fs.js";
import { FormField_onChangeSet, FormField_textArea } from "../../Optiscan.SharedUI/Forms.js";
import { Option as Option_1 } from "../../fable_modules/Fulma.2.16.0/Elements/Form/Textarea.fs.js";
import { uncurry } from "../../fable_modules/fable-library.3.7.12/Util.js";
import { AssessmentViewModel_get_assessment_ } from "../../Optiscan.Models/View/Assessment.js";
import { Optic_Set, Optic_Set_op_HatEquals_2170E4F5 } from "../../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { Functions_flip } from "../../fable_modules/Webbler.Models.1.2.6/Common.fs.js";
import { Option as Option_2, button } from "../../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";

export const sessionsTableColumns = ofArray([sessionTableColumns_SessionName, sessionTableColumns_DateCreated]);

export function sessionsTableCard(model, dispatch) {
    let children;
    return card(ofArray([new Common_GenericOption(0, " embed-card"), new Common_GenericOption(1, singleton(["style", {
        marginBottom: "2em",
    }]))]), ofArray([header(empty(), singleton(Header_title(empty(), singleton("Existing Sessions")))), content(empty(), singleton((children = [Sort_itemViewListSortable("Session", sessionsTableColumns, (_arg2, _arg1) => {
    }, ListDisplayModification_ListDisplayModification$2_applyListModifications(model.sessionListModifications, model.sessions), (_arg3) => empty_1(), model.sessionListSortInfo, (sortInfo, _arg4) => {
        dispatch(new StateTreeMsg$4(0, new PatientReportMsg(14, sortInfo)));
    })], react.createElement("div", {
        className: "table-container",
    }, ...children))))]));
}

export function assessmentsCardSection(assessment, onClick) {
    let children_2, props, children, children_4;
    const characterLimit = 280;
    const children_6 = [(children_2 = [Assessment_TypeModule_describe(assessment.assessmentType), (props = [["style", {
        marginLeft: "0.5em",
    }], new DOMAttr(40, (_arg1) => {
        onClick(assessment);
    })], (children = [Fa_i(singleton(new Fa_IconOption(11, "fas fa-edit")), [])], react.createElement("a", keyValueList(props, 1), ...children)))], react.createElement("header", {
        className: "assessments-card__section-header",
    }, ...children_2)), (children_4 = toList(delay(() => ((assessment.assessment.length > characterLimit) ? singleton_1(`${substring(assessment.assessment, 0, characterLimit)}...`) : ((assessment.assessment.length === 0) ? singleton_1("N/A") : singleton_1(assessment.assessment))))), react.createElement("p", {
        className: "assessments-card__section-content",
        onClick: (_arg2) => {
            onClick(assessment);
        },
    }, ...children_4))];
    return react.createElement("section", {
        className: "assessments-card__section",
    }, ...children_6);
}

export function assessmentsCard(isLoading, assessments, sectionEditHandler) {
    let children_2;
    const loadingClass = isLoading ? "is-loading" : "";
    return card(ofArray([new Common_GenericOption(0, `embed-card ${loadingClass}`), new Common_GenericOption(1, singleton(["style", {
        marginBottom: "2em",
    }]))]), ofArray([header(empty(), singleton((children_2 = [Header_title(empty(), singleton("Assessments")), react.createElement("p", {
        className: "card-header-subtitle",
    }, "These fields will be added as is to the report")], react.createElement("div", {
        className: "card-header-container",
    }, ...children_2)))), content(singleton(new Common_GenericOption(0, "assessments-card__content")), singleton(toArray(map((assessment) => assessmentsCardSection(assessment, sectionEditHandler), assessments))))]));
}

export function assessmentEditModal(assessment, isEditing, setter, saveCallback, cancelCallback) {
    let f, optic;
    return modal(singleton(new Option(1, isEditing)), ofArray([background(empty(), empty()), Card_card(empty(), ofArray([Card_head(empty(), singleton("Edit Assessment")), Card_body(empty(), singleton(FormField_textArea("Assessment", assessment.assessment, empty(), [new Option_1(14, singleton(new HTMLAttr(55, true)))], FormField_onChangeSet(setter, uncurry(2, (f = ((optic = AssessmentViewModel_get_assessment_(), (value) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic)(value))), (b) => ((a) => Functions_flip(uncurry(2, f), b, a))))), "PatientAssessment", Assessment_TypeModule_describe(assessment.assessmentType))([]))), Card_foot(empty(), ofArray([button(singleton(new Option_2(18, saveCallback)), singleton("Save")), button(singleton(new Option_2(18, cancelCallback)), singleton("Cancel"))]))]))]));
}

