import { FSharpRef, toString, Union } from "../fable_modules/fable-library.3.7.12/Types.js";
import { Prop, HTMLAttr, DOMAttr, DOMAttr$reflection } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { class_type, lambda_type, unit_type, option_type, union_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { structuralHash, curry, equals } from "../fable_modules/fable-library.3.7.12/Util.js";
import { String_toOption } from "../fable_modules/Webbler.Models.1.2.6/Extensions.fs.js";
import { bind, some } from "../fable_modules/fable-library.3.7.12/Option.js";
import { Optic_Get, Optic_Get_op_HatDot_21762A61, Optic_Set, Optic_Set_op_HatEquals_2170E4F5 } from "../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { toUniversalTime, tryParse, minValue, now, day, month, year, create, getUtcTicks, fromTicks } from "../fable_modules/fable-library.3.7.12/DateOffset.js";
import { Option as Option_2, Label_Option, label as label_1, div } from "../fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { contains, filter, empty as empty_1, map as map_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { Naming_UIElement, Naming_UIElement__get_asString, Naming_mkIdFromStrings } from "../fable_modules/Webbler.Models.1.2.6/Common.fs.js";
import { toArray, isEmpty, map, cons, empty, singleton, ofArray } from "../fable_modules/fable-library.3.7.12/List.js";
import { GroupType$1, SelectOptions_value, SelectOption$1, SelectPropsMulti$1 } from "./bindings/ReactSelectBind.js";
import { div as div_1 } from "../fable_modules/Fulma.2.16.0/Elements/Form/Control.fs.js";
import { Option, IInputType, input } from "../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { Option as Option_1, textarea } from "../fable_modules/Fulma.2.16.0/Elements/Form/Textarea.fs.js";
import { fromDateTimeOffset, today, toString as toString_1 } from "../fable_modules/fable-library.3.7.12/Date.js";
import * as react from "react";
import { map as map_2, tryFind, tryPick, equalsWith, tryFindIndex } from "../fable_modules/fable-library.3.7.12/Array.js";
import { printf, toText } from "../fable_modules/fable-library.3.7.12/String.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.12/MapUtil.js";
import react_select from "react-select";
import { Option as Option_3, button } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { MenuProps } from "./bindings/ReactMenuBind.js";
import { MenuItem, SubMenu, Menu } from "@szhsin/react-menu";

export class FormField_OnChangeField extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["OnChangeField"];
    }
}

export function FormField_OnChangeField$reflection() {
    return union_type("Optiscan.SharedUI.Forms.FormField.OnChangeField", [], FormField_OnChangeField, () => [[["Item", DOMAttr$reflection()]]]);
}

export function FormField_OnChangeField_get_getDomAttr() {
    return (_arg1) => _arg1.fields[0];
}

export function FormField_resetCall(elementId) {
    const element = document.getElementById(elementId);
    element.value = null;
}

export function FormField_handleStringUpdate(e) {
    if (equals(String_toOption(e.target.value), void 0)) {
        return void 0;
    }
    else {
        return some(e.target.value);
    }
}

export function FormField_onChangeStringOptic(dispatchChange, optic_0, optic_1) {
    const optic = [optic_0, curry(2, optic_1)];
    return new FormField_OnChangeField(0, new DOMAttr(9, (e) => {
        dispatchChange((svm) => {
            let value;
            return ((value = FormField_handleStringUpdate(e), Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic)(value)))(svm);
        });
    }));
}

export function FormField_onChangeDateOptic(dispatchChange, optic_0, optic_1) {
    const optic = [optic_0, curry(2, optic_1)];
    return new FormField_OnChangeField(0, new DOMAttr(9, (e) => {
        dispatchChange((svm) => {
            let value_2, lastValue;
            return ((value_2 = (e.target.validity.badInput ? ((lastValue = Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic)(svm), (e.target.value = lastValue, lastValue))) : (equals(String_toOption(toString(e.target.value)), void 0) ? (void 0) : fromTicks(getUtcTicks(e.target.valueAsDate), 0))), Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic)(value_2)))(svm);
        });
    }));
}

export function FormField_onChangeDateOpticLocal(dispatchChange, optic_0, optic_1) {
    const optic = [optic_0, curry(2, optic_1)];
    return new FormField_OnChangeField(0, new DOMAttr(9, (e) => {
        dispatchChange((svm) => {
            let value_2, lastValue, unboxedDto, copyOfStruct;
            return ((value_2 = (e.target.validity.badInput ? ((lastValue = Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic)(svm), (e.target.value = lastValue, lastValue))) : (equals(String_toOption(toString(e.target.value)), void 0) ? (void 0) : ((unboxedDto = e.target.valueAsDate, create(year(unboxedDto), month(unboxedDto), day(unboxedDto), 0, 0, 0, (copyOfStruct = now(), copyOfStruct.offset)))))), Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic)(value_2)))(svm);
        });
    }));
}

export function FormField_onChangeNumberOptic(dispatchChange, optic_0, optic_1) {
    const optic = [optic_0, curry(2, optic_1)];
    return new FormField_OnChangeField(0, new DOMAttr(9, (e) => {
        dispatchChange((svm) => {
            let value_2, lastValue;
            return ((value_2 = (e.target.validity.badInput ? ((lastValue = Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic)(svm), (e.target.value = lastValue, lastValue))) : (equals(String_toOption(toString(e.target.value)), void 0) ? (void 0) : some(e.target.valueAsNumber))), Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic)(value_2)))(svm);
        });
    }));
}

export function FormField_onChangeSet(dispatchChange, overWrite) {
    return new FormField_OnChangeField(0, new DOMAttr(9, (e) => {
        dispatchChange((svm) => overWrite(svm, e.target.value));
    }));
}

export function FormField_parseDate(date) {
    let matchValue;
    let outArg = minValue();
    matchValue = [tryParse(date, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return toUniversalTime(matchValue[1]);
    }
    else {
        return minValue();
    }
}

export function FormField_onEnterKeyPress(disabled, dispatchMsg, e) {
    if ((!disabled) && ((e.char === "13") ? true : (e.which === 13))) {
        e.preventDefault();
        dispatchMsg();
    }
}

export function FormField_labeledField(modelName, label, fieldElement, props) {
    return div(toList(delay(() => props)), ofArray([label_1(singleton(new Label_Option(3, singleton(new SelectPropsMulti$1(12, Naming_mkIdFromStrings(ofArray([modelName, label, Naming_UIElement__get_asString(new Naming_UIElement(1, new Naming_UIElement(0)))])))))), singleton(label)), div_1(empty(), singleton(fieldElement))]));
}

export function FormField_text(initialValue, extraProps, extraInputProps, _arg1, modelName, label) {
    const fieldElement = input(toList(delay(() => append(singleton_1(new Option(1, new IInputType(0))), delay(() => append(singleton_1(new Option(3, Naming_mkIdFromStrings(ofArray([modelName, label, Naming_UIElement__get_asString(new Naming_UIElement(0))])))), delay(() => append(singleton_1(new Option(10, initialValue)), delay(() => append(singleton_1(new Option(12, label)), delay(() => append(singleton_1(new Option(15, cons(_arg1.fields[0], extraProps))), delay(() => extraInputProps)))))))))))));
    return (props) => FormField_labeledField(modelName, label, fieldElement, props);
}

export function FormField_password(initialValue, extraProps, extraInputProps, _arg1, modelName, label) {
    const fieldElement = input(toList(delay(() => append(singleton_1(new Option(1, new IInputType(1))), delay(() => append(singleton_1(new Option(3, Naming_mkIdFromStrings(ofArray([modelName, label, Naming_UIElement__get_asString(new Naming_UIElement(0))])))), delay(() => append(singleton_1(new Option(10, initialValue)), delay(() => append(singleton_1(new Option(12, label)), delay(() => append(singleton_1(new Option(15, cons(_arg1.fields[0], extraProps))), delay(() => extraInputProps)))))))))))));
    return (props) => FormField_labeledField(modelName, label, fieldElement, props);
}

export function FormField_textArea(description, initialValue, extraProps, extraTextAreaProps, _arg1, modelName, label) {
    const fieldElement = textarea(toList(delay(() => append(singleton_1(new Option_1(13, description)), delay(() => append(singleton_1(new Option_1(11, initialValue)), delay(() => append(singleton_1(new Option_1(14, cons(_arg1.fields[0], extraProps))), delay(() => extraTextAreaProps)))))))), empty());
    return (props) => FormField_labeledField(modelName, label, fieldElement, props);
}

export function FormField_datePast(initialValue, extraProps, extraInputProps, _arg1, modelName, label) {
    const formatDate = (d) => toString_1(d, "yyyy-MM-dd");
    const maxDate = formatDate(today());
    const initDate = (initialValue == null) ? "" : formatDate(fromDateTimeOffset(initialValue, 0));
    const fieldElement = div_1(empty(), singleton(input(toList(delay(() => append(singleton_1(new Option(1, new IInputType(3))), delay(() => append(singleton_1(new Option(10, initDate)), delay(() => append(singleton_1(new Option(12, label)), delay(() => append(singleton_1(new Option(15, toList(delay(() => append(singleton_1(_arg1.fields[0]), delay(() => append(extraProps, delay(() => append(singleton_1(new HTMLAttr(114, maxDate)), delay(() => singleton_1(new HTMLAttr(119, "1900-01-01")))))))))))), delay(() => extraInputProps)))))))))))));
    return (props) => FormField_labeledField(modelName, label, fieldElement, props);
}

export function FormField_date(initialValue, extraProps, extraInputProps, _arg1, modelName, label) {
    const initDate = (initialValue == null) ? "" : toString_1(fromDateTimeOffset(initialValue, 0), "yyyy-MM-dd");
    const fieldElement = div_1(empty(), singleton(input(toList(delay(() => append(singleton_1(new Option(1, new IInputType(3))), delay(() => append(singleton_1(new Option(10, initDate)), delay(() => append(singleton_1(new Option(12, label)), delay(() => append(singleton_1(new Option(15, toList(delay(() => append(singleton_1(_arg1.fields[0]), delay(() => extraProps)))))), delay(() => extraInputProps)))))))))))));
    return (props) => FormField_labeledField(modelName, label, fieldElement, props);
}

export function FormField_number(initialValue, extraProps, extraInputProps, _arg1, modelName, label) {
    const fieldElement = input(toList(delay(() => append(singleton_1(new Option(1, new IInputType(7))), delay(() => append(singleton_1(new Option(10, initialValue)), delay(() => append(singleton_1(new Option(12, label)), delay(() => append(singleton_1(new Option(15, cons(_arg1.fields[0], extraProps))), delay(() => extraInputProps)))))))))));
    return (props) => FormField_labeledField(modelName, label, fieldElement, props);
}

export function FormField_displayList(label, items) {
    let children_2;
    return div(empty(), ofArray([label_1(empty(), singleton(label)), div(empty(), singleton((children_2 = map((item) => react.createElement("li", {}, item), items), react.createElement("ul", {}, ...children_2))))]));
}

export class FormField_ReactSelect_CallbackSingle$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CallbackSingle"];
    }
}

export function FormField_ReactSelect_CallbackSingle$1$reflection(gen0) {
    return union_type("Optiscan.SharedUI.Forms.FormField.ReactSelect.CallbackSingle`1", [gen0], FormField_ReactSelect_CallbackSingle$1, () => [[["Item", lambda_type(option_type(gen0), unit_type)]]]);
}

export class FormField_ReactSelect_CallbackMulti$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CallbackMulti"];
    }
}

export function FormField_ReactSelect_CallbackMulti$1$reflection(gen0) {
    return union_type("Optiscan.SharedUI.Forms.FormField.ReactSelect.CallbackMulti`1", [gen0], FormField_ReactSelect_CallbackMulti$1, () => [[["Item", lambda_type(class_type("System.Collections.Generic.IEnumerable`1", [gen0]), unit_type)]]]);
}

export function FormField_ReactSelect_mkReactSelectIdString(modelName, label, element) {
    return Naming_mkIdFromStrings(ofArray([modelName, label, Naming_UIElement__get_asString(element)]));
}

export function FormField_ReactSelect_mkReactSelectId(modelName, label, element) {
    return new SelectPropsMulti$1(12, FormField_ReactSelect_mkReactSelectIdString(modelName, label, element));
}

export function FormField_ReactSelect_onChangeSingle(dispatchChange, optic_0, optic_1) {
    const optic = [optic_0, curry(2, optic_1)];
    return new FormField_ReactSelect_CallbackSingle$1(0, (newValue) => {
        dispatchChange((svm) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic)(newValue)(svm));
    });
}

export function FormField_ReactSelect_onChangeMulti(dispatchChange, optic_0, optic_1) {
    const optic = [optic_0, curry(2, optic_1)];
    return new FormField_ReactSelect_CallbackMulti$1(0, (newValue) => {
        dispatchChange((svm) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic)(newValue)(svm));
    });
}

export function FormField_ReactSelect_deselectable(availableToSelect, extraSelectProps, optionLabel, currentlySelected, _arg1, fieldLabel, modelName, fieldProps) {
    const changeCallback = _arg1.fields[0];
    const canSelect = Array.from(map_1((selection) => (new SelectOption$1(optionLabel(selection), selection)), availableToSelect));
    let defaultSelection;
    const matchValue = bind((selected) => tryFindIndex((select) => equals(SelectOptions_value(select), selected), canSelect), currentlySelected);
    if (matchValue == null) {
        defaultSelection = empty();
    }
    else {
        const index = matchValue | 0;
        defaultSelection = singleton(new SelectPropsMulti$1(3, [canSelect[index]]));
    }
    let key;
    const arg20 = isEmpty(defaultSelection) ? "empty" : "full";
    key = toText(printf("%s-%s"))(fieldLabel)(arg20);
    let reactSelect;
    const props = toList(delay(() => append(defaultSelection, delay(() => append(extraSelectProps, delay(() => append(singleton_1(new SelectPropsMulti$1(14, "react-select")), delay(() => append(singleton_1(new SelectPropsMulti$1(1, canSelect)), delay(() => append(singleton_1(new SelectPropsMulti$1(12, FormField_ReactSelect_mkReactSelectIdString(modelName, fieldLabel, new Naming_UIElement(4)))), delay(() => append(singleton_1(new SelectPropsMulti$1(9, false)), delay(() => append(singleton_1(new SelectPropsMulti$1(10, true)), delay(() => singleton_1(new SelectPropsMulti$1(4, (value) => {
        if (equalsWith(equals, value, null)) {
            changeCallback(void 0);
        }
        else {
            changeCallback(some(value.value));
        }
    }))))))))))))))))));
    const props_1 = keyValueList(props, 1);
    reactSelect = react.createElement(react_select, props_1);
    return div(toList(delay(() => append(singleton_1(new Option_2(11, singleton(new Prop(0, key)))), delay(() => fieldProps)))), ofArray([label_1(singleton(new Label_Option(3, singleton(FormField_ReactSelect_mkReactSelectId(modelName, fieldLabel, new Naming_UIElement(1, new Naming_UIElement(4)))))), singleton(fieldLabel)), reactSelect]));
}

export function FormField_ReactSelect_single(availableToSelect, extraSelectProps, optionLabel, currentlySelected, _arg1, fieldLabel, labelled, modelName, fieldProps) {
    const canSelect = Array.from(map_1((selection) => (new SelectOption$1(optionLabel(selection), selection)), availableToSelect));
    let defaultSelection;
    const matchValue = bind((selected) => tryFindIndex((select) => equals(SelectOptions_value(select), selected), canSelect), currentlySelected);
    if (matchValue == null) {
        defaultSelection = empty();
    }
    else {
        const index = matchValue | 0;
        defaultSelection = singleton(new SelectPropsMulti$1(3, [canSelect[index]]));
    }
    let key;
    const arg20 = isEmpty(defaultSelection) ? "empty" : "full";
    key = toText(printf("%s-%s"))(fieldLabel)(arg20);
    let reactSelect;
    const props = toList(delay(() => append(defaultSelection, delay(() => append(extraSelectProps, delay(() => append(singleton_1(new SelectPropsMulti$1(14, "react-select")), delay(() => append(singleton_1(new SelectPropsMulti$1(12, FormField_ReactSelect_mkReactSelectIdString(modelName, fieldLabel, new Naming_UIElement(4)))), delay(() => append(singleton_1(new SelectPropsMulti$1(1, canSelect)), delay(() => append(singleton_1(new SelectPropsMulti$1(9, false)), delay(() => singleton_1(new SelectPropsMulti$1(4, (filledItem) => {
        _arg1.fields[0](some(filledItem.value));
    }))))))))))))))));
    const props_1 = keyValueList(props, 1);
    reactSelect = react.createElement(react_select, props_1);
    return div(toList(delay(() => append(singleton_1(new Option_2(11, singleton(new Prop(0, key)))), delay(() => fieldProps)))), toList(delay(() => append(labelled ? singleton_1(label_1(singleton(new Label_Option(3, singleton(FormField_ReactSelect_mkReactSelectId(modelName, fieldLabel, new Naming_UIElement(1, new Naming_UIElement(4)))))), singleton(fieldLabel))) : empty_1(), delay(() => singleton_1(reactSelect))))));
}

export function FormField_ReactSelect_singleGrouped(availableToSelect, extraSelectProps, optionLabel, currentlySelected, _arg1, fieldLabel, labelled, modelName, fieldProps) {
    const canSelect = Array.from(map_1((groupInfo) => (new GroupType$1(groupInfo.groupLabel, Array.from(map_1((selection) => (new SelectOption$1(optionLabel(selection), selection)), groupInfo.values)))), availableToSelect));
    let defaultSelection;
    const matchValue = bind((selected) => tryPick((group) => tryFind((select) => (optionLabel(SelectOptions_value(select)) === optionLabel(selected)), group.options), canSelect), currentlySelected);
    defaultSelection = ((matchValue == null) ? empty() : singleton(new SelectPropsMulti$1(3, [matchValue])));
    let key;
    const arg20 = isEmpty(defaultSelection) ? "empty" : "full";
    key = toText(printf("%s-%s"))(fieldLabel)(arg20);
    let reactSelect;
    const props = toList(delay(() => append(defaultSelection, delay(() => append(extraSelectProps, delay(() => append(singleton_1(new SelectPropsMulti$1(14, "react-select")), delay(() => append(singleton_1(new SelectPropsMulti$1(12, FormField_ReactSelect_mkReactSelectIdString(modelName, fieldLabel, new Naming_UIElement(4)))), delay(() => append(singleton_1(new SelectPropsMulti$1(2, canSelect)), delay(() => append(singleton_1(new SelectPropsMulti$1(9, false)), delay(() => append(singleton_1(new SelectPropsMulti$1(8, "auto")), delay(() => singleton_1(new SelectPropsMulti$1(4, (filledItem) => {
        _arg1.fields[0](some(filledItem.value));
    }))))))))))))))))));
    const props_1 = keyValueList(props, 1);
    reactSelect = react.createElement(react_select, props_1);
    return div(toList(delay(() => append(singleton_1(new Option_2(11, singleton(new Prop(0, key)))), delay(() => fieldProps)))), toList(delay(() => append(labelled ? singleton_1(label_1(singleton(new Label_Option(3, singleton(FormField_ReactSelect_mkReactSelectId(modelName, fieldLabel, new Naming_UIElement(1, new Naming_UIElement(4)))))), singleton(fieldLabel))) : empty_1(), delay(() => singleton_1(reactSelect))))));
}

export function FormField_ReactSelect_multi(availableToSelect, extraSelectProps, optionLabel, currentlySelected, _arg1, fieldLabel, modelName, fieldProps) {
    const toOptions = (values) => Array.from(map_1((selection) => (new SelectOption$1(optionLabel(selection), selection)), values));
    const notAlreadySelected = filter((item) => (!contains(item, currentlySelected, {
        Equals: equals,
        GetHashCode: structuralHash,
    })), availableToSelect);
    let reactSelect;
    const props = toList(delay(() => append(singleton_1(new SelectPropsMulti$1(0, toOptions(currentlySelected))), delay(() => append(singleton_1(new SelectPropsMulti$1(1, toOptions(notAlreadySelected))), delay(() => append(singleton_1(new SelectPropsMulti$1(14, "react-select")), delay(() => append(singleton_1(new SelectPropsMulti$1(12, FormField_ReactSelect_mkReactSelectIdString(modelName, fieldLabel, new Naming_UIElement(4)))), delay(() => append(singleton_1(new SelectPropsMulti$1(9, true)), delay(() => append(singleton_1(new SelectPropsMulti$1(4, (selected) => {
        _arg1.fields[0](equalsWith(equals, selected, null) ? empty() : ofArray(map_2((filled) => filled.value, selected)));
    })), delay(() => extraSelectProps))))))))))))));
    const props_1 = keyValueList(props, 1);
    reactSelect = react.createElement(react_select, props_1);
    return div(singleton(new Option_2(11, toList(delay(() => fieldProps)))), ofArray([label_1(singleton(new Label_Option(3, singleton(FormField_ReactSelect_mkReactSelectId(modelName, fieldLabel, new Naming_UIElement(1, new Naming_UIElement(4)))))), singleton(fieldLabel)), reactSelect]));
}

export function FormField_ReactSelect_singleUnlabled(availableToSelect, extraSelectProps, optionLabel, currentlySelected, _arg1, fieldLabel, modelName, fieldProps) {
    const canSelect = Array.from(map_1((selection) => (new SelectOption$1(optionLabel(selection), selection)), availableToSelect));
    let defaultSelection;
    const matchValue = bind((selected) => tryFindIndex((select) => equals(SelectOptions_value(select), selected), canSelect), currentlySelected);
    if (matchValue == null) {
        defaultSelection = empty();
    }
    else {
        const index = matchValue | 0;
        defaultSelection = singleton(new SelectPropsMulti$1(3, [canSelect[index]]));
    }
    let key;
    const arg20 = isEmpty(defaultSelection) ? "empty" : "full";
    key = toText(printf("%s-%s"))(fieldLabel)(arg20);
    let reactSelect;
    const props = toList(delay(() => append(defaultSelection, delay(() => append(extraSelectProps, delay(() => append(singleton_1(new SelectPropsMulti$1(14, "react-select")), delay(() => append(singleton_1(new SelectPropsMulti$1(12, FormField_ReactSelect_mkReactSelectIdString(modelName, fieldLabel, new Naming_UIElement(4)))), delay(() => append(singleton_1(new SelectPropsMulti$1(1, canSelect)), delay(() => append(singleton_1(new SelectPropsMulti$1(9, false)), delay(() => singleton_1(new SelectPropsMulti$1(4, (filledItem) => {
        _arg1.fields[0](some(filledItem.value));
    }))))))))))))))));
    const props_1 = keyValueList(props, 1);
    reactSelect = react.createElement(react_select, props_1);
    return div(toList(delay(() => append(singleton_1(new Option_2(11, singleton(new Prop(0, key)))), delay(() => fieldProps)))), singleton(reactSelect));
}

export function FormField_ReactMenu_menu(props, buttonChildren, menuChildren) {
    let children, children_6;
    const menuButton = button(singleton(new Option_3(19, "react-menu__button")), ofArray([(children = [toArray(buttonChildren)], react.createElement("div", {
        className: "react-menu__button__text",
    }, ...children)), (children_6 = [react.createElement("span", {
        className: "react-menu__button__indicator__separator",
    }), react.createElement("i", {
        className: "react-menu__button__indicator fa-solid fa-chevron-down",
    })], react.createElement("div", {
        className: "react-menu__button__indicator__container",
    }, ...children_6))]));
    const props_9 = toList(delay(() => append(props, delay(() => singleton_1(new MenuProps(3, menuButton))))));
    const props_10 = keyValueList(props_9, 1);
    return react.createElement(Menu, props_10, ...menuChildren);
}

export function FormField_ReactMenu_subMenu(props, children) {
    const props_1 = toList(delay(() => props));
    const props_2 = keyValueList(props_1, 1);
    return react.createElement(SubMenu, props_2, ...children);
}

export function FormField_ReactMenu_menuItem(props, children) {
    const props_1 = toList(delay(() => props));
    const props_2 = keyValueList(props_1, 1);
    return react.createElement(MenuItem, props_2, ...children);
}

