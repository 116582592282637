import { toString, getTicks } from "../fable_modules/fable-library.3.7.12/Date.js";
import { tryParse, minValue, fromTicks } from "../fable_modules/fable-library.3.7.12/DateOffset.js";
import { fromInteger, op_Modulus, op_Subtraction } from "../fable_modules/fable-library.3.7.12/Long.js";
import { Record, FSharpRef } from "../fable_modules/fable-library.3.7.12/Types.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.12/Choice.js";
import { list_type, record_type, string_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { map, fold } from "../fable_modules/fable-library.3.7.12/List.js";

export function DateTimeOffset_truncateToMicrosecond(dto) {
    const originalTicks = getTicks(dto);
    return fromTicks(op_Subtraction(originalTicks, op_Modulus(originalTicks, fromInteger(10, false, 2))), dto.offset);
}

export function DateTimeOffset_yyyyMMdd(dateTime) {
    return toString(dateTime, "yyyy/MM/dd");
}

export function DateTimeOffset_maybeParse(str) {
    let matchValue;
    let outArg = minValue();
    matchValue = [tryParse(str, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return void 0;
    }
}

export function Result_ofChoice(choice) {
    if (choice.tag === 1) {
        return new FSharpResult$2(1, choice.fields[0]);
    }
    else {
        return new FSharpResult$2(0, choice.fields[0]);
    }
}

export class Csv_CsvRow extends Record {
    constructor(displayName, value) {
        super();
        this.displayName = displayName;
        this.value = value;
    }
}

export function Csv_CsvRow$reflection() {
    return record_type("Optiscan.Models.Csv.CsvRow", [], Csv_CsvRow, () => [["displayName", string_type], ["value", string_type]]);
}

export class Csv_CsvRowMulti extends Record {
    constructor(displayName, values) {
        super();
        this.displayName = displayName;
        this.values = values;
    }
}

export function Csv_CsvRowMulti$reflection() {
    return record_type("Optiscan.Models.Csv.CsvRowMulti", [], Csv_CsvRowMulti, () => [["displayName", string_type], ["values", list_type(string_type)]]);
}

export function Csv_makeCsvMulti(fields) {
    return fold((x_1, y_1) => (x_1 + y_1), "", map((field) => {
        const rows = fold((x, y) => (x + y), "", map((v) => (`"${v}",`), field.values));
        return `"${field.displayName}",${rows}
`;
    }, fields));
}

export function Csv_makeCsv(fields) {
    return fold((x, y) => (x + y), "", map((field) => (`"${field.displayName}","${field.value}"
`), fields));
}

export function Csv_makeCsvUrl(csv) {
    const uriEncodedCsv = encodeURIComponent(csv);
    return `data:text/csv;charset=utf-8,${"%EF%BB%BF"}${uriEncodedCsv}`;
}

export function Csv_makeDownloadLink(fileName, url) {
    const link = window.document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    return link;
}

export function Csv_makeCsvMultiLink(fileName, rows) {
    return Csv_makeDownloadLink(fileName, Csv_makeCsvUrl(Csv_makeCsvMulti(rows)));
}

export function Csv_makeCsvLink(fileName, rows) {
    return Csv_makeDownloadLink(fileName, Csv_makeCsvUrl(Csv_makeCsv(rows)));
}

