import { ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Return_1505, ResultComputationExpression_ResultBuilder__Bind_764BA1D3, ResultComputationExpression_ResultBuilder__Delay_1505, ResultComputationExpression_ResultBuilder__Run_FCFD9EF } from "../../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { validateNotNullOrEmpty } from "../../../fable_modules/Webbler.Models.1.2.6/Validation.fs.js";
import { ShowModel, CreateModel_get_password_, CreateModel_get_guestEmails_, CreateMsg, CreateModel } from "./PatientGuestLinksTypes.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "../../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { StateTreeNode$6, NavigationMsg$1, StateTreeMsg$4 } from "../../../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { Card_foot, Card_body, Card_head, Card_card, background, Option, modal } from "../../../fable_modules/Fulma.2.16.0/Components/Modal.fs.js";
import { ofArray, empty, singleton } from "../../../fable_modules/fable-library.3.7.12/List.js";
import { Prop, HTMLAttr, DOMAttr } from "../../../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { Color_IColor, Common_GenericOption } from "../../../fable_modules/Fulma.2.16.0/Common.fs.js";
import { iterate, singleton as singleton_1, append, delay, toList } from "../../../fable_modules/fable-library.3.7.12/Seq.js";
import { FormField_password, FormField_onChangeSet, FormField_textArea } from "../../../Optiscan.SharedUI/Forms.js";
import { uncurry } from "../../../fable_modules/fable-library.3.7.12/Util.js";
import { Optic_Set, Optic_Set_op_HatEquals_2170E4F5 } from "../../../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { Functions_flip } from "../../../fable_modules/Webbler.Models.1.2.6/Common.fs.js";
import { Option as Option_1, button } from "../../../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { split } from "../../../fable_modules/fable-library.3.7.12/String.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { GuestLinkRequest } from "../../../Optiscan.Models/View/GuestLink.js";
import { securedApi } from "../../../Api.js";
import { Common_eraseExceptionToAuthError } from "../../../Common/Common.js";
import { error } from "../../../Optiscan.SharedUI/Toast.js";
import { ErrorMessage_get_describe } from "../../../Optiscan.Models/ErrorMessage.js";
import * as react from "react";
import { input } from "../../../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { Option as Option_2, IInputType } from "../../../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { toArray, defaultArg } from "../../../fable_modules/fable-library.3.7.12/Option.js";

export function canSaveGuestLink(guestLink) {
    return ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, validateNotNullOrEmpty("Guest link password cannot be empty", guestLink.password), () => ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result))));
}

export function guestLinkCreateState() {
    return new StateTreeNode$6((deps) => [new CreateModel("", "", void 0), Cmd_OfFunc_result(new StateTreeMsg$4(2))], (model, dispatch) => modal(singleton(new Option(1, true)), ofArray([background(singleton(new Common_GenericOption(1, singleton(new DOMAttr(40, (_arg1) => {
        dispatch(new StateTreeMsg$4(4, new NavigationMsg$1(1)));
    })))), empty()), Card_card(empty(), ofArray([Card_head(empty(), singleton("Guest Link")), Card_body(empty(), toList(delay(() => {
        let f, optic;
        const setter = (arg_1) => {
            dispatch(new StateTreeMsg$4(0, new CreateMsg(0, arg_1)));
        };
        return append(singleton_1(FormField_textArea("Comma seperated list of guest emails", model.guestEmails, empty(), [], FormField_onChangeSet(setter, uncurry(2, (f = ((optic = CreateModel_get_guestEmails_(), (value) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic)(value))), (b) => ((a) => Functions_flip(uncurry(2, f), b, a))))), "PatientGuestLink", "Guest Emails")([])), delay(() => {
            let f_1, optic_2;
            return singleton_1(FormField_password(model.password, empty(), [], FormField_onChangeSet(setter, uncurry(2, (f_1 = ((optic_2 = CreateModel_get_password_(), (value_2) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic_2)(value_2))), (b_1) => ((a_1) => Functions_flip(uncurry(2, f_1), b_1, a_1))))), "PatientGuestLink", "Guest Password")([]));
        }));
    }))), Card_foot(empty(), ofArray([button(toList(delay(() => append(singleton_1(new Option_1(18, (_arg2) => {
        dispatch(new StateTreeMsg$4(0, new CreateMsg(1)));
    })), delay(() => append(singleton_1(new Option_1(0, new Color_IColor(6))), delay(() => {
        const matchValue = canSaveGuestLink(model);
        return (matchValue.tag === 1) ? [new Option_1(16, true), new Option_1(17, singleton(new HTMLAttr(158, matchValue.fields[0])))] : [];
    })))))), singleton("Create")), button(ofArray([new Option_1(18, (_arg3) => {
        dispatch(new StateTreeMsg$4(0, new CreateMsg(3)));
    }), new Option_1(19, "is-secondary")]), singleton("Cancel"))]))]))])), (msg_2, tupledArg, deps_1, model_1) => {
        if (msg_2.tag === 1) {
            const emails = split(model_1.guestEmails, [","], null, 1);
            const matchValue_1 = deps_1.maybeSelectedSession;
            if (matchValue_1 == null) {
                return [model_1, Cmd_none()];
            }
            else {
                const request = new GuestLinkRequest(matchValue_1.sessionId, model_1.password, emails);
                return [model_1, Cmd_OfAsyncWith_either((x) => {
                    Cmd_OfAsync_start(x);
                }, securedApi(tupledArg[0]).createGuestLink, request, (arg_2) => (new StateTreeMsg$4(0, new CreateMsg(2, arg_2))), (arg_3) => (new StateTreeMsg$4(1, Common_eraseExceptionToAuthError(arg_3))))];
            }
        }
        else {
            return (msg_2.tag === 2) ? ((msg_2.fields[0].tag === 1) ? [model_1, error(ErrorMessage_get_describe()(msg_2.fields[0].fields[0]))] : [new CreateModel(model_1.guestEmails, model_1.password, msg_2.fields[0].fields[0].guestLink), Cmd_OfFunc_result(new StateTreeMsg$4(4, new NavigationMsg$1(4, "Show Guest Link Modal")))]) : ((msg_2.tag === 3) ? [new CreateModel(model_1.guestEmails, "", model_1.guestLink), Cmd_OfFunc_result(new StateTreeMsg$4(4, new NavigationMsg$1(1)))] : [msg_2.fields[0](model_1), Cmd_none()]);
        }
    });
}

export const inputRef = react.createRef(void 0);

export function guestLinkShowState() {
    return new StateTreeNode$6((deps) => [new ShowModel(deps.guestLink), Cmd_OfFunc_result(new StateTreeMsg$4(2))], (model, dispatch) => {
        let children;
        return modal(singleton(new Option(1, true)), ofArray([background(singleton(new Common_GenericOption(1, singleton(new DOMAttr(40, (_arg1) => {
            dispatch(new StateTreeMsg$4(4, new NavigationMsg$1(3, "list")));
        })))), empty()), Card_card(empty(), ofArray([Card_head(empty(), singleton("Guest Link")), Card_body(empty(), singleton((children = toList(delay(() => append(singleton_1("Guest link emailed to participants! please let them know the password when they join."), delay(() => append(singleton_1(react.createElement("br", {})), delay(() => append(singleton_1(react.createElement("br", {})), delay(() => append(singleton_1("Copy the guest link here if you want to manually send it to anyone else:"), delay(() => append(singleton_1(react.createElement("br", {})), delay(() => append(singleton_1(react.createElement("br", {})), delay(() => append(singleton_1(input(ofArray([new Option_2(1, new IInputType(0)), new Option_2(15, ofArray([new Prop(3, inputRef), ["style", {
            width: "80%",
        }]])), new Option_2(8, defaultArg(model.guestLink, "Error, no guest link generate.")), new Option_2(5, true)]))), delay(() => singleton_1(button(ofArray([new Option_1(18, (_arg2) => {
            iterate((element) => {
                element.select();
                document.execCommand("copy");
            }, toArray(inputRef.current));
        }), new Option_1(0, new Color_IColor(9))]), singleton("Copy"))))))))))))))))))), react.createElement("div", {}, ...children)))), Card_foot(empty(), singleton(button(ofArray([new Option_1(18, (_arg3) => {
            dispatch(new StateTreeMsg$4(4, new NavigationMsg$1(3, "list")));
        }), new Option_1(0, new Color_IColor(6))]), singleton("Ok"))))]))]));
    }, (_arg6, _arg5, _arg4, model_1) => [model_1, Cmd_none()]);
}

