import { Record } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { option_type, record_type, list_type, enum_type, int32_type, string_type, class_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";

export class SessionViewModel extends Record {
    constructor(sessionId, patientId, deviceId, sessionName, sessionState, dateCreated, region, scheduledTime, usersToNotify) {
        super();
        this.sessionId = sessionId;
        this.patientId = patientId;
        this.deviceId = deviceId;
        this.sessionName = sessionName;
        this.sessionState = (sessionState | 0);
        this.dateCreated = dateCreated;
        this.region = region;
        this.scheduledTime = scheduledTime;
        this.usersToNotify = usersToNotify;
    }
}

export function SessionViewModel$reflection() {
    return record_type("Optiscan.Models.View.Session.SessionViewModel", [], SessionViewModel, () => [["sessionId", class_type("System.Guid")], ["patientId", class_type("System.Guid")], ["deviceId", string_type], ["sessionName", string_type], ["sessionState", enum_type("Optiscan.Models.Shared.SessionState", int32_type, [["Created", 0], ["Active", 1], ["Completed", 2], ["Interrupted", 3], ["Paused", 4]])], ["dateCreated", class_type("System.DateTimeOffset")], ["region", string_type], ["scheduledTime", class_type("System.DateTimeOffset")], ["usersToNotify", list_type(class_type("System.Guid"))]]);
}

export function SessionViewModel_get_sessionId_() {
    return [(m) => m.sessionId, (v) => ((m_1) => (new SessionViewModel(v, m_1.patientId, m_1.deviceId, m_1.sessionName, m_1.sessionState, m_1.dateCreated, m_1.region, m_1.scheduledTime, m_1.usersToNotify)))];
}

export function SessionViewModel_get_sessionName_() {
    return [(m) => m.sessionName, (v) => ((m_1) => (new SessionViewModel(m_1.sessionId, m_1.patientId, m_1.deviceId, v, m_1.sessionState, m_1.dateCreated, m_1.region, m_1.scheduledTime, m_1.usersToNotify)))];
}

export class AdjustedTimeZoneViewModel extends Record {
    constructor(standardName, daylightName, label, offset) {
        super();
        this.standardName = standardName;
        this.daylightName = daylightName;
        this.label = label;
        this.offset = offset;
    }
}

export function AdjustedTimeZoneViewModel$reflection() {
    return record_type("Optiscan.Models.View.Session.AdjustedTimeZoneViewModel", [], AdjustedTimeZoneViewModel, () => [["standardName", string_type], ["daylightName", string_type], ["label", string_type], ["offset", class_type("System.TimeSpan")]]);
}

export class SessionCreateViewModel extends Record {
    constructor(patientId, sessionName, scheduledTime, usersToNotify) {
        super();
        this.patientId = patientId;
        this.sessionName = sessionName;
        this.scheduledTime = scheduledTime;
        this.usersToNotify = usersToNotify;
    }
}

export function SessionCreateViewModel$reflection() {
    return record_type("Optiscan.Models.View.Session.SessionCreateViewModel", [], SessionCreateViewModel, () => [["patientId", class_type("System.Guid")], ["sessionName", string_type], ["scheduledTime", option_type(class_type("System.DateTimeOffset"))], ["usersToNotify", list_type(class_type("System.Guid"))]]);
}

export function SessionCreateViewModel_get_sessionName_() {
    return [(m) => m.sessionName, (v) => ((m_1) => (new SessionCreateViewModel(m_1.patientId, v, m_1.scheduledTime, m_1.usersToNotify)))];
}

export function SessionCreateViewModel_get_scheduledTime_() {
    return [(m) => m.scheduledTime, (v) => ((m_1) => (new SessionCreateViewModel(m_1.patientId, m_1.sessionName, v, m_1.usersToNotify)))];
}

export class ScheduledSessionViewModel extends Record {
    constructor(sessionId, patientId, siteId, siteName, deviceId, sessionName, sessionState, dateCreated, scheduledTime, region) {
        super();
        this.sessionId = sessionId;
        this.patientId = patientId;
        this.siteId = siteId;
        this.siteName = siteName;
        this.deviceId = deviceId;
        this.sessionName = sessionName;
        this.sessionState = (sessionState | 0);
        this.dateCreated = dateCreated;
        this.scheduledTime = scheduledTime;
        this.region = region;
    }
}

export function ScheduledSessionViewModel$reflection() {
    return record_type("Optiscan.Models.View.Session.ScheduledSessionViewModel", [], ScheduledSessionViewModel, () => [["sessionId", class_type("System.Guid")], ["patientId", class_type("System.Guid")], ["siteId", class_type("System.Guid")], ["siteName", string_type], ["deviceId", string_type], ["sessionName", string_type], ["sessionState", enum_type("Optiscan.Models.Shared.SessionState", int32_type, [["Created", 0], ["Active", 1], ["Completed", 2], ["Interrupted", 3], ["Paused", 4]])], ["dateCreated", class_type("System.DateTimeOffset")], ["scheduledTime", class_type("System.DateTimeOffset")], ["region", string_type]]);
}

