import { Record } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { record_type, string_type, class_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";

export class AuthorizedUserInformation extends Record {
    constructor(Id, FullName) {
        super();
        this.Id = Id;
        this.FullName = FullName;
    }
}

export function AuthorizedUserInformation$reflection() {
    return record_type("Optiscan.Models.View.AuthorizedUserInformation.AuthorizedUserInformation", [], AuthorizedUserInformation, () => [["Id", class_type("System.Guid")], ["FullName", string_type]]);
}

export function AuthorizedUserInformation_get_empty() {
    return new AuthorizedUserInformation("00000000-0000-0000-0000-000000000000", "");
}

export function AuthorizedUserInformation_get_Id_() {
    return [(m) => m.Id, (v) => ((m_1) => (new AuthorizedUserInformation(v, m_1.FullName)))];
}

export function AuthorizedUserInformation_get_FullName_() {
    return [(m) => m.FullName, (v) => ((m_1) => (new AuthorizedUserInformation(m_1.Id, v)))];
}

