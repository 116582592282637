"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var rsuite_1 = require("rsuite");
require("rsuite/Slider/styles/index.css");
require("rsuite/Popover/styles/index.css");
var ThumbnailSlider = function (_a) {
    var frameFiles = _a.frameFiles, thumbnailUrls = _a.thumbnailUrls, selectedFrameIndex = _a.selectedFrameIndex, onThumbnailSelected = _a.onThumbnailSelected, viewportContainer = _a.viewportContainer, isSingleView = _a.isSingleView;
    // The top of the slider is normally the slider's max value, but we want things the other way around.
    var invert = function (value) { return frameFiles.length - 1 - value; };
    var _b = (0, react_1.useState)(invert(selectedFrameIndex)), sliderValue = _b[0], setSliderValue = _b[1];
    var _c = (0, react_1.useState)(false), isPopoverOpen = _c[0], setIsPopoverOpen = _c[1];
    var _d = (0, react_1.useState)(false), isSliderActive = _d[0], setIsSliderActive = _d[1];
    var _e = (0, react_1.useState)(false), isMouseOverPopover = _e[0], setIsMouseOverPopover = _e[1];
    var _f = (0, react_1.useState)(0), sliderContainerHeight = _f[0], setSliderContainerHeight = _f[1];
    var _g = (0, react_1.useState)(viewportContainer ? viewportContainer.clientWidth : 0), viewportContainerWidth = _g[0], setViewportContainerWidth = _g[1];
    var sliderContainerRef = (0, react_1.useRef)(null);
    var thumbnailRefs = (0, react_1.useRef)([]);
    var popoverRef = (0, react_1.useRef)(null);
    var popoverCloseTimeoutRef = (0, react_1.useRef)(null);
    var activeIndex = invert(sliderValue);
    // Respond to viewport resizing.
    (0, react_1.useEffect)(function () {
        if (!viewportContainer) {
            return;
        }
        var handleResize = function (entries) {
            for (var _i = 0, entries_1 = entries; _i < entries_1.length; _i++) {
                var entry = entries_1[_i];
                if (entry.target === viewportContainer) {
                    setViewportContainerWidth(entry.contentRect.width);
                }
            }
        };
        var resizeObserver = new ResizeObserver(handleResize);
        resizeObserver.observe(viewportContainer);
        return function () {
            resizeObserver.disconnect();
        };
    }, [viewportContainer]);
    // Update slider height when container height changes.
    (0, react_1.useEffect)(function () {
        if (sliderContainerRef.current) {
            setSliderContainerHeight(sliderContainerRef.current.offsetHeight);
        }
    }, [sliderContainerRef.current ? sliderContainerRef.current.offsetHeight : 0]);
    // Update slider value when selectedFrameIndex or number of frames changes.
    (0, react_1.useEffect)(function () {
        setSliderValue(invert(selectedFrameIndex));
    }, [selectedFrameIndex, frameFiles.length]);
    // Scroll to active thumbnail when it changes.
    (0, react_1.useEffect)(function () {
        var _a;
        if (thumbnailRefs.current[activeIndex]) {
            (_a = thumbnailRefs.current[activeIndex]) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });
        }
    }, [activeIndex]);
    // Manual logic to determine when to close the popover. Normally you would rely on the trigger and delay props of
    // the Whisper component, but their implementation is not ideal for users who value their sanity.
    (0, react_1.useEffect)(function () {
        if (isSliderActive || isMouseOverPopover) {
            // If either is active, clear any existing close timeout to prevent the popover from closing.
            if (popoverCloseTimeoutRef.current) {
                clearTimeout(popoverCloseTimeoutRef.current);
                popoverCloseTimeoutRef.current = null;
            }
            setIsPopoverOpen(true);
        }
        else {
            // Start a timeout to close the popover after a delay.
            popoverCloseTimeoutRef.current = setTimeout(function () {
                setIsPopoverOpen(false);
                popoverCloseTimeoutRef.current = null;
            }, 300);
        }
        // Cleanup function to clear timeout when component unmounts.
        return function () {
            if (popoverCloseTimeoutRef.current) {
                clearTimeout(popoverCloseTimeoutRef.current);
                popoverCloseTimeoutRef.current = null;
            }
        };
    }, [isSliderActive, isMouseOverPopover]);
    // Scroll to active thumbnail when popover opens. Normally you would use the onOpen prop of the Whisper component,
    // but it only executes after it has fully faded into view, causing the images to jump as the scroll happens.
    (0, react_1.useEffect)(function () {
        if (isPopoverOpen && thumbnailRefs.current[activeIndex]) {
            // We need to delay the scrolling to ensure the popover has begun rendering and the thumbnails are visible.
            setTimeout(function () {
                thumbnailRefs.current[activeIndex].scrollIntoView({
                    behavior: 'instant',
                    block: 'center'
                });
            }, 10);
        }
    }, [isPopoverOpen]);
    var handleSliderChange = function (sliderValue) {
        setSliderValue(sliderValue);
        onThumbnailSelected(invert(sliderValue));
    };
    var speaker = (react_1.default.createElement(rsuite_1.Popover, { ref: popoverRef, style: { padding: 0, width: (isSingleView) ? "".concat((viewportContainerWidth / 2) * 0.2, "px") : "".concat(viewportContainerWidth * 0.2, "px"), height: "".concat(sliderContainerHeight, "px"), overflowY: 'auto', marginLeft: '-2px' }, arrow: false, className: 'rs-theme-dark', onMouseEnter: function () { return setIsMouseOverPopover(true); }, onMouseLeave: function () { return setIsMouseOverPopover(false); } },
        react_1.default.createElement("div", { style: { height: '100%', overflowY: 'auto' }, className: 'multi-historical-timeline', onFocus: function () { return setIsMouseOverPopover(true); }, onBlur: function () { return setIsMouseOverPopover(false); } }, frameFiles.map(function (frameFile, index) { return (react_1.default.createElement("div", { key: index, ref: function (el) { return (thumbnailRefs.current[index] = el); }, style: {
                padding: '0',
                margin: '0',
                cursor: 'pointer',
            }, className: index == activeIndex ? 'selected-frame' : 'unselected-frame', onClick: function () { return handleSliderChange(invert(index)); } },
            frameFile.flagged && react_1.default.createElement("img", { src: 'flag.svg', className: 'multi-frame-flag', alt: 'Flagged' }),
            react_1.default.createElement("img", { src: thumbnailUrls[index] || "no-preview.png", alt: "Thumbnail ".concat(index), style: { width: '100%', height: 'auto' } }))); }))));
    var handleWheel = function (event) {
        var delta = Math.sign(event.deltaY);
        var newValue = Math.max(0, Math.min(frameFiles.length - 1, sliderValue - delta));
        handleSliderChange(newValue);
    };
    return (react_1.default.createElement("div", { ref: sliderContainerRef, style: { display: 'flex', alignItems: 'center', height: '100%', flex: '0 0 min-content' }, className: 'rs-theme-dark' },
        react_1.default.createElement(rsuite_1.Whisper, { placement: "right", trigger: "none", speaker: speaker, enterable: true, open: isPopoverOpen },
            react_1.default.createElement(rsuite_1.Slider, { style: { zIndex: 1 }, vertical: true, min: 0, max: frameFiles.length - 1, value: sliderValue, onChange: handleSliderChange, step: 1, tooltip: false, onFocus: function () { return setIsSliderActive(true); }, onBlur: function () { return setIsSliderActive(false); }, onMouseEnter: function () { return setIsMouseOverPopover(true); }, onMouseLeave: function () { return setIsMouseOverPopover(false); }, onWheel: handleWheel }))));
};
exports.default = ThumbnailSlider;
