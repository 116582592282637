import { Record } from "../../../fable_modules/fable-library.3.7.12/Types.js";
import { AssessmentViewModel$reflection } from "../../../Optiscan.Models/View/Assessment.js";
import { float64_type, record_type, list_type } from "../../../fable_modules/fable-library.3.7.12/Reflection.js";
import { AsyncResult_ignore, AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93, AsyncResultComputationExpression_AsyncResultBuilder__Return_1505, AsyncResultComputationExpression_asyncResult, AsyncResultComputationExpression_AsyncResultBuilder__ReturnFrom_1505, AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B } from "../../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { Pdf_Spacing_baseSpacingY, Pdf_Spacing_narrowSpacingY, Pdf_Table_Adv_makeTitle, Pdf_Spacing_topMargin, Pdf_Spacing_leftMargin } from "../PatientReportHelpers.js";
import { isNullOrWhiteSpace } from "../../../fable_modules/fable-library.3.7.12/String.js";
import { length, item } from "../../../fable_modules/fable-library.3.7.12/List.js";
import { Assessment_TypeModule_describe } from "../../../Optiscan.Models/Shared.js";

export class Args extends Record {
    constructor(assessments) {
        super();
        this.assessments = assessments;
    }
}

export function Args$reflection() {
    return record_type("Client.Patients.PatientReport.Sections.AssessmentsSection.Args", [], Args, () => [["assessments", list_type(AssessmentViewModel$reflection())]]);
}

export class AddToDocArgs extends Record {
    constructor(maxPageHeight, maxPageWidth, restartY, lineHeight) {
        super();
        this.maxPageHeight = maxPageHeight;
        this.maxPageWidth = maxPageWidth;
        this.restartY = restartY;
        this.lineHeight = lineHeight;
    }
}

export function AddToDocArgs$reflection() {
    return record_type("Client.Patients.PatientReport.Sections.AssessmentsSection.AddToDocArgs", [], AddToDocArgs, () => [["maxPageHeight", float64_type], ["maxPageWidth", float64_type], ["restartY", float64_type], ["lineHeight", float64_type]]);
}

function addAssessmentText(docArgs, i, splitText, currentY, doc) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => {
        let currentY_1;
        if ((currentY + docArgs.lineHeight) > docArgs.maxPageHeight) {
            doc.addPage();
            currentY_1 = docArgs.restartY;
        }
        else {
            currentY_1 = currentY;
        }
        doc.text(splitText[i], Pdf_Spacing_leftMargin, currentY_1);
        const i_1 = (i + 1) | 0;
        const currentY_2 = currentY_1 + docArgs.lineHeight;
        return (i_1 < splitText.length) ? AsyncResultComputationExpression_AsyncResultBuilder__ReturnFrom_1505(AsyncResultComputationExpression_asyncResult, addAssessmentText(docArgs, i_1, splitText, currentY_2, doc)) : AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, [currentY_2, doc]);
    });
}

function addAssessmentsPage(i, assessments, doc) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => {
        const newi = (i + 1) | 0;
        if (!isNullOrWhiteSpace(item(i, assessments).assessment)) {
            doc.addPage();
            const previousFont = doc.getFontSize();
            doc.setFontSize(12);
            const pageSize = doc.internal.pageSize;
            const pageHeight = pageSize.getHeight();
            const maxWidth = pageSize.getWidth() - (2 * Pdf_Spacing_leftMargin);
            const maxHeight = pageHeight - Pdf_Spacing_topMargin;
            const splitText = doc.splitTextToSize(item(i, assessments).assessment, maxWidth);
            Pdf_Table_Adv_makeTitle(Assessment_TypeModule_describe(item(i, assessments).assessmentType), Pdf_Spacing_topMargin, doc);
            return AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, AsyncResult_ignore(addAssessmentText(new AddToDocArgs(maxHeight, maxWidth, Pdf_Spacing_topMargin, Pdf_Spacing_narrowSpacingY), 0, splitText, Pdf_Spacing_topMargin + Pdf_Spacing_baseSpacingY, doc)), () => {
                doc.setFontSize(previousFont);
                return (newi < length(assessments)) ? AsyncResultComputationExpression_AsyncResultBuilder__ReturnFrom_1505(AsyncResultComputationExpression_asyncResult, addAssessmentsPage(newi, assessments, doc)) : AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, doc);
            });
        }
        else {
            return (newi < length(assessments)) ? AsyncResultComputationExpression_AsyncResultBuilder__ReturnFrom_1505(AsyncResultComputationExpression_asyncResult, addAssessmentsPage(newi, assessments, doc)) : AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, doc);
        }
    });
}

export function create(doc, args) {
    return addAssessmentsPage(0, args.assessments, doc);
}

