import { Union, Record } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { union_type, bool_type, option_type, lambda_type, unit_type, class_type, array_type, int32_type, record_type, string_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";

export class EventNames extends Record {
    constructor(SetFrame, SelectAnnotation, RemoveAnnotation, SetAnnotationText, Screenshot) {
        super();
        this.SetFrame = SetFrame;
        this.SelectAnnotation = SelectAnnotation;
        this.RemoveAnnotation = RemoveAnnotation;
        this.SetAnnotationText = SetAnnotationText;
        this.Screenshot = Screenshot;
    }
}

export function EventNames$reflection() {
    return record_type("Client.Patients.Historical.Bindings.EventNames", [], EventNames, () => [["SetFrame", string_type], ["SelectAnnotation", string_type], ["RemoveAnnotation", string_type], ["SetAnnotationText", string_type], ["Screenshot", string_type]]);
}

export const eventNames = new EventNames("set_frame", "select_annotation", "remove_annotation", "set_annotation_text", "screenshot");

export class SetFrameEvent extends Record {
    constructor(viewportIndex, frameIndex) {
        super();
        this.viewportIndex = (viewportIndex | 0);
        this.frameIndex = (frameIndex | 0);
    }
}

export function SetFrameEvent$reflection() {
    return record_type("Client.Patients.Historical.Bindings.SetFrameEvent", [], SetFrameEvent, () => [["viewportIndex", int32_type], ["frameIndex", int32_type]]);
}

export class AnnotationInformation extends Record {
    constructor(annotationUID, annotationData, toolName, measurements) {
        super();
        this.annotationUID = annotationUID;
        this.annotationData = annotationData;
        this.toolName = toolName;
        this.measurements = measurements;
    }
}

export function AnnotationInformation$reflection() {
    return record_type("Client.Patients.Historical.Bindings.AnnotationInformation", [], AnnotationInformation, () => [["annotationUID", string_type], ["annotationData", string_type], ["toolName", string_type], ["measurements", array_type(string_type)]]);
}

export class CanvasInfo extends Record {
    constructor(viewportIndex, canvasData) {
        super();
        this.viewportIndex = (viewportIndex | 0);
        this.canvasData = canvasData;
    }
}

export function CanvasInfo$reflection() {
    return record_type("Client.Patients.Historical.Bindings.CanvasInfo", [], CanvasInfo, () => [["viewportIndex", int32_type], ["canvasData", string_type]]);
}

export class IHistoricalViewProps extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ViewportIndex", "TotalViewports", "InitialFrame", "DicomFiles", "LoadedDicomFileNames", "OnDicomFilesLoaded", "DoStartPlayback", "DoPausePlayback", "DoSetMaximizedViewport", "EventNames", "ToggleFlagged", "SaveAnnotationHandler", "UpdateAnnotationHandler", "DeleteAnnotationHandler", "SetSelectedAnnotation", "ScreenshotHandler", "SetScreenshotLoading", "Annotations", "IsSingleView", "IsMaximized"];
    }
}

export function IHistoricalViewProps$reflection() {
    return union_type("Client.Patients.Historical.Bindings.IHistoricalViewProps", [], IHistoricalViewProps, () => [[["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]], [["Item", array_type(class_type("Browser.Types.File", void 0, File))]], [["Item", array_type(string_type)]], [["Item", lambda_type(unit_type, unit_type)]], [["Item", lambda_type(unit_type, unit_type)]], [["Item", lambda_type(unit_type, unit_type)]], [["Item", lambda_type(option_type(int32_type), unit_type)]], [["Item", EventNames$reflection()]], [["Item", lambda_type(unit_type, unit_type)]], [["Item", lambda_type(AnnotationInformation$reflection(), unit_type)]], [["Item", lambda_type(AnnotationInformation$reflection(), unit_type)]], [["Item", lambda_type(AnnotationInformation$reflection(), unit_type)]], [["Item", lambda_type(string_type, unit_type)]], [["Item", lambda_type(array_type(CanvasInfo$reflection()), unit_type)]], [["Item", lambda_type(bool_type, unit_type)]], [["Item", array_type(string_type)]], [["Item", bool_type]], [["Item", bool_type]]]);
}

