import { RestrictedModalMsg, RestrictedSearchModel, SearchFailedModal, PatientInfoModel_get_lastName_, PatientInfoModel_get_firstName_, SearchModel_get_patientInfo_, SearchModel_get_patientId_, SearchModel_get_selectedSite_, SearchModel_get_searchType_, SearchType_get_describe, SearchType_get_all, PatientSearchMsg, SearchModel, PatientInfoModel_get_empty, SearchType } from "./PatientSearchTypes.js";
import { PatientViewModel_get_empty } from "../Optiscan.Models/View/Patient.js";
import { cons, map as map_1, tail, exists, head, isEmpty, singleton, ofArray, empty } from "../fable_modules/fable-library.3.7.12/List.js";
import { Cmd_OfAsyncWith_perform, Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Internal_BranchMsgChild$2, Internal_BranchModelChild$2, Internal_BranchMsgChildAndSibling$3, Internal_BranchModelChildAndSibling$3, StateTreeConfig$6, defaultConfig as defaultConfig_1, Internal_BranchMsgSibling$2, Internal_BranchModelSibling$2, Internal_StateTreeRoot$6, Internal_StateTreeModel$1, StateTreeNode$6, NavigationMsg$1, StateTreeMsg$4 } from "../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { isNullOrWhiteSpace, isNullOrEmpty } from "../fable_modules/fable-library.3.7.12/String.js";
import { SelectPropsMulti$1, LabelledGroup$1 } from "../Optiscan.SharedUI/bindings/ReactSelectBind.js";
import { DOMAttr, HTMLAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import * as react from "react";
import { keyValueList } from "../fable_modules/fable-library.3.7.12/MapUtil.js";
import { footer, content, Header_title, header, card } from "../fable_modules/Fulma.2.16.0/Components/Card.fs.js";
import { Color_IColor, Common_GenericOption } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { Option, columns } from "../fable_modules/Fulma.2.16.0/Layouts/Columns.fs.js";
import { column } from "../fable_modules/Fulma.2.16.0/Layouts/Column.fs.js";
import { FormField_resetCall, FormField_onChangeSet, FormField_text, FormField_ReactSelect_singleGrouped, FormField_ReactSelect_CallbackSingle$1, FormField_ReactSelect_single } from "../Optiscan.SharedUI/Forms.js";
import { Compose_Lens, Compose_Lens_op_GreaterMinusGreater_31E453EA, Optic_Get, Optic_Get_op_HatDot_21762A61, Optic_Set, Optic_Set_op_HatEquals_2170E4F5 } from "../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { SiteViewModel_get_name_ } from "../Optiscan.Models/View/Site.js";
import { Option as Option_1 } from "../fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { equals, partialApply, uncurry } from "../fable_modules/fable-library.3.7.12/Util.js";
import { Naming_UIElement, Naming_UIElement__get_asString, Naming_mkIdFromStrings, Functions_flip } from "../fable_modules/Webbler.Models.1.2.6/Common.fs.js";
import { Helpers_nothing } from "../fable_modules/Fable.React.7.4.3/Fable.React.Helpers.fs.js";
import { Option as Option_2, button } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { securedApi } from "../Api.js";
import { Common_eraseExceptionToAuthError } from "../Common/Common.js";
import { error as error_15 } from "../Optiscan.SharedUI/Toast.js";
import { Cmd_none, Cmd_batch } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { ErrorMessage_get_describe } from "../Optiscan.Models/ErrorMessage.js";
import { bind, some, toArray, map, defaultArg } from "../fable_modules/fable-library.3.7.12/Option.js";
import { Navigation_modifyUrl } from "../fable_modules/Fable.Elmish.Browser.3.0.4/navigation.fs.js";
import { Page, pageHash } from "../Pages.js";
import { Card_head, Card_foot, Card_body, Card_card, background, Option as Option_3, modal } from "../fable_modules/Fulma.2.16.0/Components/Modal.fs.js";
import { input, checkbox } from "../fable_modules/Fulma.2.16.0/Elements/Form/Checkbox.fs.js";
import { TelemetryAction } from "../Optiscan.Models/Api/Api.js";
import { Internal_combineChildIndexPathFromKeyPathMap, NavigationMsg$1_destructiveCast_Z33BEA92B, Internal_combineSiblingIndexPathFromKeyPathMap, defaultConfig, StateTree$3_$ctor_4E60E31B } from "../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { Cmd_map, Cmd_batch as Cmd_batch_1, Cmd_none as Cmd_none_1 } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { ofList } from "../fable_modules/fable-library.3.7.12/Map.js";
import { PatientDetailsMsg } from "./PatientDetails/PatientDetailsTypes.js";
import { patientView } from "./PatientDetails/PatientDetailsTree.js";
import { noteCreateState, noteEditState, noteDetailState } from "./PatientDetails/PatientNotes/PatientNotesTree.js";
import { sessionCreateState, sessionEditState, sessionDetailState } from "./PatientDetails/PatientSessions/PatientSessionsTree.js";
import { guestLinkShowState, guestLinkCreateState } from "./PatientDetails/PatientGuestLinks/PatientGuestLinksTree.js";
import { PatientTelepathologyMsg } from "./PatientTelepathology/PatientTelepathologyTypes.js";
import { patientTelepathologyView } from "./PatientTelepathology/PatientTelepathologyTree.js";
import { patientHistoricalComparisonView } from "./Historical/Comparison/HistoricalComparisonTree.js";
import { patientReportView } from "./PatientReport/PatientReportTree.js";

export const States_RestrictedModalHeadText = "Access to Patient Data is Restricted";

export const States_RestrictedModalBodyText = "Access to the selected patient data is restricted to the Clinical Staff of the Site the patient belongs to.  Please confirm that you\u0027d like to access this information by checking the box below and clicking the Confirm button.";

export function States_search(maybeRedirectPatientId, maybeRedirectSessionId) {
    return new StateTreeNode$6((_arg1) => [new SearchModel(new SearchType(1), PatientInfoModel_get_empty(), "", void 0, PatientViewModel_get_empty(), empty(), empty(), false, false, maybeRedirectPatientId, maybeRedirectSessionId, ""), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientSearchMsg(0)))], (model, dispatch) => {
        let matchValue, children_2;
        const isSearchDisabled = ((matchValue = model.searchType, (matchValue != null) ? ((matchValue.tag === 1) ? isNullOrEmpty(model.patientId) : (isNullOrWhiteSpace(model.patientInfo.firstName) ? true : isNullOrWhiteSpace(model.patientInfo.lastName))) : true)) ? true : (model.selectedSite == null);
        const groupedSites = ofArray([new LabelledGroup$1("Assigned Site", model.sites), new LabelledGroup$1("Authorized Sites", model.authorizedSites)]);
        const setter = (arg_1) => {
            dispatch(new StateTreeMsg$4(0, new PatientSearchMsg(5, arg_1)));
        };
        if (model.maybeRedirectPatientId != null) {
            const props = [new HTMLAttr(64, "is-loading"), ["style", {
                paddingTop: "5em",
            }]];
            return react.createElement("div", keyValueList(props, 1));
        }
        else {
            return card(singleton(new Common_GenericOption(0, "patient-search-card embed-card")), ofArray([header(empty(), singleton(Header_title(empty(), singleton("Patient Search")))), content(empty(), toList(delay(() => append(singleton_1("Please enter the patient information below and then click the search button"), delay(() => {
                let f1_2, optic, optic_2, f1_3, optic_5;
                return append(singleton_1(columns(singleton(new Option(9, singleton(["style", {
                    marginTop: "1em",
                }]))), ofArray([column(empty(), singleton(FormField_ReactSelect_single(SearchType_get_all(), [], SearchType_get_describe(), model.searchType, new FormField_ReactSelect_CallbackSingle$1(0, (f1_2 = ((optic = SearchModel_get_searchType_(), (value) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic)(value))), (arg_2) => {
                    setter(f1_2(arg_2));
                })), "Search Type", true, "Search Type", []))), column(empty(), singleton(FormField_ReactSelect_singleGrouped(groupedSites, [new SelectPropsMulti$1(11, model.loadingSites ? true : model.loadingAuthorizedSites)], (optic_2 = SiteViewModel_get_name_(), (target) => Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic_2)(target)), Optic_Get_op_HatDot_21762A61(new Optic_Get(0), SearchModel_get_selectedSite_())(model), new FormField_ReactSelect_CallbackSingle$1(0, (f1_3 = ((optic_5 = SearchModel_get_selectedSite_(), (value_2) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic_5)(value_2))), (arg_3) => {
                    setter(f1_3(arg_3));
                })), "Site", true, "Patient Search", toList(delay(() => (model.loadingSites ? singleton_1(new Option_1(10, "is-loading")) : empty_1()))))))]))), delay(() => append(singleton_1(react.createElement("hr", {})), delay(() => {
                    let f_2, optic_11, f, optic_7, l, f_1, optic_9, l_2;
                    const matchValue_1 = model.searchType;
                    return (matchValue_1 != null) ? ((matchValue_1.tag === 1) ? singleton_1(FormField_text("", empty(), [], FormField_onChangeSet(setter, uncurry(2, (f_2 = ((optic_11 = SearchModel_get_patientId_(), (value_8) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic_11)(value_8))), (b_2) => ((a_2) => Functions_flip(uncurry(2, f_2), b_2, a_2))))), "Patient Search", "Patient ID")([])) : singleton_1(columns(empty(), ofArray([column(empty(), singleton(FormField_text("", empty(), [], FormField_onChangeSet(setter, uncurry(2, (f = ((optic_7 = ((l = SearchModel_get_patientInfo_(), Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), PatientInfoModel_get_firstName_())(l))), (value_4) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic_7)(value_4))), (b) => ((a) => Functions_flip(uncurry(2, f), b, a))))), "Patient Search", "First Name")([]))), column(empty(), singleton(FormField_text("", empty(), [], FormField_onChangeSet(setter, uncurry(2, (f_1 = ((optic_9 = ((l_2 = SearchModel_get_patientInfo_(), Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), PatientInfoModel_get_lastName_())(l_2))), (value_6) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic_9)(value_6))), (b_1) => ((a_1) => Functions_flip(uncurry(2, f_1), b_1, a_1))))), "Patient Search", "Last Name")([])))])))) : singleton_1(Helpers_nothing);
                }))));
            }))))), footer(empty(), singleton((children_2 = [button(ofArray([new Option_2(18, (_arg2) => {
                dispatch(new StateTreeMsg$4(0, new PatientSearchMsg(6)));
            }), new Option_2(0, new Color_IColor(8))]), ofArray([Fa_i(ofArray([new Fa_IconOption(11, "fas fa-undo"), new Fa_IconOption(6), new Fa_IconOption(9)]), [react.createElement("br", {})]), "Clear Fields"])), button(ofArray([new Option_2(18, (_arg3) => {
                dispatch(new StateTreeMsg$4(0, new PatientSearchMsg(1)));
            }), new Option_2(16, isSearchDisabled)]), singleton("Search"))], react.createElement("div", {}, ...children_2))))]));
        }
    }, uncurry(4, (msg_2) => ((tupledArg) => {
        const token = tupledArg[0];
        return (_arg4) => ((model_1) => {
            let matchValue_2, siteId_1, matchValue_4, patientId, patient_1;
            let pattern_matching_result, sites, siteId, authSites, err, patient, maybePatient, patients, err_1, setter_1;
            if (msg_2.tag === 7) {
                if (msg_2.fields[0].tag === 1) {
                    pattern_matching_result = 4;
                    err = msg_2.fields[0].fields[0];
                }
                else {
                    pattern_matching_result = 1;
                    sites = msg_2.fields[0].fields[0];
                }
            }
            else if (msg_2.tag === 8) {
                pattern_matching_result = 2;
                siteId = msg_2.fields[0];
            }
            else if (msg_2.tag === 9) {
                if (msg_2.fields[0].tag === 1) {
                    pattern_matching_result = 4;
                    err = msg_2.fields[0].fields[0];
                }
                else {
                    pattern_matching_result = 3;
                    authSites = msg_2.fields[0].fields[0];
                }
            }
            else if (msg_2.tag === 1) {
                pattern_matching_result = 5;
            }
            else if (msg_2.tag === 4) {
                if (msg_2.fields[0].tag === 1) {
                    pattern_matching_result = 9;
                    err_1 = msg_2.fields[0].fields[0];
                }
                else {
                    pattern_matching_result = 6;
                    patient = msg_2.fields[0].fields[0];
                }
            }
            else if (msg_2.tag === 2) {
                if (msg_2.fields[0].tag === 1) {
                    pattern_matching_result = 9;
                    err_1 = msg_2.fields[0].fields[0];
                }
                else {
                    pattern_matching_result = 7;
                    maybePatient = msg_2.fields[0].fields[0];
                }
            }
            else if (msg_2.tag === 3) {
                if (msg_2.fields[0].tag === 1) {
                    pattern_matching_result = 9;
                    err_1 = msg_2.fields[0].fields[0];
                }
                else {
                    pattern_matching_result = 8;
                    patients = msg_2.fields[0].fields[0];
                }
            }
            else if (msg_2.tag === 5) {
                pattern_matching_result = 10;
                setter_1 = msg_2.fields[0];
            }
            else if (msg_2.tag === 6) {
                pattern_matching_result = 11;
            }
            else {
                pattern_matching_result = 0;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return [new SearchModel(model_1.searchType, model_1.patientInfo, model_1.patientId, model_1.selectedSite, model_1.patient, model_1.sites, model_1.authorizedSites, true, model_1.loadingAuthorizedSites, model_1.maybeRedirectPatientId, model_1.maybeRedirectSessionId, model_1.searchFailedMsg), (matchValue_2 = tupledArg[1].userData.siteIds, (!isEmpty(matchValue_2)) ? Cmd_OfAsyncWith_either((x) => {
                        Cmd_OfAsync_start(x);
                    }, securedApi(token).getSitesForAccount, head(matchValue_2).accountId, (arg_4) => (new StateTreeMsg$4(0, new PatientSearchMsg(7, arg_4))), (arg_5) => (new StateTreeMsg$4(1, Common_eraseExceptionToAuthError(arg_5)))) : Cmd_OfFunc_result(new StateTreeMsg$4(2)))];
                }
                case 1: {
                    return (!isEmpty(sites)) ? [new SearchModel(model_1.searchType, model_1.patientInfo, model_1.patientId, model_1.selectedSite, model_1.patient, sites, model_1.authorizedSites, false, model_1.loadingAuthorizedSites, model_1.maybeRedirectPatientId, model_1.maybeRedirectSessionId, model_1.searchFailedMsg), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientSearchMsg(8, head(sites).siteId)))] : [model_1, error_15("User Account has no sites")];
                }
                case 2: {
                    return [new SearchModel(model_1.searchType, model_1.patientInfo, model_1.patientId, model_1.selectedSite, model_1.patient, model_1.sites, model_1.authorizedSites, model_1.loadingSites, true, model_1.maybeRedirectPatientId, model_1.maybeRedirectSessionId, model_1.searchFailedMsg), Cmd_OfAsyncWith_either((x_1) => {
                        Cmd_OfAsync_start(x_1);
                    }, securedApi(token).getAllTargetSitesForSite, siteId, (arg_7) => (new StateTreeMsg$4(0, new PatientSearchMsg(9, arg_7))), (arg_8) => (new StateTreeMsg$4(1, Common_eraseExceptionToAuthError(arg_8))))];
                }
                case 3: {
                    return [new SearchModel(model_1.searchType, model_1.patientInfo, model_1.patientId, model_1.selectedSite, model_1.patient, model_1.sites, authSites, model_1.loadingSites, false, model_1.maybeRedirectPatientId, model_1.maybeRedirectSessionId, model_1.searchFailedMsg), (model_1.maybeRedirectPatientId == null) ? Cmd_OfFunc_result(new StateTreeMsg$4(2)) : Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientSearchMsg(1)))];
                }
                case 4: {
                    return [model_1, Cmd_batch(ofArray([error_15(ErrorMessage_get_describe()(err)), Cmd_OfFunc_result(new StateTreeMsg$4(2))]))];
                }
                case 5: {
                    return [model_1, (siteId_1 = defaultArg(map((s_4) => s_4.siteId, model_1.selectedSite), "00000000-0000-0000-0000-000000000000"), (matchValue_4 = [model_1.searchType, model_1.maybeRedirectPatientId], (matchValue_4[1] != null) ? ((patientId = matchValue_4[1], Cmd_OfAsyncWith_either((x_2) => {
                        Cmd_OfAsync_start(x_2);
                    }, securedApi(token).getPatient, patientId, (arg_10) => (new StateTreeMsg$4(0, new PatientSearchMsg(4, arg_10))), (arg_11) => (new StateTreeMsg$4(1, Common_eraseExceptionToAuthError(arg_11)))))) : ((matchValue_4[0] == null) ? error_15("Search Type must be selected before searching") : ((matchValue_4[0].tag === 1) ? Cmd_OfAsyncWith_either((x_4) => {
                        Cmd_OfAsync_start(x_4);
                    }, securedApi(token).searchForPatientIdHash, [model_1.patientId, siteId_1], (arg_16) => (new StateTreeMsg$4(0, new PatientSearchMsg(2, arg_16))), (arg_17) => (new StateTreeMsg$4(1, Common_eraseExceptionToAuthError(arg_17)))) : Cmd_OfAsyncWith_either((x_3) => {
                        Cmd_OfAsync_start(x_3);
                    }, securedApi(token).searchForPatientNameHash, [model_1.patientInfo.firstName, model_1.patientInfo.lastName, siteId_1], (arg_13) => (new StateTreeMsg$4(0, new PatientSearchMsg(3, arg_13))), (arg_14) => (new StateTreeMsg$4(1, Common_eraseExceptionToAuthError(arg_14))))))))];
                }
                case 6: {
                    return exists((s_5) => (s_5.siteId === patient.siteId), model_1.authorizedSites) ? [new SearchModel(model_1.searchType, model_1.patientInfo, model_1.patientId, model_1.selectedSite, patient, model_1.sites, model_1.authorizedSites, model_1.loadingSites, model_1.loadingAuthorizedSites, model_1.maybeRedirectPatientId, model_1.maybeRedirectSessionId, model_1.searchFailedMsg), Cmd_OfFunc_result(new StateTreeMsg$4(4, new NavigationMsg$1(0, ofArray(["RestrictedModal", "SearchForm"]))))] : [new SearchModel(model_1.searchType, model_1.patientInfo, model_1.patientId, model_1.selectedSite, patient, model_1.sites, model_1.authorizedSites, model_1.loadingSites, model_1.loadingAuthorizedSites, model_1.maybeRedirectPatientId, model_1.maybeRedirectSessionId, model_1.searchFailedMsg), Cmd_OfFunc_result(new StateTreeMsg$4(4, new NavigationMsg$1(0, ofArray(["list", "RestrictedModal", "SearchForm"]))))];
                }
                case 7: {
                    let pattern_matching_result_1, patient_2;
                    if (maybePatient != null) {
                        if ((patient_1 = maybePatient, exists((s_6) => (s_6.siteId === patient_1.siteId), model_1.authorizedSites))) {
                            pattern_matching_result_1 = 0;
                            patient_2 = maybePatient;
                        }
                        else {
                            pattern_matching_result_1 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                    switch (pattern_matching_result_1) {
                        case 0: {
                            return [new SearchModel(model_1.searchType, model_1.patientInfo, model_1.patientId, model_1.selectedSite, patient_2, model_1.sites, model_1.authorizedSites, model_1.loadingSites, model_1.loadingAuthorizedSites, model_1.maybeRedirectPatientId, model_1.maybeRedirectSessionId, model_1.searchFailedMsg), Cmd_batch(toList(delay(() => append((model_1.maybeRedirectPatientId == null) ? singleton_1(Navigation_modifyUrl(pageHash(new Page(6, patient_2.id, void 0)))) : empty_1(), delay(() => singleton_1(Cmd_OfFunc_result(new StateTreeMsg$4(4, new NavigationMsg$1(0, ofArray(["RestrictedModal", "SearchForm"]))))))))))];
                        }
                        case 1: {
                            if (maybePatient == null) {
                                return [new SearchModel(model_1.searchType, model_1.patientInfo, model_1.patientId, model_1.selectedSite, PatientViewModel_get_empty(), model_1.sites, model_1.authorizedSites, model_1.loadingSites, model_1.loadingAuthorizedSites, model_1.maybeRedirectPatientId, model_1.maybeRedirectSessionId, "Search found no matching patients."), Cmd_OfFunc_result(new StateTreeMsg$4(4, new NavigationMsg$1(4, "Search Failed Modal")))];
                            }
                            else {
                                const patient_3 = maybePatient;
                                return [new SearchModel(model_1.searchType, model_1.patientInfo, model_1.patientId, model_1.selectedSite, patient_3, model_1.sites, model_1.authorizedSites, model_1.loadingSites, model_1.loadingAuthorizedSites, model_1.maybeRedirectPatientId, model_1.maybeRedirectSessionId, model_1.searchFailedMsg), Cmd_batch(toList(delay(() => append((model_1.maybeRedirectPatientId == null) ? singleton_1(Navigation_modifyUrl(pageHash(new Page(6, patient_3.id, void 0)))) : empty_1(), delay(() => singleton_1(Cmd_OfFunc_result(new StateTreeMsg$4(4, new NavigationMsg$1(0, ofArray(["list", "RestrictedModal", "SearchForm"]))))))))))];
                            }
                        }
                    }
                }
                case 8: {
                    let pattern_matching_result_2, patient_6;
                    if (!isEmpty(patients)) {
                        if (isEmpty(tail(patients))) {
                            if (exists((s_7) => (s_7.siteId === head(patients).siteId), model_1.authorizedSites)) {
                                pattern_matching_result_2 = 0;
                                patient_6 = head(patients);
                            }
                            else {
                                pattern_matching_result_2 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_2 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_2 = 1;
                    }
                    switch (pattern_matching_result_2) {
                        case 0: {
                            return [new SearchModel(model_1.searchType, model_1.patientInfo, model_1.patientId, model_1.selectedSite, patient_6, model_1.sites, model_1.authorizedSites, model_1.loadingSites, model_1.loadingAuthorizedSites, model_1.maybeRedirectPatientId, model_1.maybeRedirectSessionId, model_1.searchFailedMsg), Cmd_batch(toList(delay(() => append((model_1.maybeRedirectPatientId == null) ? singleton_1(Navigation_modifyUrl(pageHash(new Page(6, patient_6.id, void 0)))) : empty_1(), delay(() => singleton_1(Cmd_OfFunc_result(new StateTreeMsg$4(4, new NavigationMsg$1(0, ofArray(["RestrictedModal", "SearchForm"]))))))))))];
                        }
                        case 1: {
                            return isEmpty(patients) ? [new SearchModel(model_1.searchType, model_1.patientInfo, model_1.patientId, model_1.selectedSite, PatientViewModel_get_empty(), model_1.sites, model_1.authorizedSites, model_1.loadingSites, model_1.loadingAuthorizedSites, model_1.maybeRedirectPatientId, model_1.maybeRedirectSessionId, "Search found no matching patients."), Cmd_OfFunc_result(new StateTreeMsg$4(4, new NavigationMsg$1(4, "Search Failed Modal")))] : (isEmpty(tail(patients)) ? [new SearchModel(model_1.searchType, model_1.patientInfo, model_1.patientId, model_1.selectedSite, head(patients), model_1.sites, model_1.authorizedSites, model_1.loadingSites, model_1.loadingAuthorizedSites, model_1.maybeRedirectPatientId, model_1.maybeRedirectSessionId, model_1.searchFailedMsg), Cmd_batch(toList(delay(() => append((model_1.maybeRedirectPatientId == null) ? singleton_1(Navigation_modifyUrl(pageHash(new Page(6, head(patients).id, void 0)))) : empty_1(), delay(() => singleton_1(Cmd_OfFunc_result(new StateTreeMsg$4(4, new NavigationMsg$1(0, ofArray(["list", "RestrictedModal", "SearchForm"]))))))))))] : [new SearchModel(model_1.searchType, model_1.patientInfo, model_1.patientId, model_1.selectedSite, PatientViewModel_get_empty(), model_1.sites, model_1.authorizedSites, model_1.loadingSites, model_1.loadingAuthorizedSites, model_1.maybeRedirectPatientId, model_1.maybeRedirectSessionId, "Search found several matching patients. Please retry the search using patient id."), Cmd_OfFunc_result(new StateTreeMsg$4(4, new NavigationMsg$1(4, "Search Failed Modal")))]);
                        }
                    }
                }
                case 9: {
                    return [new SearchModel(model_1.searchType, model_1.patientInfo, model_1.patientId, model_1.selectedSite, PatientViewModel_get_empty(), model_1.sites, model_1.authorizedSites, model_1.loadingSites, model_1.loadingAuthorizedSites, void 0, void 0, model_1.searchFailedMsg), error_15(ErrorMessage_get_describe()(err_1))];
                }
                case 10: {
                    return [setter_1(model_1), Cmd_none()];
                }
                case 11: {
                    const clearFieldCall = (fieldName) => {
                        FormField_resetCall(Naming_mkIdFromStrings(ofArray(["Patient Search", fieldName, Naming_UIElement__get_asString(new Naming_UIElement(0))])));
                    };
                    const matchValue_5 = model_1.searchType;
                    if (matchValue_5 == null) {
                    }
                    else if (matchValue_5.tag === 1) {
                        map_1(clearFieldCall, singleton("Patient ID"));
                    }
                    else {
                        map_1(clearFieldCall, ofArray(["First Name", "Last Name"]));
                    }
                    return [new SearchModel(model_1.searchType, PatientInfoModel_get_empty(), "", void 0, model_1.patient, model_1.sites, model_1.authorizedSites, model_1.loadingSites, model_1.loadingAuthorizedSites, model_1.maybeRedirectPatientId, model_1.maybeRedirectSessionId, model_1.searchFailedMsg), Cmd_none()];
                }
            }
        });
    })));
}

export function States_searchFailedModal() {
    return new StateTreeNode$6((deps) => [new SearchFailedModal(deps.searchFailedMsg), Cmd_OfFunc_result(new StateTreeMsg$4(2))], (model, dispatch) => modal(singleton(new Option_3(1, true)), ofArray([background(singleton(new Common_GenericOption(1, singleton(new DOMAttr(40, (_arg1) => {
        dispatch(new StateTreeMsg$4(4, new NavigationMsg$1(1)));
    })))), empty()), Card_card(empty(), ofArray([Card_body(empty(), singleton(model.searchFailedMsg)), Card_foot(empty(), singleton(button(singleton(new Option_2(18, (_arg2) => {
        dispatch(new StateTreeMsg$4(4, new NavigationMsg$1(1)));
    })), singleton("Ok"))))]))])), (_arg5, _arg4, _arg3, model_1) => [model_1, Cmd_none()]);
}

export function States_restrictedModal() {
    return new StateTreeNode$6((deps) => [new RestrictedSearchModel(false, deps.patient, deps.maybeRedirectPatientId, deps.maybeRedirectSessionId), Cmd_OfFunc_result(new StateTreeMsg$4(2))], (model, dispatch) => {
        let props_2, children_2, props;
        return modal(singleton(new Option_3(1, true)), singleton(Card_card(empty(), ofArray([Card_head(empty(), singleton(States_RestrictedModalHeadText)), Card_body(empty(), ofArray([States_RestrictedModalBodyText, (props_2 = [["style", {
            marginTop: "2em",
        }]], (children_2 = [checkbox(empty(), singleton(input(singleton(new Common_GenericOption(1, singleton(new DOMAttr(40, (e) => {
            dispatch(new StateTreeMsg$4(0, new RestrictedModalMsg(2, e.currentTarget.checked)));
        }))))))), (props = [["style", {
            marginLeft: "1em",
        }]], react.createElement("span", keyValueList(props, 1), "Request Access to data"))], react.createElement("div", keyValueList(props_2, 1), ...children_2)))])), Card_foot(empty(), ofArray([button(ofArray([new Option_2(18, (_arg1) => {
            dispatch(new StateTreeMsg$4(0, new RestrictedModalMsg(1)));
        }), new Option_2(16, !model.accessRequested)]), singleton("Confirm")), button(ofArray([new Option_2(18, (_arg2) => {
            dispatch(new StateTreeMsg$4(0, new RestrictedModalMsg(0)));
        }), new Option_2(0, new Color_IColor(8)), new Option_2(4)]), singleton("Go Back"))]))]))));
    }, uncurry(4, (msg_2) => ((tupledArg) => {
        const token = tupledArg[0];
        return (_arg5) => ((model_1) => ((msg_2.tag === 0) ? [model_1, Cmd_OfAsyncWith_perform((x) => {
            Cmd_OfAsync_start(x);
        }, securedApi(token).telemetry, new TelemetryAction(5, model_1.patient), (_arg3) => (new StateTreeMsg$4(4, new NavigationMsg$1(1))))] : ((msg_2.tag === 1) ? [model_1, Cmd_OfAsyncWith_perform((x_1) => {
            Cmd_OfAsync_start(x_1);
        }, securedApi(token).telemetry, new TelemetryAction(4, model_1.patient), (_arg4) => (new StateTreeMsg$4(4, new NavigationMsg$1(4, "list"))))] : [new RestrictedSearchModel(msg_2.fields[0], model_1.patient, model_1.maybeRedirectPatientId, model_1.maybeRedirectSessionId), Cmd_none()])));
    })));
}

export function stateTree(currentUser, maybeRedirectPatientId, maybeRedirectSessionId) {
    let current_67, child_17, siblingBuilder_18, current, current_1_1, config_1, maybeAutoMessage, key_3, current_3, current_5, child_13, config_24, inputRecord, current_58, keepCachedChildren_7, child_12, siblingBuilder_14, siblingBuilder_12, siblingBuilder_9, siblingBuilder_6, siblingBuilder_3, siblingBuilder, current_9, child, current_6, config_2, maybeAutoMessage_1, key_7, current_8, config_3, current_1_2, config_4, maybeAutoMessage_2, key_11, current_12, current_14, f1_8, current_1_3, config_6, maybeAutoMessage_3, key_16, current_18, current_20, child_3, current_21, config_7, maybeAutoMessage_4, key_20, current_23, config_8, f1_13, current_1_4, config_9, maybeAutoMessage_5, key_24, current_27, current_29, f1_16, current_1_5, config_11, maybeAutoMessage_6, key_29, current_33, current_35, child_7, current_36, config_12, maybeAutoMessage_7, key_33, current_38, config_13, f1_21, current_1_6, config_14, maybeAutoMessage_8, key_37, current_42, f1_24, current_1_7, config_17, inputRecord_1, maybeAutoMessage_9, key_41, current_47, f1_27, current_1_8, config_20, inputRecord_2, maybeAutoMessage_10, key_45, current_52, config_23, maybeAutoMessage_11, key_49, current_57, current_61, config_25, maybeAutoMessage_12, key_52, current_60, config_26, keepCachedChildren_9, child_16, current_66, config_27, maybeAutoMessage_13, key_56, current_65, config_28, keepCachedChildren_11, child_19, current_71, config_29, maybeAutoMessage_14, key_60, current_70;
    return StateTree$3_$ctor_4E60E31B((current_67 = States_search(maybeRedirectPatientId, maybeRedirectSessionId), (child_17 = ((siblingBuilder_18 = ((current = States_searchFailedModal(), (current_1_1 = ((config_1 = defaultConfig(), (maybeAutoMessage = config_1.MaybeSendMessageOnNavigation, (key_3 = "Search Failed Modal", (current_3 = current, new Internal_StateTreeRoot$6((_arg2_1, deps, maybeCachedModel, _arg1_1) => {
        const matchValue = [maybeCachedModel, config_1.ReInitOnNavigation, maybeAutoMessage];
        let pattern_matching_result, model_2, model_1_1, msg_8;
        if (matchValue[0] != null) {
            if (matchValue[1]) {
                pattern_matching_result = 2;
            }
            else if (matchValue[2] != null) {
                pattern_matching_result = 1;
                model_1_1 = matchValue[0];
                msg_8 = matchValue[2];
            }
            else {
                pattern_matching_result = 0;
                model_2 = matchValue[0];
            }
        }
        else {
            pattern_matching_result = 2;
        }
        switch (pattern_matching_result) {
            case 0: {
                return [model_2, Cmd_none_1()];
            }
            case 1: {
                return [model_1_1, Cmd_OfFunc_result(msg_8)];
            }
            case 2: {
                const patternInput = current_3.Init(deps);
                return [new Internal_StateTreeModel$1(patternInput[0], false), Cmd_batch_1(toList(delay(() => append(singleton_1(patternInput[1]), delay(() => ofArray(toArray(map(Cmd_OfFunc_result, maybeAutoMessage))))))))];
            }
        }
    }, (_arg3, dispatch, model_2_1) => current_3.View(model_2_1.NodeModel, dispatch), (_arg4, msg_3_1, pass, deps_1, model_3) => {
        switch (msg_3_1.tag) {
            case 1: {
                console.error(some(`State tree error: ${msg_3_1.fields[0]}`));
                return [model_3, Cmd_none_1(), void 0, void 0];
            }
            case 2: {
                return [new Internal_StateTreeModel$1(model_3.NodeModel, true), Cmd_none_1(), void 0, void 0];
            }
            case 3: {
                return [model_3, Cmd_none_1(), void 0, msg_3_1.fields[0]];
            }
            case 4: {
                return [model_3, Cmd_none_1(), msg_3_1.fields[0], void 0];
            }
            default: {
                const patternInput_1 = current_3.Update(msg_3_1.fields[0], pass, deps_1, model_3.NodeModel);
                return [new Internal_StateTreeModel$1(patternInput_1[0], model_3.Initialized), patternInput_1[1], void 0, void 0];
            }
        }
    }, (msg_4_1, deps_2, model_4) => {
        const patternInput_2 = config_1.UpdateInbound(msg_4_1, deps_2, model_4.NodeModel);
        return [new Internal_StateTreeModel$1(patternInput_2[0], model_4.Initialized), patternInput_2[1]];
    }, key_3, ofList(singleton([singleton(key_3), singleton(0)])))))))), (sibling) => {
        const current_4 = current_1_1;
        const sibling_1 = sibling;
        return new Internal_StateTreeRoot$6((indexPath, deps_3, maybeCachedModel_1, keepCachedSiblings) => {
            const maybeCachedCurrentModel = bind((cachedModel) => cachedModel.MaybeCurrentModel, maybeCachedModel_1);
            const maybeCachedSiblingModel = bind((cachedModel_1) => cachedModel_1.MaybeSiblingModel, maybeCachedModel_1);
            let pattern_matching_result_1, x, xs;
            if (isEmpty(indexPath)) {
                pattern_matching_result_1 = 1;
            }
            else if (head(indexPath) === 0) {
                if (isEmpty(tail(indexPath))) {
                    pattern_matching_result_1 = 0;
                }
                else {
                    pattern_matching_result_1 = 1;
                }
            }
            else {
                pattern_matching_result_1 = 2;
                x = head(indexPath);
                xs = tail(indexPath);
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    const patternInput_3 = current_4.Init(indexPath, deps_3, maybeCachedCurrentModel, false);
                    return [new Internal_BranchModelSibling$2(patternInput_3[0], keepCachedSiblings ? maybeCachedSiblingModel : (void 0)), Cmd_map((arg0_3) => (new Internal_BranchMsgSibling$2(0, arg0_3)), patternInput_3[1])];
                }
                case 1: {
                    console.error(some("Failed to initialize state, invalid index"));
                    return [defaultArg(maybeCachedModel_1, new Internal_BranchModelSibling$2(void 0, void 0)), Cmd_none_1()];
                }
                case 2: {
                    const patternInput_1_1 = sibling_1.Init(cons(x - 1, xs), deps_3, maybeCachedSiblingModel, keepCachedSiblings);
                    return [new Internal_BranchModelSibling$2(keepCachedSiblings ? maybeCachedCurrentModel : (void 0), patternInput_1_1[0]), Cmd_map((arg0_1_1) => (new Internal_BranchMsgSibling$2(1, arg0_1_1)), patternInput_1_1[1])];
                }
            }
        }, (indexPath_1, dispatch_1, model_5) => (isEmpty(indexPath_1) ? "Woops, looks like you took a wrong turn..." : ((head(indexPath_1) === 0) ? (isEmpty(tail(indexPath_1)) ? defaultArg(map(partialApply(1, current_4.View, [indexPath_1, (arg) => {
            dispatch_1(new Internal_BranchMsgSibling$2(0, arg));
        }]), model_5.MaybeCurrentModel), "Loading...") : "Woops, looks like you took a wrong turn...") : defaultArg(map(partialApply(1, sibling_1.View, [cons(head(indexPath_1) - 1, tail(indexPath_1)), (arg_1) => {
            dispatch_1(new Internal_BranchMsgSibling$2(1, arg_1));
        }]), model_5.MaybeSiblingModel), "Loading..."))), (indexPath_2, branchMsg, pass_1, deps_1_1, model_1_2) => {
            const matchValue_1 = [branchMsg, indexPath_2];
            let pattern_matching_result_2, msg_1_2, msg_2_2, x_3, xs_3;
            if (matchValue_1[0].tag === 1) {
                if (!isEmpty(matchValue_1[1])) {
                    if (head(matchValue_1[1]) > 0) {
                        pattern_matching_result_2 = 1;
                        msg_2_2 = matchValue_1[0].fields[0];
                        x_3 = head(matchValue_1[1]);
                        xs_3 = tail(matchValue_1[1]);
                    }
                    else {
                        pattern_matching_result_2 = 2;
                    }
                }
                else {
                    pattern_matching_result_2 = 2;
                }
            }
            else if (!isEmpty(matchValue_1[1])) {
                if (head(matchValue_1[1]) === 0) {
                    pattern_matching_result_2 = 0;
                    msg_1_2 = matchValue_1[0].fields[0];
                }
                else {
                    pattern_matching_result_2 = 2;
                }
            }
            else {
                pattern_matching_result_2 = 2;
            }
            switch (pattern_matching_result_2) {
                case 0: {
                    const matchValue_1_1 = model_1_2.MaybeCurrentModel;
                    if (matchValue_1_1 == null) {
                        console.error(some("Failed to update state, not initialized"));
                        return [model_1_2, Cmd_none_1(), void 0, void 0];
                    }
                    else {
                        const patternInput_2_1 = current_4.Update(indexPath_2, msg_1_2, pass_1, deps_1_1, matchValue_1_1);
                        return [new Internal_BranchModelSibling$2(patternInput_2_1[0], model_1_2.MaybeSiblingModel), Cmd_map((arg0_5) => (new Internal_BranchMsgSibling$2(0, arg0_5)), patternInput_2_1[1]), patternInput_2_1[2], patternInput_2_1[3]];
                    }
                }
                case 1: {
                    const matchValue_2 = model_1_2.MaybeSiblingModel;
                    if (matchValue_2 == null) {
                        console.error(some("Failed to update state, not initialized"));
                        return [model_1_2, Cmd_none_1(), void 0, void 0];
                    }
                    else {
                        const patternInput_3_1 = sibling_1.Update(cons(x_3 - 1, xs_3), msg_2_2, pass_1, deps_1_1, matchValue_2);
                        return [new Internal_BranchModelSibling$2(model_1_2.MaybeCurrentModel, patternInput_3_1[0]), Cmd_map((arg0_7) => (new Internal_BranchMsgSibling$2(1, arg0_7)), patternInput_3_1[1]), patternInput_3_1[2], patternInput_3_1[3]];
                    }
                }
                case 2: {
                    console.warn(some("Got an update for a node that shouldn\u0027t be initialized, ignoring"));
                    return [model_1_2, Cmd_none_1(), void 0, void 0];
                }
            }
        }, (msg_3_2, deps_2_1, model_2_2) => {
            let patternInput_5;
            const matchValue_3 = model_2_2.MaybeCurrentModel;
            if (matchValue_3 == null) {
                patternInput_5 = [model_2_2.MaybeCurrentModel, Cmd_none_1()];
            }
            else {
                const patternInput_4 = current_4.UpdateInbound(msg_3_2, deps_2_1, matchValue_3);
                patternInput_5 = [patternInput_4[0], patternInput_4[1]];
            }
            let patternInput_7;
            const matchValue_4 = model_2_2.MaybeSiblingModel;
            if (matchValue_4 == null) {
                patternInput_7 = [model_2_2.MaybeSiblingModel, Cmd_none_1()];
            }
            else {
                const patternInput_6 = sibling_1.UpdateInbound(msg_3_2, deps_2_1, matchValue_4);
                patternInput_7 = [patternInput_6[0], patternInput_6[1]];
            }
            return [new Internal_BranchModelSibling$2(patternInput_5[0], patternInput_7[0]), Cmd_batch_1(ofArray([Cmd_map((arg0_8) => (new Internal_BranchMsgSibling$2(0, arg0_8)), patternInput_5[1]), Cmd_map((arg0_9) => (new Internal_BranchMsgSibling$2(1, arg0_9)), patternInput_7[1])]))];
        }, current_4.LastKey, Internal_combineSiblingIndexPathFromKeyPathMap(sibling_1.IndexPathFromKeyPath, current_4.IndexPathFromKeyPath));
    }))), (current_5 = States_restrictedModal(), (child_13 = ((config_24 = ((inputRecord = defaultConfig_1(), new StateTreeConfig$6(inputRecord.VisibleToChildren, true, inputRecord.MaybeSendMessageOnNavigation, inputRecord.KeepCachedChildren, (msg, _arg1, model) => {
        let pattern_matching_result_3;
        if (msg.tag === 0) {
            if (msg.fields[0] === model.patient.id) {
                pattern_matching_result_3 = 0;
            }
            else {
                pattern_matching_result_3 = 1;
            }
        }
        else {
            pattern_matching_result_3 = 1;
        }
        switch (pattern_matching_result_3) {
            case 0: {
                return [model, Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientDetailsMsg(4)))];
            }
            case 1: {
                let pattern_matching_result_4;
                if (msg.tag === 2) {
                    if (msg.fields[0] === model.patient.deviceId) {
                        pattern_matching_result_4 = 0;
                    }
                    else {
                        pattern_matching_result_4 = 1;
                    }
                }
                else {
                    pattern_matching_result_4 = 1;
                }
                switch (pattern_matching_result_4) {
                    case 0: {
                        return [model, Cmd_OfFunc_result(new StateTreeMsg$4(4, new NavigationMsg$1(4, "Telepathology")))];
                    }
                    case 1: {
                        return [model, Cmd_none()];
                    }
                }
            }
        }
    }))), (current_58 = patientView(currentUser), (keepCachedChildren_7 = config_24.KeepCachedChildren, (child_12 = ((siblingBuilder_14 = ((siblingBuilder_12 = ((siblingBuilder_9 = ((siblingBuilder_6 = ((siblingBuilder_3 = ((siblingBuilder = ((current_9 = noteDetailState(currentUser), (child = ((current_6 = noteEditState(), (config_2 = defaultConfig(), (maybeAutoMessage_1 = config_2.MaybeSendMessageOnNavigation, (key_7 = "edit", (current_8 = current_6, new Internal_StateTreeRoot$6((_arg2_2, deps_4, maybeCachedModel_2, _arg1_2) => {
        const matchValue_5 = [maybeCachedModel_2, config_2.ReInitOnNavigation, maybeAutoMessage_1];
        let pattern_matching_result_5, model_6, model_1_3, msg_12;
        if (matchValue_5[0] != null) {
            if (matchValue_5[1]) {
                pattern_matching_result_5 = 2;
            }
            else if (matchValue_5[2] != null) {
                pattern_matching_result_5 = 1;
                model_1_3 = matchValue_5[0];
                msg_12 = matchValue_5[2];
            }
            else {
                pattern_matching_result_5 = 0;
                model_6 = matchValue_5[0];
            }
        }
        else {
            pattern_matching_result_5 = 2;
        }
        switch (pattern_matching_result_5) {
            case 0: {
                return [model_6, Cmd_none_1()];
            }
            case 1: {
                return [model_1_3, Cmd_OfFunc_result(msg_12)];
            }
            case 2: {
                const patternInput_8 = current_8.Init(deps_4);
                return [new Internal_StateTreeModel$1(patternInput_8[0], false), Cmd_batch_1(toList(delay(() => append(singleton_1(patternInput_8[1]), delay(() => ofArray(toArray(map(Cmd_OfFunc_result, maybeAutoMessage_1))))))))];
            }
        }
    }, (_arg3_1, dispatch_2, model_2_3) => current_8.View(model_2_3.NodeModel, dispatch_2), (_arg4_1, msg_3_3, pass_2, deps_1_2, model_3_1) => {
        switch (msg_3_3.tag) {
            case 1: {
                console.error(some(`State tree error: ${msg_3_3.fields[0]}`));
                return [model_3_1, Cmd_none_1(), void 0, void 0];
            }
            case 2: {
                return [new Internal_StateTreeModel$1(model_3_1.NodeModel, true), Cmd_none_1(), void 0, void 0];
            }
            case 3: {
                return [model_3_1, Cmd_none_1(), void 0, msg_3_3.fields[0]];
            }
            case 4: {
                return [model_3_1, Cmd_none_1(), msg_3_3.fields[0], void 0];
            }
            default: {
                const patternInput_1_2 = current_8.Update(msg_3_3.fields[0], pass_2, deps_1_2, model_3_1.NodeModel);
                return [new Internal_StateTreeModel$1(patternInput_1_2[0], model_3_1.Initialized), patternInput_1_2[1], void 0, void 0];
            }
        }
    }, (msg_4_2, deps_2_2, model_4_1) => {
        const patternInput_2_2 = config_2.UpdateInbound(msg_4_2, deps_2_2, model_4_1.NodeModel);
        return [new Internal_StateTreeModel$1(patternInput_2_2[0], model_4_1.Initialized), patternInput_2_2[1]];
    }, key_7, ofList(singleton([singleton(key_7), singleton(0)]))))))))), (config_3 = defaultConfig(), (current_1_2 = ((config_4 = config_3, (maybeAutoMessage_2 = config_4.MaybeSendMessageOnNavigation, (key_11 = "detail", (current_12 = current_9, new Internal_StateTreeRoot$6((_arg2_3, deps_5, maybeCachedModel_3, _arg1_3) => {
        const matchValue_6 = [maybeCachedModel_3, config_4.ReInitOnNavigation, maybeAutoMessage_2];
        let pattern_matching_result_6, model_7, model_1_4, msg_15;
        if (matchValue_6[0] != null) {
            if (matchValue_6[1]) {
                pattern_matching_result_6 = 2;
            }
            else if (matchValue_6[2] != null) {
                pattern_matching_result_6 = 1;
                model_1_4 = matchValue_6[0];
                msg_15 = matchValue_6[2];
            }
            else {
                pattern_matching_result_6 = 0;
                model_7 = matchValue_6[0];
            }
        }
        else {
            pattern_matching_result_6 = 2;
        }
        switch (pattern_matching_result_6) {
            case 0: {
                return [model_7, Cmd_none_1()];
            }
            case 1: {
                return [model_1_4, Cmd_OfFunc_result(msg_15)];
            }
            case 2: {
                const patternInput_9 = current_12.Init(deps_5);
                return [new Internal_StateTreeModel$1(patternInput_9[0], false), Cmd_batch_1(toList(delay(() => append(singleton_1(patternInput_9[1]), delay(() => ofArray(toArray(map(Cmd_OfFunc_result, maybeAutoMessage_2))))))))];
            }
        }
    }, (_arg3_2, dispatch_3, model_2_4) => current_12.View(model_2_4.NodeModel, dispatch_3), (_arg4_2, msg_3_4, pass_3, deps_1_3, model_3_2) => {
        switch (msg_3_4.tag) {
            case 1: {
                console.error(some(`State tree error: ${msg_3_4.fields[0]}`));
                return [model_3_2, Cmd_none_1(), void 0, void 0];
            }
            case 2: {
                return [new Internal_StateTreeModel$1(model_3_2.NodeModel, true), Cmd_none_1(), void 0, void 0];
            }
            case 3: {
                return [model_3_2, Cmd_none_1(), void 0, msg_3_4.fields[0]];
            }
            case 4: {
                return [model_3_2, Cmd_none_1(), msg_3_4.fields[0], void 0];
            }
            default: {
                const patternInput_1_3 = current_12.Update(msg_3_4.fields[0], pass_3, deps_1_3, model_3_2.NodeModel);
                return [new Internal_StateTreeModel$1(patternInput_1_3[0], model_3_2.Initialized), patternInput_1_3[1], void 0, void 0];
            }
        }
    }, (msg_4_3, deps_2_3, model_4_2) => {
        const patternInput_2_3 = config_4.UpdateInbound(msg_4_3, deps_2_3, model_4_2.NodeModel);
        return [new Internal_StateTreeModel$1(patternInput_2_3[0], model_4_2.Initialized), patternInput_2_3[1]];
    }, key_11, ofList(singleton([singleton(key_11), singleton(0)])))))))), (sibling_2) => {
        const keepCachedChildren_1 = config_3.KeepCachedChildren;
        const child_2 = child;
        const current_13 = current_1_2;
        const sibling_3 = sibling_2;
        return new Internal_StateTreeRoot$6((indexPath_3, deps_6, maybeCachedModel_4, keepCachedSiblings_1) => {
            const maybeCachedCurrentModel_1 = bind((cachedModel_2) => cachedModel_2.MaybeCurrentModel, maybeCachedModel_4);
            const maybeCachedSiblingModel_1 = bind((cachedModel_1_1) => cachedModel_1_1.MaybeSiblingModel, maybeCachedModel_4);
            const maybeCachedChildModel = bind((cachedModel_2_1) => cachedModel_2_1.MaybeChildModel, maybeCachedModel_4);
            const matchValue_7 = [indexPath_3, maybeCachedCurrentModel_1];
            let pattern_matching_result_7, currentModel_1_1, xs_4, x_4, xs_1_1;
            if (isEmpty(matchValue_7[0])) {
                pattern_matching_result_7 = 3;
            }
            else if (head(matchValue_7[0]) === 0) {
                if (isEmpty(tail(matchValue_7[0]))) {
                    pattern_matching_result_7 = 0;
                }
                else if (matchValue_7[1] != null) {
                    pattern_matching_result_7 = 1;
                    currentModel_1_1 = matchValue_7[1];
                    xs_4 = tail(matchValue_7[0]);
                }
                else {
                    pattern_matching_result_7 = 0;
                }
            }
            else {
                pattern_matching_result_7 = 2;
                x_4 = head(matchValue_7[0]);
                xs_1_1 = tail(matchValue_7[0]);
            }
            switch (pattern_matching_result_7) {
                case 0: {
                    const patternInput_10 = current_13.Init(indexPath_3, deps_6, maybeCachedCurrentModel_1, false);
                    return [new Internal_BranchModelChildAndSibling$3(patternInput_10[0], keepCachedSiblings_1 ? maybeCachedSiblingModel_1 : (void 0), keepCachedChildren_1 ? maybeCachedChildModel : (void 0)), Cmd_map((arg0_10) => (new Internal_BranchMsgChildAndSibling$3(0, arg0_10)), patternInput_10[1])];
                }
                case 1: {
                    const patternInput_1_4 = child_2.Init(xs_4, currentModel_1_1.NodeModel, maybeCachedChildModel, keepCachedChildren_1);
                    return [new Internal_BranchModelChildAndSibling$3(currentModel_1_1, keepCachedSiblings_1 ? maybeCachedSiblingModel_1 : (void 0), patternInput_1_4[0]), Cmd_map((arg0_2_2) => (new Internal_BranchMsgChildAndSibling$3(2, arg0_2_2)), patternInput_1_4[1])];
                }
                case 2: {
                    const patternInput_2_4 = sibling_3.Init(cons(x_4 - 1, xs_1_1), deps_6, maybeCachedSiblingModel_1, keepCachedSiblings_1);
                    return [new Internal_BranchModelChildAndSibling$3(keepCachedSiblings_1 ? maybeCachedCurrentModel_1 : (void 0), patternInput_2_4[0], keepCachedChildren_1 ? maybeCachedChildModel : (void 0)), Cmd_map((arg0_3_2) => (new Internal_BranchMsgChildAndSibling$3(1, arg0_3_2)), patternInput_2_4[1])];
                }
                case 3: {
                    console.error(some("Failed to initialize state, invalid index"));
                    return [defaultArg(maybeCachedModel_4, new Internal_BranchModelChildAndSibling$3(void 0, void 0, void 0)), Cmd_none_1()];
                }
            }
        }, (indexPath_1_1, dispatch_4, model_8) => {
            if (isEmpty(indexPath_1_1)) {
                return "Woops, looks like you took a wrong turn...";
            }
            else if (head(indexPath_1_1) === 0) {
                if (isEmpty(tail(indexPath_1_1))) {
                    return defaultArg(map(partialApply(1, current_13.View, [indexPath_1_1, (arg_2) => {
                        dispatch_4(new Internal_BranchMsgChildAndSibling$3(0, arg_2));
                    }]), model_8.MaybeCurrentModel), "Loading...");
                }
                else {
                    const children = toList(delay(() => append(config_3.VisibleToChildren ? singleton_1(defaultArg(map(partialApply(1, current_13.View, [indexPath_1_1, (arg_3) => {
                        dispatch_4(new Internal_BranchMsgChildAndSibling$3(0, arg_3));
                    }]), model_8.MaybeCurrentModel), "Loading...")) : empty_1(), delay(() => singleton_1(defaultArg(map(partialApply(1, child_2.View, [tail(indexPath_1_1), (arg_4) => {
                        dispatch_4(new Internal_BranchMsgChildAndSibling$3(2, arg_4));
                    }]), model_8.MaybeChildModel), "Loading..."))))));
                    return react.createElement("div", {}, ...children);
                }
            }
            else {
                return defaultArg(map(partialApply(1, sibling_3.View, [cons(head(indexPath_1_1) - 1, tail(indexPath_1_1)), (arg_5) => {
                    dispatch_4(new Internal_BranchMsgChildAndSibling$3(1, arg_5));
                }]), model_8.MaybeSiblingModel), "Loading...");
            }
        }, (indexPath_2_1, branchMsg_1, pass_4, deps_1_4, model_1_5) => {
            let newNodeModel;
            const matchValue_1_2 = [branchMsg_1, indexPath_2_1];
            let pattern_matching_result_8, msg_1_5, xs_5, msg_2_5, xs_6, msg_3_5, x_3_1, xs_7;
            if (matchValue_1_2[0].tag === 2) {
                if (!isEmpty(matchValue_1_2[1])) {
                    if (head(matchValue_1_2[1]) === 0) {
                        pattern_matching_result_8 = 1;
                        msg_2_5 = matchValue_1_2[0].fields[0];
                        xs_6 = tail(matchValue_1_2[1]);
                    }
                    else {
                        pattern_matching_result_8 = 3;
                    }
                }
                else {
                    pattern_matching_result_8 = 3;
                }
            }
            else if (matchValue_1_2[0].tag === 1) {
                if (!isEmpty(matchValue_1_2[1])) {
                    if (head(matchValue_1_2[1]) > 0) {
                        pattern_matching_result_8 = 2;
                        msg_3_5 = matchValue_1_2[0].fields[0];
                        x_3_1 = head(matchValue_1_2[1]);
                        xs_7 = tail(matchValue_1_2[1]);
                    }
                    else {
                        pattern_matching_result_8 = 3;
                    }
                }
                else {
                    pattern_matching_result_8 = 3;
                }
            }
            else if (!isEmpty(matchValue_1_2[1])) {
                if (head(matchValue_1_2[1]) === 0) {
                    pattern_matching_result_8 = 0;
                    msg_1_5 = matchValue_1_2[0].fields[0];
                    xs_5 = tail(matchValue_1_2[1]);
                }
                else {
                    pattern_matching_result_8 = 3;
                }
            }
            else {
                pattern_matching_result_8 = 3;
            }
            switch (pattern_matching_result_8) {
                case 0: {
                    const matchValue_2_1 = model_1_5.MaybeCurrentModel;
                    if (matchValue_2_1 == null) {
                        console.error(some("Failed to update state, not initialized"));
                        return [model_1_5, Cmd_none_1(), void 0, void 0];
                    }
                    else {
                        const currentModel_2_1 = matchValue_2_1;
                        const patternInput_3_2 = current_13.Update(indexPath_2_1, msg_1_5, pass_4, deps_1_4, currentModel_2_1);
                        const newCurrentModel_2 = patternInput_3_2[0];
                        const maybeOutboundMsg_2 = patternInput_3_2[3];
                        const maybeNavMsg_2 = patternInput_3_2[2];
                        const currentCmd_1_1 = patternInput_3_2[1];
                        let pattern_matching_result_9;
                        if (msg_1_5.tag === 2) {
                            if (!equals(xs_5, empty())) {
                                pattern_matching_result_9 = 0;
                            }
                            else {
                                pattern_matching_result_9 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_9 = 1;
                        }
                        switch (pattern_matching_result_9) {
                            case 0: {
                                const patternInput_4_1 = child_2.Init(xs_5, currentModel_2_1.NodeModel, model_1_5.MaybeChildModel, false);
                                return [new Internal_BranchModelChildAndSibling$3(newCurrentModel_2, model_1_5.MaybeSiblingModel, patternInput_4_1[0]), Cmd_batch_1(ofArray([Cmd_map((arg0_10_1) => (new Internal_BranchMsgChildAndSibling$3(0, arg0_10_1)), currentCmd_1_1), Cmd_map((arg0_11) => (new Internal_BranchMsgChildAndSibling$3(2, arg0_11)), patternInput_4_1[1])])), maybeNavMsg_2, maybeOutboundMsg_2];
                            }
                            case 1: {
                                return [new Internal_BranchModelChildAndSibling$3(newCurrentModel_2, model_1_5.MaybeSiblingModel, model_1_5.MaybeChildModel), Cmd_map((arg0_13) => (new Internal_BranchMsgChildAndSibling$3(0, arg0_13)), currentCmd_1_1), maybeNavMsg_2, maybeOutboundMsg_2];
                            }
                        }
                    }
                }
                case 1: {
                    const matchValue_3_1 = [model_1_5.MaybeCurrentModel, model_1_5.MaybeChildModel];
                    let pattern_matching_result_10, childModel_1, currentModel_3_1;
                    if (matchValue_3_1[0] != null) {
                        if (matchValue_3_1[1] != null) {
                            pattern_matching_result_10 = 0;
                            childModel_1 = matchValue_3_1[1];
                            currentModel_3_1 = matchValue_3_1[0];
                        }
                        else {
                            pattern_matching_result_10 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_10 = 1;
                    }
                    switch (pattern_matching_result_10) {
                        case 0: {
                            const patternInput_5_1 = child_2.Update(xs_6, msg_2_5, pass_4, currentModel_3_1.NodeModel, childModel_1);
                            const maybeNavMsg_1_1 = patternInput_5_1[2];
                            return [new Internal_BranchModelChildAndSibling$3((maybeNavMsg_1_1 != null) ? ((maybeNavMsg_1_1.tag === 2) ? ((newNodeModel = maybeNavMsg_1_1.fields[0], map((model_2_5) => (new Internal_StateTreeModel$1(newNodeModel, model_2_5.Initialized)), model_1_5.MaybeCurrentModel))) : model_1_5.MaybeCurrentModel) : model_1_5.MaybeCurrentModel, model_1_5.MaybeSiblingModel, patternInput_5_1[0]), Cmd_map((arg0_15) => (new Internal_BranchMsgChildAndSibling$3(2, arg0_15)), patternInput_5_1[1]), map(NavigationMsg$1_destructiveCast_Z33BEA92B, maybeNavMsg_1_1), patternInput_5_1[3]];
                        }
                        case 1: {
                            console.error(some("Failed to update state, parent not initialized"));
                            return [model_1_5, Cmd_none_1(), void 0, void 0];
                        }
                    }
                }
                case 2: {
                    const matchValue_4_1 = model_1_5.MaybeSiblingModel;
                    if (matchValue_4_1 == null) {
                        console.error(some("Failed to update state, not initialized"));
                        return [model_1_5, Cmd_none_1(), void 0, void 0];
                    }
                    else {
                        const patternInput_6_1 = sibling_3.Update(cons(x_3_1 - 1, xs_7), msg_3_5, pass_4, deps_1_4, matchValue_4_1);
                        return [new Internal_BranchModelChildAndSibling$3(model_1_5.MaybeCurrentModel, patternInput_6_1[0], model_1_5.MaybeChildModel), Cmd_map((arg0_17) => (new Internal_BranchMsgChildAndSibling$3(1, arg0_17)), patternInput_6_1[1]), patternInput_6_1[2], patternInput_6_1[3]];
                    }
                }
                case 3: {
                    console.warn(some("Got an update for a node that shouldn\u0027t be initialized, ignoring"));
                    return [model_1_5, Cmd_none_1(), void 0, void 0];
                }
            }
        }, (msg_4_4, deps_2_4, model_3_3) => {
            let patternInput_8_1;
            const matchValue_5_1 = model_3_3.MaybeCurrentModel;
            if (matchValue_5_1 == null) {
                patternInput_8_1 = [model_3_3.MaybeCurrentModel, Cmd_none_1()];
            }
            else {
                const patternInput_7_1 = current_13.UpdateInbound(msg_4_4, deps_2_4, matchValue_5_1);
                patternInput_8_1 = [patternInput_7_1[0], patternInput_7_1[1]];
            }
            let patternInput_10_1;
            const matchValue_6_1 = [model_3_3.MaybeChildModel, model_3_3.MaybeCurrentModel];
            let pattern_matching_result_11, childModel_2, currentModel_5;
            if (matchValue_6_1[0] != null) {
                if (matchValue_6_1[1] != null) {
                    pattern_matching_result_11 = 0;
                    childModel_2 = matchValue_6_1[0];
                    currentModel_5 = matchValue_6_1[1];
                }
                else {
                    pattern_matching_result_11 = 1;
                }
            }
            else {
                pattern_matching_result_11 = 1;
            }
            switch (pattern_matching_result_11) {
                case 0: {
                    const patternInput_9_1 = child_2.UpdateInbound(msg_4_4, currentModel_5.NodeModel, childModel_2);
                    patternInput_10_1 = [patternInput_9_1[0], patternInput_9_1[1]];
                    break;
                }
                case 1: {
                    patternInput_10_1 = [model_3_3.MaybeChildModel, Cmd_none_1()];
                    break;
                }
            }
            let patternInput_12;
            const matchValue_7_1 = model_3_3.MaybeSiblingModel;
            if (matchValue_7_1 == null) {
                patternInput_12 = [model_3_3.MaybeSiblingModel, Cmd_none_1()];
            }
            else {
                const patternInput_11 = sibling_3.UpdateInbound(msg_4_4, deps_2_4, matchValue_7_1);
                patternInput_12 = [patternInput_11[0], patternInput_11[1]];
            }
            return [new Internal_BranchModelChildAndSibling$3(patternInput_8_1[0], patternInput_12[0], patternInput_10_1[0]), Cmd_batch_1(ofArray([Cmd_map((arg0_18) => (new Internal_BranchMsgChildAndSibling$3(0, arg0_18)), patternInput_8_1[1]), Cmd_map((arg0_19) => (new Internal_BranchMsgChildAndSibling$3(2, arg0_19)), patternInput_10_1[1]), Cmd_map((arg0_20) => (new Internal_BranchMsgChildAndSibling$3(1, arg0_20)), patternInput_12[1])]))];
        }, current_13.LastKey, Internal_combineSiblingIndexPathFromKeyPathMap(sibling_3.IndexPathFromKeyPath, Internal_combineChildIndexPathFromKeyPathMap(current_13.LastKey, child_2.IndexPathFromKeyPath, current_13.IndexPathFromKeyPath)));
    }))))), (current_14 = noteCreateState(currentUser), (f1_8 = ((current_1_3 = ((config_6 = defaultConfig(), (maybeAutoMessage_3 = config_6.MaybeSendMessageOnNavigation, (key_16 = "create", (current_18 = current_14, new Internal_StateTreeRoot$6((_arg2_4, deps_7, maybeCachedModel_5, _arg1_4) => {
        const matchValue_8 = [maybeCachedModel_5, config_6.ReInitOnNavigation, maybeAutoMessage_3];
        let pattern_matching_result_12, model_9, model_1_6, msg_19;
        if (matchValue_8[0] != null) {
            if (matchValue_8[1]) {
                pattern_matching_result_12 = 2;
            }
            else if (matchValue_8[2] != null) {
                pattern_matching_result_12 = 1;
                model_1_6 = matchValue_8[0];
                msg_19 = matchValue_8[2];
            }
            else {
                pattern_matching_result_12 = 0;
                model_9 = matchValue_8[0];
            }
        }
        else {
            pattern_matching_result_12 = 2;
        }
        switch (pattern_matching_result_12) {
            case 0: {
                return [model_9, Cmd_none_1()];
            }
            case 1: {
                return [model_1_6, Cmd_OfFunc_result(msg_19)];
            }
            case 2: {
                const patternInput_13 = current_18.Init(deps_7);
                return [new Internal_StateTreeModel$1(patternInput_13[0], false), Cmd_batch_1(toList(delay(() => append(singleton_1(patternInput_13[1]), delay(() => ofArray(toArray(map(Cmd_OfFunc_result, maybeAutoMessage_3))))))))];
            }
        }
    }, (_arg3_3, dispatch_5, model_2_6) => current_18.View(model_2_6.NodeModel, dispatch_5), (_arg4_3, msg_3_6, pass_5, deps_1_5, model_3_4) => {
        switch (msg_3_6.tag) {
            case 1: {
                console.error(some(`State tree error: ${msg_3_6.fields[0]}`));
                return [model_3_4, Cmd_none_1(), void 0, void 0];
            }
            case 2: {
                return [new Internal_StateTreeModel$1(model_3_4.NodeModel, true), Cmd_none_1(), void 0, void 0];
            }
            case 3: {
                return [model_3_4, Cmd_none_1(), void 0, msg_3_6.fields[0]];
            }
            case 4: {
                return [model_3_4, Cmd_none_1(), msg_3_6.fields[0], void 0];
            }
            default: {
                const patternInput_1_5 = current_18.Update(msg_3_6.fields[0], pass_5, deps_1_5, model_3_4.NodeModel);
                return [new Internal_StateTreeModel$1(patternInput_1_5[0], model_3_4.Initialized), patternInput_1_5[1], void 0, void 0];
            }
        }
    }, (msg_4_5, deps_2_5, model_4_3) => {
        const patternInput_2_5 = config_6.UpdateInbound(msg_4_5, deps_2_5, model_4_3.NodeModel);
        return [new Internal_StateTreeModel$1(patternInput_2_5[0], model_4_3.Initialized), patternInput_2_5[1]];
    }, key_16, ofList(singleton([singleton(key_16), singleton(0)])))))))), (sibling_4) => {
        const current_19 = current_1_3;
        const sibling_5 = sibling_4;
        return new Internal_StateTreeRoot$6((indexPath_4, deps_8, maybeCachedModel_6, keepCachedSiblings_2) => {
            const maybeCachedCurrentModel_2 = bind((cachedModel_3) => cachedModel_3.MaybeCurrentModel, maybeCachedModel_6);
            const maybeCachedSiblingModel_2 = bind((cachedModel_1_2) => cachedModel_1_2.MaybeSiblingModel, maybeCachedModel_6);
            let pattern_matching_result_13, x_5, xs_8;
            if (isEmpty(indexPath_4)) {
                pattern_matching_result_13 = 1;
            }
            else if (head(indexPath_4) === 0) {
                if (isEmpty(tail(indexPath_4))) {
                    pattern_matching_result_13 = 0;
                }
                else {
                    pattern_matching_result_13 = 1;
                }
            }
            else {
                pattern_matching_result_13 = 2;
                x_5 = head(indexPath_4);
                xs_8 = tail(indexPath_4);
            }
            switch (pattern_matching_result_13) {
                case 0: {
                    const patternInput_14 = current_19.Init(indexPath_4, deps_8, maybeCachedCurrentModel_2, false);
                    return [new Internal_BranchModelSibling$2(patternInput_14[0], keepCachedSiblings_2 ? maybeCachedSiblingModel_2 : (void 0)), Cmd_map((arg0_21) => (new Internal_BranchMsgSibling$2(0, arg0_21)), patternInput_14[1])];
                }
                case 1: {
                    console.error(some("Failed to initialize state, invalid index"));
                    return [defaultArg(maybeCachedModel_6, new Internal_BranchModelSibling$2(void 0, void 0)), Cmd_none_1()];
                }
                case 2: {
                    const patternInput_1_6 = sibling_5.Init(cons(x_5 - 1, xs_8), deps_8, maybeCachedSiblingModel_2, keepCachedSiblings_2);
                    return [new Internal_BranchModelSibling$2(keepCachedSiblings_2 ? maybeCachedCurrentModel_2 : (void 0), patternInput_1_6[0]), Cmd_map((arg0_1_3) => (new Internal_BranchMsgSibling$2(1, arg0_1_3)), patternInput_1_6[1])];
                }
            }
        }, (indexPath_1_2, dispatch_6, model_10) => (isEmpty(indexPath_1_2) ? "Woops, looks like you took a wrong turn..." : ((head(indexPath_1_2) === 0) ? (isEmpty(tail(indexPath_1_2)) ? defaultArg(map(partialApply(1, current_19.View, [indexPath_1_2, (arg_6) => {
            dispatch_6(new Internal_BranchMsgSibling$2(0, arg_6));
        }]), model_10.MaybeCurrentModel), "Loading...") : "Woops, looks like you took a wrong turn...") : defaultArg(map(partialApply(1, sibling_5.View, [cons(head(indexPath_1_2) - 1, tail(indexPath_1_2)), (arg_7) => {
            dispatch_6(new Internal_BranchMsgSibling$2(1, arg_7));
        }]), model_10.MaybeSiblingModel), "Loading..."))), (indexPath_2_2, branchMsg_2, pass_6, deps_1_6, model_1_7) => {
            const matchValue_9 = [branchMsg_2, indexPath_2_2];
            let pattern_matching_result_14, msg_1_7, msg_2_7, x_3_2, xs_3_2;
            if (matchValue_9[0].tag === 1) {
                if (!isEmpty(matchValue_9[1])) {
                    if (head(matchValue_9[1]) > 0) {
                        pattern_matching_result_14 = 1;
                        msg_2_7 = matchValue_9[0].fields[0];
                        x_3_2 = head(matchValue_9[1]);
                        xs_3_2 = tail(matchValue_9[1]);
                    }
                    else {
                        pattern_matching_result_14 = 2;
                    }
                }
                else {
                    pattern_matching_result_14 = 2;
                }
            }
            else if (!isEmpty(matchValue_9[1])) {
                if (head(matchValue_9[1]) === 0) {
                    pattern_matching_result_14 = 0;
                    msg_1_7 = matchValue_9[0].fields[0];
                }
                else {
                    pattern_matching_result_14 = 2;
                }
            }
            else {
                pattern_matching_result_14 = 2;
            }
            switch (pattern_matching_result_14) {
                case 0: {
                    const matchValue_1_3 = model_1_7.MaybeCurrentModel;
                    if (matchValue_1_3 == null) {
                        console.error(some("Failed to update state, not initialized"));
                        return [model_1_7, Cmd_none_1(), void 0, void 0];
                    }
                    else {
                        const patternInput_2_6 = current_19.Update(indexPath_2_2, msg_1_7, pass_6, deps_1_6, matchValue_1_3);
                        return [new Internal_BranchModelSibling$2(patternInput_2_6[0], model_1_7.MaybeSiblingModel), Cmd_map((arg0_5_2) => (new Internal_BranchMsgSibling$2(0, arg0_5_2)), patternInput_2_6[1]), patternInput_2_6[2], patternInput_2_6[3]];
                    }
                }
                case 1: {
                    const matchValue_2_2 = model_1_7.MaybeSiblingModel;
                    if (matchValue_2_2 == null) {
                        console.error(some("Failed to update state, not initialized"));
                        return [model_1_7, Cmd_none_1(), void 0, void 0];
                    }
                    else {
                        const patternInput_3_3 = sibling_5.Update(cons(x_3_2 - 1, xs_3_2), msg_2_7, pass_6, deps_1_6, matchValue_2_2);
                        return [new Internal_BranchModelSibling$2(model_1_7.MaybeCurrentModel, patternInput_3_3[0]), Cmd_map((arg0_7_2) => (new Internal_BranchMsgSibling$2(1, arg0_7_2)), patternInput_3_3[1]), patternInput_3_3[2], patternInput_3_3[3]];
                    }
                }
                case 2: {
                    console.warn(some("Got an update for a node that shouldn\u0027t be initialized, ignoring"));
                    return [model_1_7, Cmd_none_1(), void 0, void 0];
                }
            }
        }, (msg_3_7, deps_2_6, model_2_7) => {
            let patternInput_5_2;
            const matchValue_3_2 = model_2_7.MaybeCurrentModel;
            if (matchValue_3_2 == null) {
                patternInput_5_2 = [model_2_7.MaybeCurrentModel, Cmd_none_1()];
            }
            else {
                const patternInput_4_2 = current_19.UpdateInbound(msg_3_7, deps_2_6, matchValue_3_2);
                patternInput_5_2 = [patternInput_4_2[0], patternInput_4_2[1]];
            }
            let patternInput_7_2;
            const matchValue_4_2 = model_2_7.MaybeSiblingModel;
            if (matchValue_4_2 == null) {
                patternInput_7_2 = [model_2_7.MaybeSiblingModel, Cmd_none_1()];
            }
            else {
                const patternInput_6_2 = sibling_5.UpdateInbound(msg_3_7, deps_2_6, matchValue_4_2);
                patternInput_7_2 = [patternInput_6_2[0], patternInput_6_2[1]];
            }
            return [new Internal_BranchModelSibling$2(patternInput_5_2[0], patternInput_7_2[0]), Cmd_batch_1(ofArray([Cmd_map((arg0_8_2) => (new Internal_BranchMsgSibling$2(0, arg0_8_2)), patternInput_5_2[1]), Cmd_map((arg0_9_2) => (new Internal_BranchMsgSibling$2(1, arg0_9_2)), patternInput_7_2[1])]))];
        }, current_19.LastKey, Internal_combineSiblingIndexPathFromKeyPathMap(sibling_5.IndexPathFromKeyPath, current_19.IndexPathFromKeyPath));
    })), (arg_8) => siblingBuilder(f1_8(arg_8)))))), (current_20 = sessionDetailState(currentUser), (child_3 = ((current_21 = sessionEditState(currentUser), (config_7 = defaultConfig(), (maybeAutoMessage_4 = config_7.MaybeSendMessageOnNavigation, (key_20 = "edit", (current_23 = current_21, new Internal_StateTreeRoot$6((_arg2_5, deps_9, maybeCachedModel_7, _arg1_5) => {
        const matchValue_10 = [maybeCachedModel_7, config_7.ReInitOnNavigation, maybeAutoMessage_4];
        let pattern_matching_result_15, model_11, model_1_8, msg_23;
        if (matchValue_10[0] != null) {
            if (matchValue_10[1]) {
                pattern_matching_result_15 = 2;
            }
            else if (matchValue_10[2] != null) {
                pattern_matching_result_15 = 1;
                model_1_8 = matchValue_10[0];
                msg_23 = matchValue_10[2];
            }
            else {
                pattern_matching_result_15 = 0;
                model_11 = matchValue_10[0];
            }
        }
        else {
            pattern_matching_result_15 = 2;
        }
        switch (pattern_matching_result_15) {
            case 0: {
                return [model_11, Cmd_none_1()];
            }
            case 1: {
                return [model_1_8, Cmd_OfFunc_result(msg_23)];
            }
            case 2: {
                const patternInput_15 = current_23.Init(deps_9);
                return [new Internal_StateTreeModel$1(patternInput_15[0], false), Cmd_batch_1(toList(delay(() => append(singleton_1(patternInput_15[1]), delay(() => ofArray(toArray(map(Cmd_OfFunc_result, maybeAutoMessage_4))))))))];
            }
        }
    }, (_arg3_4, dispatch_7, model_2_8) => current_23.View(model_2_8.NodeModel, dispatch_7), (_arg4_4, msg_3_8, pass_7, deps_1_7, model_3_5) => {
        switch (msg_3_8.tag) {
            case 1: {
                console.error(some(`State tree error: ${msg_3_8.fields[0]}`));
                return [model_3_5, Cmd_none_1(), void 0, void 0];
            }
            case 2: {
                return [new Internal_StateTreeModel$1(model_3_5.NodeModel, true), Cmd_none_1(), void 0, void 0];
            }
            case 3: {
                return [model_3_5, Cmd_none_1(), void 0, msg_3_8.fields[0]];
            }
            case 4: {
                return [model_3_5, Cmd_none_1(), msg_3_8.fields[0], void 0];
            }
            default: {
                const patternInput_1_7 = current_23.Update(msg_3_8.fields[0], pass_7, deps_1_7, model_3_5.NodeModel);
                return [new Internal_StateTreeModel$1(patternInput_1_7[0], model_3_5.Initialized), patternInput_1_7[1], void 0, void 0];
            }
        }
    }, (msg_4_6, deps_2_7, model_4_4) => {
        const patternInput_2_7 = config_7.UpdateInbound(msg_4_6, deps_2_7, model_4_4.NodeModel);
        return [new Internal_StateTreeModel$1(patternInput_2_7[0], model_4_4.Initialized), patternInput_2_7[1]];
    }, key_20, ofList(singleton([singleton(key_20), singleton(0)]))))))))), (config_8 = defaultConfig(), (f1_13 = ((current_1_4 = ((config_9 = config_8, (maybeAutoMessage_5 = config_9.MaybeSendMessageOnNavigation, (key_24 = "Session Details Modal", (current_27 = current_20, new Internal_StateTreeRoot$6((_arg2_6, deps_10, maybeCachedModel_8, _arg1_6) => {
        const matchValue_11 = [maybeCachedModel_8, config_9.ReInitOnNavigation, maybeAutoMessage_5];
        let pattern_matching_result_16, model_12, model_1_9, msg_26;
        if (matchValue_11[0] != null) {
            if (matchValue_11[1]) {
                pattern_matching_result_16 = 2;
            }
            else if (matchValue_11[2] != null) {
                pattern_matching_result_16 = 1;
                model_1_9 = matchValue_11[0];
                msg_26 = matchValue_11[2];
            }
            else {
                pattern_matching_result_16 = 0;
                model_12 = matchValue_11[0];
            }
        }
        else {
            pattern_matching_result_16 = 2;
        }
        switch (pattern_matching_result_16) {
            case 0: {
                return [model_12, Cmd_none_1()];
            }
            case 1: {
                return [model_1_9, Cmd_OfFunc_result(msg_26)];
            }
            case 2: {
                const patternInput_16 = current_27.Init(deps_10);
                return [new Internal_StateTreeModel$1(patternInput_16[0], false), Cmd_batch_1(toList(delay(() => append(singleton_1(patternInput_16[1]), delay(() => ofArray(toArray(map(Cmd_OfFunc_result, maybeAutoMessage_5))))))))];
            }
        }
    }, (_arg3_5, dispatch_8, model_2_9) => current_27.View(model_2_9.NodeModel, dispatch_8), (_arg4_5, msg_3_9, pass_8, deps_1_8, model_3_6) => {
        switch (msg_3_9.tag) {
            case 1: {
                console.error(some(`State tree error: ${msg_3_9.fields[0]}`));
                return [model_3_6, Cmd_none_1(), void 0, void 0];
            }
            case 2: {
                return [new Internal_StateTreeModel$1(model_3_6.NodeModel, true), Cmd_none_1(), void 0, void 0];
            }
            case 3: {
                return [model_3_6, Cmd_none_1(), void 0, msg_3_9.fields[0]];
            }
            case 4: {
                return [model_3_6, Cmd_none_1(), msg_3_9.fields[0], void 0];
            }
            default: {
                const patternInput_1_8 = current_27.Update(msg_3_9.fields[0], pass_8, deps_1_8, model_3_6.NodeModel);
                return [new Internal_StateTreeModel$1(patternInput_1_8[0], model_3_6.Initialized), patternInput_1_8[1], void 0, void 0];
            }
        }
    }, (msg_4_7, deps_2_8, model_4_5) => {
        const patternInput_2_8 = config_9.UpdateInbound(msg_4_7, deps_2_8, model_4_5.NodeModel);
        return [new Internal_StateTreeModel$1(patternInput_2_8[0], model_4_5.Initialized), patternInput_2_8[1]];
    }, key_24, ofList(singleton([singleton(key_24), singleton(0)])))))))), (sibling_6) => {
        const keepCachedChildren_3 = config_8.KeepCachedChildren;
        const child_6 = child_3;
        const current_28 = current_1_4;
        const sibling_7 = sibling_6;
        return new Internal_StateTreeRoot$6((indexPath_5, deps_11, maybeCachedModel_9, keepCachedSiblings_3) => {
            const maybeCachedCurrentModel_3 = bind((cachedModel_4) => cachedModel_4.MaybeCurrentModel, maybeCachedModel_9);
            const maybeCachedSiblingModel_3 = bind((cachedModel_1_3) => cachedModel_1_3.MaybeSiblingModel, maybeCachedModel_9);
            const maybeCachedChildModel_1 = bind((cachedModel_2_2) => cachedModel_2_2.MaybeChildModel, maybeCachedModel_9);
            const matchValue_12 = [indexPath_5, maybeCachedCurrentModel_3];
            let pattern_matching_result_17, currentModel_1_3, xs_9, x_6, xs_1_3;
            if (isEmpty(matchValue_12[0])) {
                pattern_matching_result_17 = 3;
            }
            else if (head(matchValue_12[0]) === 0) {
                if (isEmpty(tail(matchValue_12[0]))) {
                    pattern_matching_result_17 = 0;
                }
                else if (matchValue_12[1] != null) {
                    pattern_matching_result_17 = 1;
                    currentModel_1_3 = matchValue_12[1];
                    xs_9 = tail(matchValue_12[0]);
                }
                else {
                    pattern_matching_result_17 = 0;
                }
            }
            else {
                pattern_matching_result_17 = 2;
                x_6 = head(matchValue_12[0]);
                xs_1_3 = tail(matchValue_12[0]);
            }
            switch (pattern_matching_result_17) {
                case 0: {
                    const patternInput_17 = current_28.Init(indexPath_5, deps_11, maybeCachedCurrentModel_3, false);
                    return [new Internal_BranchModelChildAndSibling$3(patternInput_17[0], keepCachedSiblings_3 ? maybeCachedSiblingModel_3 : (void 0), keepCachedChildren_3 ? maybeCachedChildModel_1 : (void 0)), Cmd_map((arg0_22) => (new Internal_BranchMsgChildAndSibling$3(0, arg0_22)), patternInput_17[1])];
                }
                case 1: {
                    const patternInput_1_9 = child_6.Init(xs_9, currentModel_1_3.NodeModel, maybeCachedChildModel_1, keepCachedChildren_3);
                    return [new Internal_BranchModelChildAndSibling$3(currentModel_1_3, keepCachedSiblings_3 ? maybeCachedSiblingModel_3 : (void 0), patternInput_1_9[0]), Cmd_map((arg0_2_4) => (new Internal_BranchMsgChildAndSibling$3(2, arg0_2_4)), patternInput_1_9[1])];
                }
                case 2: {
                    const patternInput_2_9 = sibling_7.Init(cons(x_6 - 1, xs_1_3), deps_11, maybeCachedSiblingModel_3, keepCachedSiblings_3);
                    return [new Internal_BranchModelChildAndSibling$3(keepCachedSiblings_3 ? maybeCachedCurrentModel_3 : (void 0), patternInput_2_9[0], keepCachedChildren_3 ? maybeCachedChildModel_1 : (void 0)), Cmd_map((arg0_3_4) => (new Internal_BranchMsgChildAndSibling$3(1, arg0_3_4)), patternInput_2_9[1])];
                }
                case 3: {
                    console.error(some("Failed to initialize state, invalid index"));
                    return [defaultArg(maybeCachedModel_9, new Internal_BranchModelChildAndSibling$3(void 0, void 0, void 0)), Cmd_none_1()];
                }
            }
        }, (indexPath_1_3, dispatch_9, model_13) => {
            if (isEmpty(indexPath_1_3)) {
                return "Woops, looks like you took a wrong turn...";
            }
            else if (head(indexPath_1_3) === 0) {
                if (isEmpty(tail(indexPath_1_3))) {
                    return defaultArg(map(partialApply(1, current_28.View, [indexPath_1_3, (arg_9) => {
                        dispatch_9(new Internal_BranchMsgChildAndSibling$3(0, arg_9));
                    }]), model_13.MaybeCurrentModel), "Loading...");
                }
                else {
                    const children_2 = toList(delay(() => append(config_8.VisibleToChildren ? singleton_1(defaultArg(map(partialApply(1, current_28.View, [indexPath_1_3, (arg_10) => {
                        dispatch_9(new Internal_BranchMsgChildAndSibling$3(0, arg_10));
                    }]), model_13.MaybeCurrentModel), "Loading...")) : empty_1(), delay(() => singleton_1(defaultArg(map(partialApply(1, child_6.View, [tail(indexPath_1_3), (arg_11) => {
                        dispatch_9(new Internal_BranchMsgChildAndSibling$3(2, arg_11));
                    }]), model_13.MaybeChildModel), "Loading..."))))));
                    return react.createElement("div", {}, ...children_2);
                }
            }
            else {
                return defaultArg(map(partialApply(1, sibling_7.View, [cons(head(indexPath_1_3) - 1, tail(indexPath_1_3)), (arg_12) => {
                    dispatch_9(new Internal_BranchMsgChildAndSibling$3(1, arg_12));
                }]), model_13.MaybeSiblingModel), "Loading...");
            }
        }, (indexPath_2_3, branchMsg_3, pass_9, deps_1_9, model_1_10) => {
            let newNodeModel_1;
            const matchValue_1_4 = [branchMsg_3, indexPath_2_3];
            let pattern_matching_result_18, msg_1_10, xs_5_1, msg_2_10, xs_6_1, msg_3_10, x_3_3, xs_7_1;
            if (matchValue_1_4[0].tag === 2) {
                if (!isEmpty(matchValue_1_4[1])) {
                    if (head(matchValue_1_4[1]) === 0) {
                        pattern_matching_result_18 = 1;
                        msg_2_10 = matchValue_1_4[0].fields[0];
                        xs_6_1 = tail(matchValue_1_4[1]);
                    }
                    else {
                        pattern_matching_result_18 = 3;
                    }
                }
                else {
                    pattern_matching_result_18 = 3;
                }
            }
            else if (matchValue_1_4[0].tag === 1) {
                if (!isEmpty(matchValue_1_4[1])) {
                    if (head(matchValue_1_4[1]) > 0) {
                        pattern_matching_result_18 = 2;
                        msg_3_10 = matchValue_1_4[0].fields[0];
                        x_3_3 = head(matchValue_1_4[1]);
                        xs_7_1 = tail(matchValue_1_4[1]);
                    }
                    else {
                        pattern_matching_result_18 = 3;
                    }
                }
                else {
                    pattern_matching_result_18 = 3;
                }
            }
            else if (!isEmpty(matchValue_1_4[1])) {
                if (head(matchValue_1_4[1]) === 0) {
                    pattern_matching_result_18 = 0;
                    msg_1_10 = matchValue_1_4[0].fields[0];
                    xs_5_1 = tail(matchValue_1_4[1]);
                }
                else {
                    pattern_matching_result_18 = 3;
                }
            }
            else {
                pattern_matching_result_18 = 3;
            }
            switch (pattern_matching_result_18) {
                case 0: {
                    const matchValue_2_3 = model_1_10.MaybeCurrentModel;
                    if (matchValue_2_3 == null) {
                        console.error(some("Failed to update state, not initialized"));
                        return [model_1_10, Cmd_none_1(), void 0, void 0];
                    }
                    else {
                        const currentModel_2_3 = matchValue_2_3;
                        const patternInput_3_4 = current_28.Update(indexPath_2_3, msg_1_10, pass_9, deps_1_9, currentModel_2_3);
                        const newCurrentModel_4 = patternInput_3_4[0];
                        const maybeOutboundMsg_4 = patternInput_3_4[3];
                        const maybeNavMsg_4 = patternInput_3_4[2];
                        const currentCmd_1_3 = patternInput_3_4[1];
                        let pattern_matching_result_19;
                        if (msg_1_10.tag === 2) {
                            if (!equals(xs_5_1, empty())) {
                                pattern_matching_result_19 = 0;
                            }
                            else {
                                pattern_matching_result_19 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_19 = 1;
                        }
                        switch (pattern_matching_result_19) {
                            case 0: {
                                const patternInput_4_3 = child_6.Init(xs_5_1, currentModel_2_3.NodeModel, model_1_10.MaybeChildModel, false);
                                return [new Internal_BranchModelChildAndSibling$3(newCurrentModel_4, model_1_10.MaybeSiblingModel, patternInput_4_3[0]), Cmd_batch_1(ofArray([Cmd_map((arg0_10_2) => (new Internal_BranchMsgChildAndSibling$3(0, arg0_10_2)), currentCmd_1_3), Cmd_map((arg0_11_1) => (new Internal_BranchMsgChildAndSibling$3(2, arg0_11_1)), patternInput_4_3[1])])), maybeNavMsg_4, maybeOutboundMsg_4];
                            }
                            case 1: {
                                return [new Internal_BranchModelChildAndSibling$3(newCurrentModel_4, model_1_10.MaybeSiblingModel, model_1_10.MaybeChildModel), Cmd_map((arg0_13_1) => (new Internal_BranchMsgChildAndSibling$3(0, arg0_13_1)), currentCmd_1_3), maybeNavMsg_4, maybeOutboundMsg_4];
                            }
                        }
                    }
                }
                case 1: {
                    const matchValue_3_3 = [model_1_10.MaybeCurrentModel, model_1_10.MaybeChildModel];
                    let pattern_matching_result_20, childModel_1_1, currentModel_3_2;
                    if (matchValue_3_3[0] != null) {
                        if (matchValue_3_3[1] != null) {
                            pattern_matching_result_20 = 0;
                            childModel_1_1 = matchValue_3_3[1];
                            currentModel_3_2 = matchValue_3_3[0];
                        }
                        else {
                            pattern_matching_result_20 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_20 = 1;
                    }
                    switch (pattern_matching_result_20) {
                        case 0: {
                            const patternInput_5_3 = child_6.Update(xs_6_1, msg_2_10, pass_9, currentModel_3_2.NodeModel, childModel_1_1);
                            const maybeNavMsg_1_3 = patternInput_5_3[2];
                            return [new Internal_BranchModelChildAndSibling$3((maybeNavMsg_1_3 != null) ? ((maybeNavMsg_1_3.tag === 2) ? ((newNodeModel_1 = maybeNavMsg_1_3.fields[0], map((model_2_10) => (new Internal_StateTreeModel$1(newNodeModel_1, model_2_10.Initialized)), model_1_10.MaybeCurrentModel))) : model_1_10.MaybeCurrentModel) : model_1_10.MaybeCurrentModel, model_1_10.MaybeSiblingModel, patternInput_5_3[0]), Cmd_map((arg0_15_1) => (new Internal_BranchMsgChildAndSibling$3(2, arg0_15_1)), patternInput_5_3[1]), map(NavigationMsg$1_destructiveCast_Z33BEA92B, maybeNavMsg_1_3), patternInput_5_3[3]];
                        }
                        case 1: {
                            console.error(some("Failed to update state, parent not initialized"));
                            return [model_1_10, Cmd_none_1(), void 0, void 0];
                        }
                    }
                }
                case 2: {
                    const matchValue_4_3 = model_1_10.MaybeSiblingModel;
                    if (matchValue_4_3 == null) {
                        console.error(some("Failed to update state, not initialized"));
                        return [model_1_10, Cmd_none_1(), void 0, void 0];
                    }
                    else {
                        const patternInput_6_3 = sibling_7.Update(cons(x_3_3 - 1, xs_7_1), msg_3_10, pass_9, deps_1_9, matchValue_4_3);
                        return [new Internal_BranchModelChildAndSibling$3(model_1_10.MaybeCurrentModel, patternInput_6_3[0], model_1_10.MaybeChildModel), Cmd_map((arg0_17_1) => (new Internal_BranchMsgChildAndSibling$3(1, arg0_17_1)), patternInput_6_3[1]), patternInput_6_3[2], patternInput_6_3[3]];
                    }
                }
                case 3: {
                    console.warn(some("Got an update for a node that shouldn\u0027t be initialized, ignoring"));
                    return [model_1_10, Cmd_none_1(), void 0, void 0];
                }
            }
        }, (msg_4_8, deps_2_9, model_3_7) => {
            let patternInput_8_2;
            const matchValue_5_2 = model_3_7.MaybeCurrentModel;
            if (matchValue_5_2 == null) {
                patternInput_8_2 = [model_3_7.MaybeCurrentModel, Cmd_none_1()];
            }
            else {
                const patternInput_7_3 = current_28.UpdateInbound(msg_4_8, deps_2_9, matchValue_5_2);
                patternInput_8_2 = [patternInput_7_3[0], patternInput_7_3[1]];
            }
            let patternInput_10_2;
            const matchValue_6_2 = [model_3_7.MaybeChildModel, model_3_7.MaybeCurrentModel];
            let pattern_matching_result_21, childModel_2_1, currentModel_5_1;
            if (matchValue_6_2[0] != null) {
                if (matchValue_6_2[1] != null) {
                    pattern_matching_result_21 = 0;
                    childModel_2_1 = matchValue_6_2[0];
                    currentModel_5_1 = matchValue_6_2[1];
                }
                else {
                    pattern_matching_result_21 = 1;
                }
            }
            else {
                pattern_matching_result_21 = 1;
            }
            switch (pattern_matching_result_21) {
                case 0: {
                    const patternInput_9_2 = child_6.UpdateInbound(msg_4_8, currentModel_5_1.NodeModel, childModel_2_1);
                    patternInput_10_2 = [patternInput_9_2[0], patternInput_9_2[1]];
                    break;
                }
                case 1: {
                    patternInput_10_2 = [model_3_7.MaybeChildModel, Cmd_none_1()];
                    break;
                }
            }
            let patternInput_12_1;
            const matchValue_7_2 = model_3_7.MaybeSiblingModel;
            if (matchValue_7_2 == null) {
                patternInput_12_1 = [model_3_7.MaybeSiblingModel, Cmd_none_1()];
            }
            else {
                const patternInput_11_1 = sibling_7.UpdateInbound(msg_4_8, deps_2_9, matchValue_7_2);
                patternInput_12_1 = [patternInput_11_1[0], patternInput_11_1[1]];
            }
            return [new Internal_BranchModelChildAndSibling$3(patternInput_8_2[0], patternInput_12_1[0], patternInput_10_2[0]), Cmd_batch_1(ofArray([Cmd_map((arg0_18_1) => (new Internal_BranchMsgChildAndSibling$3(0, arg0_18_1)), patternInput_8_2[1]), Cmd_map((arg0_19_1) => (new Internal_BranchMsgChildAndSibling$3(2, arg0_19_1)), patternInput_10_2[1]), Cmd_map((arg0_20_1) => (new Internal_BranchMsgChildAndSibling$3(1, arg0_20_1)), patternInput_12_1[1])]))];
        }, current_28.LastKey, Internal_combineSiblingIndexPathFromKeyPathMap(sibling_7.IndexPathFromKeyPath, Internal_combineChildIndexPathFromKeyPathMap(current_28.LastKey, child_6.IndexPathFromKeyPath, current_28.IndexPathFromKeyPath)));
    })), (arg_13) => siblingBuilder_3(f1_13(arg_13)))))))), (current_29 = sessionCreateState(), (f1_16 = ((current_1_5 = ((config_11 = defaultConfig(), (maybeAutoMessage_6 = config_11.MaybeSendMessageOnNavigation, (key_29 = "Create Session Modal", (current_33 = current_29, new Internal_StateTreeRoot$6((_arg2_7, deps_12, maybeCachedModel_10, _arg1_7) => {
        const matchValue_13 = [maybeCachedModel_10, config_11.ReInitOnNavigation, maybeAutoMessage_6];
        let pattern_matching_result_22, model_14, model_1_11, msg_30;
        if (matchValue_13[0] != null) {
            if (matchValue_13[1]) {
                pattern_matching_result_22 = 2;
            }
            else if (matchValue_13[2] != null) {
                pattern_matching_result_22 = 1;
                model_1_11 = matchValue_13[0];
                msg_30 = matchValue_13[2];
            }
            else {
                pattern_matching_result_22 = 0;
                model_14 = matchValue_13[0];
            }
        }
        else {
            pattern_matching_result_22 = 2;
        }
        switch (pattern_matching_result_22) {
            case 0: {
                return [model_14, Cmd_none_1()];
            }
            case 1: {
                return [model_1_11, Cmd_OfFunc_result(msg_30)];
            }
            case 2: {
                const patternInput_18 = current_33.Init(deps_12);
                return [new Internal_StateTreeModel$1(patternInput_18[0], false), Cmd_batch_1(toList(delay(() => append(singleton_1(patternInput_18[1]), delay(() => ofArray(toArray(map(Cmd_OfFunc_result, maybeAutoMessage_6))))))))];
            }
        }
    }, (_arg3_6, dispatch_10, model_2_11) => current_33.View(model_2_11.NodeModel, dispatch_10), (_arg4_6, msg_3_11, pass_10, deps_1_10, model_3_8) => {
        switch (msg_3_11.tag) {
            case 1: {
                console.error(some(`State tree error: ${msg_3_11.fields[0]}`));
                return [model_3_8, Cmd_none_1(), void 0, void 0];
            }
            case 2: {
                return [new Internal_StateTreeModel$1(model_3_8.NodeModel, true), Cmd_none_1(), void 0, void 0];
            }
            case 3: {
                return [model_3_8, Cmd_none_1(), void 0, msg_3_11.fields[0]];
            }
            case 4: {
                return [model_3_8, Cmd_none_1(), msg_3_11.fields[0], void 0];
            }
            default: {
                const patternInput_1_10 = current_33.Update(msg_3_11.fields[0], pass_10, deps_1_10, model_3_8.NodeModel);
                return [new Internal_StateTreeModel$1(patternInput_1_10[0], model_3_8.Initialized), patternInput_1_10[1], void 0, void 0];
            }
        }
    }, (msg_4_9, deps_2_10, model_4_6) => {
        const patternInput_2_10 = config_11.UpdateInbound(msg_4_9, deps_2_10, model_4_6.NodeModel);
        return [new Internal_StateTreeModel$1(patternInput_2_10[0], model_4_6.Initialized), patternInput_2_10[1]];
    }, key_29, ofList(singleton([singleton(key_29), singleton(0)])))))))), (sibling_8) => {
        const current_34 = current_1_5;
        const sibling_9 = sibling_8;
        return new Internal_StateTreeRoot$6((indexPath_6, deps_13, maybeCachedModel_11, keepCachedSiblings_4) => {
            const maybeCachedCurrentModel_4 = bind((cachedModel_5) => cachedModel_5.MaybeCurrentModel, maybeCachedModel_11);
            const maybeCachedSiblingModel_4 = bind((cachedModel_1_4) => cachedModel_1_4.MaybeSiblingModel, maybeCachedModel_11);
            let pattern_matching_result_23, x_7, xs_10;
            if (isEmpty(indexPath_6)) {
                pattern_matching_result_23 = 1;
            }
            else if (head(indexPath_6) === 0) {
                if (isEmpty(tail(indexPath_6))) {
                    pattern_matching_result_23 = 0;
                }
                else {
                    pattern_matching_result_23 = 1;
                }
            }
            else {
                pattern_matching_result_23 = 2;
                x_7 = head(indexPath_6);
                xs_10 = tail(indexPath_6);
            }
            switch (pattern_matching_result_23) {
                case 0: {
                    const patternInput_19 = current_34.Init(indexPath_6, deps_13, maybeCachedCurrentModel_4, false);
                    return [new Internal_BranchModelSibling$2(patternInput_19[0], keepCachedSiblings_4 ? maybeCachedSiblingModel_4 : (void 0)), Cmd_map((arg0_23) => (new Internal_BranchMsgSibling$2(0, arg0_23)), patternInput_19[1])];
                }
                case 1: {
                    console.error(some("Failed to initialize state, invalid index"));
                    return [defaultArg(maybeCachedModel_11, new Internal_BranchModelSibling$2(void 0, void 0)), Cmd_none_1()];
                }
                case 2: {
                    const patternInput_1_11 = sibling_9.Init(cons(x_7 - 1, xs_10), deps_13, maybeCachedSiblingModel_4, keepCachedSiblings_4);
                    return [new Internal_BranchModelSibling$2(keepCachedSiblings_4 ? maybeCachedCurrentModel_4 : (void 0), patternInput_1_11[0]), Cmd_map((arg0_1_5) => (new Internal_BranchMsgSibling$2(1, arg0_1_5)), patternInput_1_11[1])];
                }
            }
        }, (indexPath_1_4, dispatch_11, model_15) => (isEmpty(indexPath_1_4) ? "Woops, looks like you took a wrong turn..." : ((head(indexPath_1_4) === 0) ? (isEmpty(tail(indexPath_1_4)) ? defaultArg(map(partialApply(1, current_34.View, [indexPath_1_4, (arg_14) => {
            dispatch_11(new Internal_BranchMsgSibling$2(0, arg_14));
        }]), model_15.MaybeCurrentModel), "Loading...") : "Woops, looks like you took a wrong turn...") : defaultArg(map(partialApply(1, sibling_9.View, [cons(head(indexPath_1_4) - 1, tail(indexPath_1_4)), (arg_15) => {
            dispatch_11(new Internal_BranchMsgSibling$2(1, arg_15));
        }]), model_15.MaybeSiblingModel), "Loading..."))), (indexPath_2_4, branchMsg_4, pass_11, deps_1_11, model_1_12) => {
            const matchValue_14 = [branchMsg_4, indexPath_2_4];
            let pattern_matching_result_24, msg_1_12, msg_2_12, x_3_4, xs_3_4;
            if (matchValue_14[0].tag === 1) {
                if (!isEmpty(matchValue_14[1])) {
                    if (head(matchValue_14[1]) > 0) {
                        pattern_matching_result_24 = 1;
                        msg_2_12 = matchValue_14[0].fields[0];
                        x_3_4 = head(matchValue_14[1]);
                        xs_3_4 = tail(matchValue_14[1]);
                    }
                    else {
                        pattern_matching_result_24 = 2;
                    }
                }
                else {
                    pattern_matching_result_24 = 2;
                }
            }
            else if (!isEmpty(matchValue_14[1])) {
                if (head(matchValue_14[1]) === 0) {
                    pattern_matching_result_24 = 0;
                    msg_1_12 = matchValue_14[0].fields[0];
                }
                else {
                    pattern_matching_result_24 = 2;
                }
            }
            else {
                pattern_matching_result_24 = 2;
            }
            switch (pattern_matching_result_24) {
                case 0: {
                    const matchValue_1_5 = model_1_12.MaybeCurrentModel;
                    if (matchValue_1_5 == null) {
                        console.error(some("Failed to update state, not initialized"));
                        return [model_1_12, Cmd_none_1(), void 0, void 0];
                    }
                    else {
                        const patternInput_2_11 = current_34.Update(indexPath_2_4, msg_1_12, pass_11, deps_1_11, matchValue_1_5);
                        return [new Internal_BranchModelSibling$2(patternInput_2_11[0], model_1_12.MaybeSiblingModel), Cmd_map((arg0_5_4) => (new Internal_BranchMsgSibling$2(0, arg0_5_4)), patternInput_2_11[1]), patternInput_2_11[2], patternInput_2_11[3]];
                    }
                }
                case 1: {
                    const matchValue_2_4 = model_1_12.MaybeSiblingModel;
                    if (matchValue_2_4 == null) {
                        console.error(some("Failed to update state, not initialized"));
                        return [model_1_12, Cmd_none_1(), void 0, void 0];
                    }
                    else {
                        const patternInput_3_5 = sibling_9.Update(cons(x_3_4 - 1, xs_3_4), msg_2_12, pass_11, deps_1_11, matchValue_2_4);
                        return [new Internal_BranchModelSibling$2(model_1_12.MaybeCurrentModel, patternInput_3_5[0]), Cmd_map((arg0_7_4) => (new Internal_BranchMsgSibling$2(1, arg0_7_4)), patternInput_3_5[1]), patternInput_3_5[2], patternInput_3_5[3]];
                    }
                }
                case 2: {
                    console.warn(some("Got an update for a node that shouldn\u0027t be initialized, ignoring"));
                    return [model_1_12, Cmd_none_1(), void 0, void 0];
                }
            }
        }, (msg_3_12, deps_2_11, model_2_12) => {
            let patternInput_5_4;
            const matchValue_3_4 = model_2_12.MaybeCurrentModel;
            if (matchValue_3_4 == null) {
                patternInput_5_4 = [model_2_12.MaybeCurrentModel, Cmd_none_1()];
            }
            else {
                const patternInput_4_4 = current_34.UpdateInbound(msg_3_12, deps_2_11, matchValue_3_4);
                patternInput_5_4 = [patternInput_4_4[0], patternInput_4_4[1]];
            }
            let patternInput_7_4;
            const matchValue_4_4 = model_2_12.MaybeSiblingModel;
            if (matchValue_4_4 == null) {
                patternInput_7_4 = [model_2_12.MaybeSiblingModel, Cmd_none_1()];
            }
            else {
                const patternInput_6_4 = sibling_9.UpdateInbound(msg_3_12, deps_2_11, matchValue_4_4);
                patternInput_7_4 = [patternInput_6_4[0], patternInput_6_4[1]];
            }
            return [new Internal_BranchModelSibling$2(patternInput_5_4[0], patternInput_7_4[0]), Cmd_batch_1(ofArray([Cmd_map((arg0_8_4) => (new Internal_BranchMsgSibling$2(0, arg0_8_4)), patternInput_5_4[1]), Cmd_map((arg0_9_4) => (new Internal_BranchMsgSibling$2(1, arg0_9_4)), patternInput_7_4[1])]))];
        }, current_34.LastKey, Internal_combineSiblingIndexPathFromKeyPathMap(sibling_9.IndexPathFromKeyPath, current_34.IndexPathFromKeyPath));
    })), (arg_16) => siblingBuilder_6(f1_16(arg_16)))))), (current_35 = guestLinkCreateState(), (child_7 = ((current_36 = guestLinkShowState(), (config_12 = defaultConfig(), (maybeAutoMessage_7 = config_12.MaybeSendMessageOnNavigation, (key_33 = "Show Guest Link Modal", (current_38 = current_36, new Internal_StateTreeRoot$6((_arg2_8, deps_14, maybeCachedModel_12, _arg1_8) => {
        const matchValue_15 = [maybeCachedModel_12, config_12.ReInitOnNavigation, maybeAutoMessage_7];
        let pattern_matching_result_25, model_16, model_1_13, msg_34;
        if (matchValue_15[0] != null) {
            if (matchValue_15[1]) {
                pattern_matching_result_25 = 2;
            }
            else if (matchValue_15[2] != null) {
                pattern_matching_result_25 = 1;
                model_1_13 = matchValue_15[0];
                msg_34 = matchValue_15[2];
            }
            else {
                pattern_matching_result_25 = 0;
                model_16 = matchValue_15[0];
            }
        }
        else {
            pattern_matching_result_25 = 2;
        }
        switch (pattern_matching_result_25) {
            case 0: {
                return [model_16, Cmd_none_1()];
            }
            case 1: {
                return [model_1_13, Cmd_OfFunc_result(msg_34)];
            }
            case 2: {
                const patternInput_20 = current_38.Init(deps_14);
                return [new Internal_StateTreeModel$1(patternInput_20[0], false), Cmd_batch_1(toList(delay(() => append(singleton_1(patternInput_20[1]), delay(() => ofArray(toArray(map(Cmd_OfFunc_result, maybeAutoMessage_7))))))))];
            }
        }
    }, (_arg3_7, dispatch_12, model_2_13) => current_38.View(model_2_13.NodeModel, dispatch_12), (_arg4_7, msg_3_13, pass_12, deps_1_12, model_3_9) => {
        switch (msg_3_13.tag) {
            case 1: {
                console.error(some(`State tree error: ${msg_3_13.fields[0]}`));
                return [model_3_9, Cmd_none_1(), void 0, void 0];
            }
            case 2: {
                return [new Internal_StateTreeModel$1(model_3_9.NodeModel, true), Cmd_none_1(), void 0, void 0];
            }
            case 3: {
                return [model_3_9, Cmd_none_1(), void 0, msg_3_13.fields[0]];
            }
            case 4: {
                return [model_3_9, Cmd_none_1(), msg_3_13.fields[0], void 0];
            }
            default: {
                const patternInput_1_12 = current_38.Update(msg_3_13.fields[0], pass_12, deps_1_12, model_3_9.NodeModel);
                return [new Internal_StateTreeModel$1(patternInput_1_12[0], model_3_9.Initialized), patternInput_1_12[1], void 0, void 0];
            }
        }
    }, (msg_4_10, deps_2_12, model_4_7) => {
        const patternInput_2_12 = config_12.UpdateInbound(msg_4_10, deps_2_12, model_4_7.NodeModel);
        return [new Internal_StateTreeModel$1(patternInput_2_12[0], model_4_7.Initialized), patternInput_2_12[1]];
    }, key_33, ofList(singleton([singleton(key_33), singleton(0)]))))))))), (config_13 = defaultConfig(), (f1_21 = ((current_1_6 = ((config_14 = config_13, (maybeAutoMessage_8 = config_14.MaybeSendMessageOnNavigation, (key_37 = "Create Guest Link Modal", (current_42 = current_35, new Internal_StateTreeRoot$6((_arg2_9, deps_15, maybeCachedModel_13, _arg1_9) => {
        const matchValue_16 = [maybeCachedModel_13, config_14.ReInitOnNavigation, maybeAutoMessage_8];
        let pattern_matching_result_26, model_17, model_1_14, msg_37;
        if (matchValue_16[0] != null) {
            if (matchValue_16[1]) {
                pattern_matching_result_26 = 2;
            }
            else if (matchValue_16[2] != null) {
                pattern_matching_result_26 = 1;
                model_1_14 = matchValue_16[0];
                msg_37 = matchValue_16[2];
            }
            else {
                pattern_matching_result_26 = 0;
                model_17 = matchValue_16[0];
            }
        }
        else {
            pattern_matching_result_26 = 2;
        }
        switch (pattern_matching_result_26) {
            case 0: {
                return [model_17, Cmd_none_1()];
            }
            case 1: {
                return [model_1_14, Cmd_OfFunc_result(msg_37)];
            }
            case 2: {
                const patternInput_21 = current_42.Init(deps_15);
                return [new Internal_StateTreeModel$1(patternInput_21[0], false), Cmd_batch_1(toList(delay(() => append(singleton_1(patternInput_21[1]), delay(() => ofArray(toArray(map(Cmd_OfFunc_result, maybeAutoMessage_8))))))))];
            }
        }
    }, (_arg3_8, dispatch_13, model_2_14) => current_42.View(model_2_14.NodeModel, dispatch_13), (_arg4_8, msg_3_14, pass_13, deps_1_13, model_3_10) => {
        switch (msg_3_14.tag) {
            case 1: {
                console.error(some(`State tree error: ${msg_3_14.fields[0]}`));
                return [model_3_10, Cmd_none_1(), void 0, void 0];
            }
            case 2: {
                return [new Internal_StateTreeModel$1(model_3_10.NodeModel, true), Cmd_none_1(), void 0, void 0];
            }
            case 3: {
                return [model_3_10, Cmd_none_1(), void 0, msg_3_14.fields[0]];
            }
            case 4: {
                return [model_3_10, Cmd_none_1(), msg_3_14.fields[0], void 0];
            }
            default: {
                const patternInput_1_13 = current_42.Update(msg_3_14.fields[0], pass_13, deps_1_13, model_3_10.NodeModel);
                return [new Internal_StateTreeModel$1(patternInput_1_13[0], model_3_10.Initialized), patternInput_1_13[1], void 0, void 0];
            }
        }
    }, (msg_4_11, deps_2_13, model_4_8) => {
        const patternInput_2_13 = config_14.UpdateInbound(msg_4_11, deps_2_13, model_4_8.NodeModel);
        return [new Internal_StateTreeModel$1(patternInput_2_13[0], model_4_8.Initialized), patternInput_2_13[1]];
    }, key_37, ofList(singleton([singleton(key_37), singleton(0)])))))))), (sibling_10) => {
        const keepCachedChildren_5 = config_13.KeepCachedChildren;
        const child_10 = child_7;
        const current_43 = current_1_6;
        const sibling_11 = sibling_10;
        return new Internal_StateTreeRoot$6((indexPath_7, deps_16, maybeCachedModel_14, keepCachedSiblings_5) => {
            const maybeCachedCurrentModel_5 = bind((cachedModel_6) => cachedModel_6.MaybeCurrentModel, maybeCachedModel_14);
            const maybeCachedSiblingModel_5 = bind((cachedModel_1_5) => cachedModel_1_5.MaybeSiblingModel, maybeCachedModel_14);
            const maybeCachedChildModel_2 = bind((cachedModel_2_3) => cachedModel_2_3.MaybeChildModel, maybeCachedModel_14);
            const matchValue_17 = [indexPath_7, maybeCachedCurrentModel_5];
            let pattern_matching_result_27, currentModel_1_5, xs_11, x_8, xs_1_5;
            if (isEmpty(matchValue_17[0])) {
                pattern_matching_result_27 = 3;
            }
            else if (head(matchValue_17[0]) === 0) {
                if (isEmpty(tail(matchValue_17[0]))) {
                    pattern_matching_result_27 = 0;
                }
                else if (matchValue_17[1] != null) {
                    pattern_matching_result_27 = 1;
                    currentModel_1_5 = matchValue_17[1];
                    xs_11 = tail(matchValue_17[0]);
                }
                else {
                    pattern_matching_result_27 = 0;
                }
            }
            else {
                pattern_matching_result_27 = 2;
                x_8 = head(matchValue_17[0]);
                xs_1_5 = tail(matchValue_17[0]);
            }
            switch (pattern_matching_result_27) {
                case 0: {
                    const patternInput_22 = current_43.Init(indexPath_7, deps_16, maybeCachedCurrentModel_5, false);
                    return [new Internal_BranchModelChildAndSibling$3(patternInput_22[0], keepCachedSiblings_5 ? maybeCachedSiblingModel_5 : (void 0), keepCachedChildren_5 ? maybeCachedChildModel_2 : (void 0)), Cmd_map((arg0_24) => (new Internal_BranchMsgChildAndSibling$3(0, arg0_24)), patternInput_22[1])];
                }
                case 1: {
                    const patternInput_1_14 = child_10.Init(xs_11, currentModel_1_5.NodeModel, maybeCachedChildModel_2, keepCachedChildren_5);
                    return [new Internal_BranchModelChildAndSibling$3(currentModel_1_5, keepCachedSiblings_5 ? maybeCachedSiblingModel_5 : (void 0), patternInput_1_14[0]), Cmd_map((arg0_2_6) => (new Internal_BranchMsgChildAndSibling$3(2, arg0_2_6)), patternInput_1_14[1])];
                }
                case 2: {
                    const patternInput_2_14 = sibling_11.Init(cons(x_8 - 1, xs_1_5), deps_16, maybeCachedSiblingModel_5, keepCachedSiblings_5);
                    return [new Internal_BranchModelChildAndSibling$3(keepCachedSiblings_5 ? maybeCachedCurrentModel_5 : (void 0), patternInput_2_14[0], keepCachedChildren_5 ? maybeCachedChildModel_2 : (void 0)), Cmd_map((arg0_3_6) => (new Internal_BranchMsgChildAndSibling$3(1, arg0_3_6)), patternInput_2_14[1])];
                }
                case 3: {
                    console.error(some("Failed to initialize state, invalid index"));
                    return [defaultArg(maybeCachedModel_14, new Internal_BranchModelChildAndSibling$3(void 0, void 0, void 0)), Cmd_none_1()];
                }
            }
        }, (indexPath_1_5, dispatch_14, model_18) => {
            if (isEmpty(indexPath_1_5)) {
                return "Woops, looks like you took a wrong turn...";
            }
            else if (head(indexPath_1_5) === 0) {
                if (isEmpty(tail(indexPath_1_5))) {
                    return defaultArg(map(partialApply(1, current_43.View, [indexPath_1_5, (arg_17) => {
                        dispatch_14(new Internal_BranchMsgChildAndSibling$3(0, arg_17));
                    }]), model_18.MaybeCurrentModel), "Loading...");
                }
                else {
                    const children_4 = toList(delay(() => append(config_13.VisibleToChildren ? singleton_1(defaultArg(map(partialApply(1, current_43.View, [indexPath_1_5, (arg_18) => {
                        dispatch_14(new Internal_BranchMsgChildAndSibling$3(0, arg_18));
                    }]), model_18.MaybeCurrentModel), "Loading...")) : empty_1(), delay(() => singleton_1(defaultArg(map(partialApply(1, child_10.View, [tail(indexPath_1_5), (arg_19) => {
                        dispatch_14(new Internal_BranchMsgChildAndSibling$3(2, arg_19));
                    }]), model_18.MaybeChildModel), "Loading..."))))));
                    return react.createElement("div", {}, ...children_4);
                }
            }
            else {
                return defaultArg(map(partialApply(1, sibling_11.View, [cons(head(indexPath_1_5) - 1, tail(indexPath_1_5)), (arg_20) => {
                    dispatch_14(new Internal_BranchMsgChildAndSibling$3(1, arg_20));
                }]), model_18.MaybeSiblingModel), "Loading...");
            }
        }, (indexPath_2_5, branchMsg_5, pass_14, deps_1_14, model_1_15) => {
            let newNodeModel_2;
            const matchValue_1_6 = [branchMsg_5, indexPath_2_5];
            let pattern_matching_result_28, msg_1_15, xs_5_2, msg_2_15, xs_6_2, msg_3_15, x_3_5, xs_7_2;
            if (matchValue_1_6[0].tag === 2) {
                if (!isEmpty(matchValue_1_6[1])) {
                    if (head(matchValue_1_6[1]) === 0) {
                        pattern_matching_result_28 = 1;
                        msg_2_15 = matchValue_1_6[0].fields[0];
                        xs_6_2 = tail(matchValue_1_6[1]);
                    }
                    else {
                        pattern_matching_result_28 = 3;
                    }
                }
                else {
                    pattern_matching_result_28 = 3;
                }
            }
            else if (matchValue_1_6[0].tag === 1) {
                if (!isEmpty(matchValue_1_6[1])) {
                    if (head(matchValue_1_6[1]) > 0) {
                        pattern_matching_result_28 = 2;
                        msg_3_15 = matchValue_1_6[0].fields[0];
                        x_3_5 = head(matchValue_1_6[1]);
                        xs_7_2 = tail(matchValue_1_6[1]);
                    }
                    else {
                        pattern_matching_result_28 = 3;
                    }
                }
                else {
                    pattern_matching_result_28 = 3;
                }
            }
            else if (!isEmpty(matchValue_1_6[1])) {
                if (head(matchValue_1_6[1]) === 0) {
                    pattern_matching_result_28 = 0;
                    msg_1_15 = matchValue_1_6[0].fields[0];
                    xs_5_2 = tail(matchValue_1_6[1]);
                }
                else {
                    pattern_matching_result_28 = 3;
                }
            }
            else {
                pattern_matching_result_28 = 3;
            }
            switch (pattern_matching_result_28) {
                case 0: {
                    const matchValue_2_5 = model_1_15.MaybeCurrentModel;
                    if (matchValue_2_5 == null) {
                        console.error(some("Failed to update state, not initialized"));
                        return [model_1_15, Cmd_none_1(), void 0, void 0];
                    }
                    else {
                        const currentModel_2_5 = matchValue_2_5;
                        const patternInput_3_6 = current_43.Update(indexPath_2_5, msg_1_15, pass_14, deps_1_14, currentModel_2_5);
                        const newCurrentModel_6 = patternInput_3_6[0];
                        const maybeOutboundMsg_6 = patternInput_3_6[3];
                        const maybeNavMsg_6 = patternInput_3_6[2];
                        const currentCmd_1_5 = patternInput_3_6[1];
                        let pattern_matching_result_29;
                        if (msg_1_15.tag === 2) {
                            if (!equals(xs_5_2, empty())) {
                                pattern_matching_result_29 = 0;
                            }
                            else {
                                pattern_matching_result_29 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_29 = 1;
                        }
                        switch (pattern_matching_result_29) {
                            case 0: {
                                const patternInput_4_5 = child_10.Init(xs_5_2, currentModel_2_5.NodeModel, model_1_15.MaybeChildModel, false);
                                return [new Internal_BranchModelChildAndSibling$3(newCurrentModel_6, model_1_15.MaybeSiblingModel, patternInput_4_5[0]), Cmd_batch_1(ofArray([Cmd_map((arg0_10_3) => (new Internal_BranchMsgChildAndSibling$3(0, arg0_10_3)), currentCmd_1_5), Cmd_map((arg0_11_2) => (new Internal_BranchMsgChildAndSibling$3(2, arg0_11_2)), patternInput_4_5[1])])), maybeNavMsg_6, maybeOutboundMsg_6];
                            }
                            case 1: {
                                return [new Internal_BranchModelChildAndSibling$3(newCurrentModel_6, model_1_15.MaybeSiblingModel, model_1_15.MaybeChildModel), Cmd_map((arg0_13_2) => (new Internal_BranchMsgChildAndSibling$3(0, arg0_13_2)), currentCmd_1_5), maybeNavMsg_6, maybeOutboundMsg_6];
                            }
                        }
                    }
                }
                case 1: {
                    const matchValue_3_5 = [model_1_15.MaybeCurrentModel, model_1_15.MaybeChildModel];
                    let pattern_matching_result_30, childModel_1_2, currentModel_3_3;
                    if (matchValue_3_5[0] != null) {
                        if (matchValue_3_5[1] != null) {
                            pattern_matching_result_30 = 0;
                            childModel_1_2 = matchValue_3_5[1];
                            currentModel_3_3 = matchValue_3_5[0];
                        }
                        else {
                            pattern_matching_result_30 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_30 = 1;
                    }
                    switch (pattern_matching_result_30) {
                        case 0: {
                            const patternInput_5_5 = child_10.Update(xs_6_2, msg_2_15, pass_14, currentModel_3_3.NodeModel, childModel_1_2);
                            const maybeNavMsg_1_5 = patternInput_5_5[2];
                            return [new Internal_BranchModelChildAndSibling$3((maybeNavMsg_1_5 != null) ? ((maybeNavMsg_1_5.tag === 2) ? ((newNodeModel_2 = maybeNavMsg_1_5.fields[0], map((model_2_15) => (new Internal_StateTreeModel$1(newNodeModel_2, model_2_15.Initialized)), model_1_15.MaybeCurrentModel))) : model_1_15.MaybeCurrentModel) : model_1_15.MaybeCurrentModel, model_1_15.MaybeSiblingModel, patternInput_5_5[0]), Cmd_map((arg0_15_2) => (new Internal_BranchMsgChildAndSibling$3(2, arg0_15_2)), patternInput_5_5[1]), map(NavigationMsg$1_destructiveCast_Z33BEA92B, maybeNavMsg_1_5), patternInput_5_5[3]];
                        }
                        case 1: {
                            console.error(some("Failed to update state, parent not initialized"));
                            return [model_1_15, Cmd_none_1(), void 0, void 0];
                        }
                    }
                }
                case 2: {
                    const matchValue_4_5 = model_1_15.MaybeSiblingModel;
                    if (matchValue_4_5 == null) {
                        console.error(some("Failed to update state, not initialized"));
                        return [model_1_15, Cmd_none_1(), void 0, void 0];
                    }
                    else {
                        const patternInput_6_5 = sibling_11.Update(cons(x_3_5 - 1, xs_7_2), msg_3_15, pass_14, deps_1_14, matchValue_4_5);
                        return [new Internal_BranchModelChildAndSibling$3(model_1_15.MaybeCurrentModel, patternInput_6_5[0], model_1_15.MaybeChildModel), Cmd_map((arg0_17_2) => (new Internal_BranchMsgChildAndSibling$3(1, arg0_17_2)), patternInput_6_5[1]), patternInput_6_5[2], patternInput_6_5[3]];
                    }
                }
                case 3: {
                    console.warn(some("Got an update for a node that shouldn\u0027t be initialized, ignoring"));
                    return [model_1_15, Cmd_none_1(), void 0, void 0];
                }
            }
        }, (msg_4_12, deps_2_14, model_3_11) => {
            let patternInput_8_3;
            const matchValue_5_3 = model_3_11.MaybeCurrentModel;
            if (matchValue_5_3 == null) {
                patternInput_8_3 = [model_3_11.MaybeCurrentModel, Cmd_none_1()];
            }
            else {
                const patternInput_7_5 = current_43.UpdateInbound(msg_4_12, deps_2_14, matchValue_5_3);
                patternInput_8_3 = [patternInput_7_5[0], patternInput_7_5[1]];
            }
            let patternInput_10_3;
            const matchValue_6_3 = [model_3_11.MaybeChildModel, model_3_11.MaybeCurrentModel];
            let pattern_matching_result_31, childModel_2_2, currentModel_5_2;
            if (matchValue_6_3[0] != null) {
                if (matchValue_6_3[1] != null) {
                    pattern_matching_result_31 = 0;
                    childModel_2_2 = matchValue_6_3[0];
                    currentModel_5_2 = matchValue_6_3[1];
                }
                else {
                    pattern_matching_result_31 = 1;
                }
            }
            else {
                pattern_matching_result_31 = 1;
            }
            switch (pattern_matching_result_31) {
                case 0: {
                    const patternInput_9_3 = child_10.UpdateInbound(msg_4_12, currentModel_5_2.NodeModel, childModel_2_2);
                    patternInput_10_3 = [patternInput_9_3[0], patternInput_9_3[1]];
                    break;
                }
                case 1: {
                    patternInput_10_3 = [model_3_11.MaybeChildModel, Cmd_none_1()];
                    break;
                }
            }
            let patternInput_12_2;
            const matchValue_7_3 = model_3_11.MaybeSiblingModel;
            if (matchValue_7_3 == null) {
                patternInput_12_2 = [model_3_11.MaybeSiblingModel, Cmd_none_1()];
            }
            else {
                const patternInput_11_2 = sibling_11.UpdateInbound(msg_4_12, deps_2_14, matchValue_7_3);
                patternInput_12_2 = [patternInput_11_2[0], patternInput_11_2[1]];
            }
            return [new Internal_BranchModelChildAndSibling$3(patternInput_8_3[0], patternInput_12_2[0], patternInput_10_3[0]), Cmd_batch_1(ofArray([Cmd_map((arg0_18_2) => (new Internal_BranchMsgChildAndSibling$3(0, arg0_18_2)), patternInput_8_3[1]), Cmd_map((arg0_19_2) => (new Internal_BranchMsgChildAndSibling$3(2, arg0_19_2)), patternInput_10_3[1]), Cmd_map((arg0_20_2) => (new Internal_BranchMsgChildAndSibling$3(1, arg0_20_2)), patternInput_12_2[1])]))];
        }, current_43.LastKey, Internal_combineSiblingIndexPathFromKeyPathMap(sibling_11.IndexPathFromKeyPath, Internal_combineChildIndexPathFromKeyPathMap(current_43.LastKey, child_10.IndexPathFromKeyPath, current_43.IndexPathFromKeyPath)));
    })), (arg_21) => siblingBuilder_9(f1_21(arg_21)))))))), (f1_24 = ((current_1_7 = ((config_17 = ((inputRecord_1 = defaultConfig_1(), new StateTreeConfig$6(inputRecord_1.VisibleToChildren, true, inputRecord_1.MaybeSendMessageOnNavigation, inputRecord_1.KeepCachedChildren, (msg_5, _arg2, model_1) => {
        let pattern_matching_result_32;
        if (msg_5.tag === 1) {
            if (msg_5.fields[0] === model_1.patient.deviceId) {
                pattern_matching_result_32 = 0;
            }
            else {
                pattern_matching_result_32 = 1;
            }
        }
        else {
            pattern_matching_result_32 = 1;
        }
        switch (pattern_matching_result_32) {
            case 0: {
                return [model_1, Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientTelepathologyMsg(3)))];
            }
            case 1: {
                return [model_1, Cmd_none()];
            }
        }
    }))), (maybeAutoMessage_9 = config_17.MaybeSendMessageOnNavigation, (key_41 = "Telepathology", (current_47 = patientTelepathologyView(), new Internal_StateTreeRoot$6((_arg2_10, deps_17, maybeCachedModel_15, _arg1_10) => {
        const matchValue_18 = [maybeCachedModel_15, config_17.ReInitOnNavigation, maybeAutoMessage_9];
        let pattern_matching_result_33, model_19, model_1_16, msg_41;
        if (matchValue_18[0] != null) {
            if (matchValue_18[1]) {
                pattern_matching_result_33 = 2;
            }
            else if (matchValue_18[2] != null) {
                pattern_matching_result_33 = 1;
                model_1_16 = matchValue_18[0];
                msg_41 = matchValue_18[2];
            }
            else {
                pattern_matching_result_33 = 0;
                model_19 = matchValue_18[0];
            }
        }
        else {
            pattern_matching_result_33 = 2;
        }
        switch (pattern_matching_result_33) {
            case 0: {
                return [model_19, Cmd_none_1()];
            }
            case 1: {
                return [model_1_16, Cmd_OfFunc_result(msg_41)];
            }
            case 2: {
                const patternInput_23 = current_47.Init(deps_17);
                return [new Internal_StateTreeModel$1(patternInput_23[0], false), Cmd_batch_1(toList(delay(() => append(singleton_1(patternInput_23[1]), delay(() => ofArray(toArray(map(Cmd_OfFunc_result, maybeAutoMessage_9))))))))];
            }
        }
    }, (_arg3_9, dispatch_15, model_2_16) => current_47.View(model_2_16.NodeModel, dispatch_15), (_arg4_9, msg_3_16, pass_15, deps_1_15, model_3_12) => {
        switch (msg_3_16.tag) {
            case 1: {
                console.error(some(`State tree error: ${msg_3_16.fields[0]}`));
                return [model_3_12, Cmd_none_1(), void 0, void 0];
            }
            case 2: {
                return [new Internal_StateTreeModel$1(model_3_12.NodeModel, true), Cmd_none_1(), void 0, void 0];
            }
            case 3: {
                return [model_3_12, Cmd_none_1(), void 0, msg_3_16.fields[0]];
            }
            case 4: {
                return [model_3_12, Cmd_none_1(), msg_3_16.fields[0], void 0];
            }
            default: {
                const patternInput_1_15 = current_47.Update(msg_3_16.fields[0], pass_15, deps_1_15, model_3_12.NodeModel);
                return [new Internal_StateTreeModel$1(patternInput_1_15[0], model_3_12.Initialized), patternInput_1_15[1], void 0, void 0];
            }
        }
    }, (msg_4_13, deps_2_15, model_4_9) => {
        const patternInput_2_15 = config_17.UpdateInbound(msg_4_13, deps_2_15, model_4_9.NodeModel);
        return [new Internal_StateTreeModel$1(patternInput_2_15[0], model_4_9.Initialized), patternInput_2_15[1]];
    }, key_41, ofList(singleton([singleton(key_41), singleton(0)])))))))), (sibling_12) => {
        const current_48 = current_1_7;
        const sibling_13 = sibling_12;
        return new Internal_StateTreeRoot$6((indexPath_8, deps_18, maybeCachedModel_16, keepCachedSiblings_6) => {
            const maybeCachedCurrentModel_6 = bind((cachedModel_7) => cachedModel_7.MaybeCurrentModel, maybeCachedModel_16);
            const maybeCachedSiblingModel_6 = bind((cachedModel_1_6) => cachedModel_1_6.MaybeSiblingModel, maybeCachedModel_16);
            let pattern_matching_result_34, x_9, xs_12;
            if (isEmpty(indexPath_8)) {
                pattern_matching_result_34 = 1;
            }
            else if (head(indexPath_8) === 0) {
                if (isEmpty(tail(indexPath_8))) {
                    pattern_matching_result_34 = 0;
                }
                else {
                    pattern_matching_result_34 = 1;
                }
            }
            else {
                pattern_matching_result_34 = 2;
                x_9 = head(indexPath_8);
                xs_12 = tail(indexPath_8);
            }
            switch (pattern_matching_result_34) {
                case 0: {
                    const patternInput_24 = current_48.Init(indexPath_8, deps_18, maybeCachedCurrentModel_6, false);
                    return [new Internal_BranchModelSibling$2(patternInput_24[0], keepCachedSiblings_6 ? maybeCachedSiblingModel_6 : (void 0)), Cmd_map((arg0_25) => (new Internal_BranchMsgSibling$2(0, arg0_25)), patternInput_24[1])];
                }
                case 1: {
                    console.error(some("Failed to initialize state, invalid index"));
                    return [defaultArg(maybeCachedModel_16, new Internal_BranchModelSibling$2(void 0, void 0)), Cmd_none_1()];
                }
                case 2: {
                    const patternInput_1_16 = sibling_13.Init(cons(x_9 - 1, xs_12), deps_18, maybeCachedSiblingModel_6, keepCachedSiblings_6);
                    return [new Internal_BranchModelSibling$2(keepCachedSiblings_6 ? maybeCachedCurrentModel_6 : (void 0), patternInput_1_16[0]), Cmd_map((arg0_1_7) => (new Internal_BranchMsgSibling$2(1, arg0_1_7)), patternInput_1_16[1])];
                }
            }
        }, (indexPath_1_6, dispatch_16, model_20) => (isEmpty(indexPath_1_6) ? "Woops, looks like you took a wrong turn..." : ((head(indexPath_1_6) === 0) ? (isEmpty(tail(indexPath_1_6)) ? defaultArg(map(partialApply(1, current_48.View, [indexPath_1_6, (arg_22) => {
            dispatch_16(new Internal_BranchMsgSibling$2(0, arg_22));
        }]), model_20.MaybeCurrentModel), "Loading...") : "Woops, looks like you took a wrong turn...") : defaultArg(map(partialApply(1, sibling_13.View, [cons(head(indexPath_1_6) - 1, tail(indexPath_1_6)), (arg_23) => {
            dispatch_16(new Internal_BranchMsgSibling$2(1, arg_23));
        }]), model_20.MaybeSiblingModel), "Loading..."))), (indexPath_2_6, branchMsg_6, pass_16, deps_1_16, model_1_17) => {
            const matchValue_19 = [branchMsg_6, indexPath_2_6];
            let pattern_matching_result_35, msg_1_17, msg_2_17, x_3_6, xs_3_6;
            if (matchValue_19[0].tag === 1) {
                if (!isEmpty(matchValue_19[1])) {
                    if (head(matchValue_19[1]) > 0) {
                        pattern_matching_result_35 = 1;
                        msg_2_17 = matchValue_19[0].fields[0];
                        x_3_6 = head(matchValue_19[1]);
                        xs_3_6 = tail(matchValue_19[1]);
                    }
                    else {
                        pattern_matching_result_35 = 2;
                    }
                }
                else {
                    pattern_matching_result_35 = 2;
                }
            }
            else if (!isEmpty(matchValue_19[1])) {
                if (head(matchValue_19[1]) === 0) {
                    pattern_matching_result_35 = 0;
                    msg_1_17 = matchValue_19[0].fields[0];
                }
                else {
                    pattern_matching_result_35 = 2;
                }
            }
            else {
                pattern_matching_result_35 = 2;
            }
            switch (pattern_matching_result_35) {
                case 0: {
                    const matchValue_1_7 = model_1_17.MaybeCurrentModel;
                    if (matchValue_1_7 == null) {
                        console.error(some("Failed to update state, not initialized"));
                        return [model_1_17, Cmd_none_1(), void 0, void 0];
                    }
                    else {
                        const patternInput_2_16 = current_48.Update(indexPath_2_6, msg_1_17, pass_16, deps_1_16, matchValue_1_7);
                        return [new Internal_BranchModelSibling$2(patternInput_2_16[0], model_1_17.MaybeSiblingModel), Cmd_map((arg0_5_6) => (new Internal_BranchMsgSibling$2(0, arg0_5_6)), patternInput_2_16[1]), patternInput_2_16[2], patternInput_2_16[3]];
                    }
                }
                case 1: {
                    const matchValue_2_6 = model_1_17.MaybeSiblingModel;
                    if (matchValue_2_6 == null) {
                        console.error(some("Failed to update state, not initialized"));
                        return [model_1_17, Cmd_none_1(), void 0, void 0];
                    }
                    else {
                        const patternInput_3_7 = sibling_13.Update(cons(x_3_6 - 1, xs_3_6), msg_2_17, pass_16, deps_1_16, matchValue_2_6);
                        return [new Internal_BranchModelSibling$2(model_1_17.MaybeCurrentModel, patternInput_3_7[0]), Cmd_map((arg0_7_6) => (new Internal_BranchMsgSibling$2(1, arg0_7_6)), patternInput_3_7[1]), patternInput_3_7[2], patternInput_3_7[3]];
                    }
                }
                case 2: {
                    console.warn(some("Got an update for a node that shouldn\u0027t be initialized, ignoring"));
                    return [model_1_17, Cmd_none_1(), void 0, void 0];
                }
            }
        }, (msg_3_17, deps_2_16, model_2_17) => {
            let patternInput_5_6;
            const matchValue_3_6 = model_2_17.MaybeCurrentModel;
            if (matchValue_3_6 == null) {
                patternInput_5_6 = [model_2_17.MaybeCurrentModel, Cmd_none_1()];
            }
            else {
                const patternInput_4_6 = current_48.UpdateInbound(msg_3_17, deps_2_16, matchValue_3_6);
                patternInput_5_6 = [patternInput_4_6[0], patternInput_4_6[1]];
            }
            let patternInput_7_6;
            const matchValue_4_6 = model_2_17.MaybeSiblingModel;
            if (matchValue_4_6 == null) {
                patternInput_7_6 = [model_2_17.MaybeSiblingModel, Cmd_none_1()];
            }
            else {
                const patternInput_6_6 = sibling_13.UpdateInbound(msg_3_17, deps_2_16, matchValue_4_6);
                patternInput_7_6 = [patternInput_6_6[0], patternInput_6_6[1]];
            }
            return [new Internal_BranchModelSibling$2(patternInput_5_6[0], patternInput_7_6[0]), Cmd_batch_1(ofArray([Cmd_map((arg0_8_6) => (new Internal_BranchMsgSibling$2(0, arg0_8_6)), patternInput_5_6[1]), Cmd_map((arg0_9_6) => (new Internal_BranchMsgSibling$2(1, arg0_9_6)), patternInput_7_6[1])]))];
        }, current_48.LastKey, Internal_combineSiblingIndexPathFromKeyPathMap(sibling_13.IndexPathFromKeyPath, current_48.IndexPathFromKeyPath));
    })), (arg_24) => siblingBuilder_12(f1_24(arg_24))))), (f1_27 = ((current_1_8 = ((config_20 = ((inputRecord_2 = defaultConfig_1(), new StateTreeConfig$6(inputRecord_2.VisibleToChildren, true, inputRecord_2.MaybeSendMessageOnNavigation, inputRecord_2.KeepCachedChildren, inputRecord_2.UpdateInbound))), (maybeAutoMessage_10 = config_20.MaybeSendMessageOnNavigation, (key_45 = "Historical Comparison", (current_52 = patientHistoricalComparisonView(currentUser), new Internal_StateTreeRoot$6((_arg2_11, deps_19, maybeCachedModel_17, _arg1_11) => {
        const matchValue_20 = [maybeCachedModel_17, config_20.ReInitOnNavigation, maybeAutoMessage_10];
        let pattern_matching_result_36, model_21, model_1_18, msg_45;
        if (matchValue_20[0] != null) {
            if (matchValue_20[1]) {
                pattern_matching_result_36 = 2;
            }
            else if (matchValue_20[2] != null) {
                pattern_matching_result_36 = 1;
                model_1_18 = matchValue_20[0];
                msg_45 = matchValue_20[2];
            }
            else {
                pattern_matching_result_36 = 0;
                model_21 = matchValue_20[0];
            }
        }
        else {
            pattern_matching_result_36 = 2;
        }
        switch (pattern_matching_result_36) {
            case 0: {
                return [model_21, Cmd_none_1()];
            }
            case 1: {
                return [model_1_18, Cmd_OfFunc_result(msg_45)];
            }
            case 2: {
                const patternInput_25 = current_52.Init(deps_19);
                return [new Internal_StateTreeModel$1(patternInput_25[0], false), Cmd_batch_1(toList(delay(() => append(singleton_1(patternInput_25[1]), delay(() => ofArray(toArray(map(Cmd_OfFunc_result, maybeAutoMessage_10))))))))];
            }
        }
    }, (_arg3_10, dispatch_17, model_2_18) => current_52.View(model_2_18.NodeModel, dispatch_17), (_arg4_10, msg_3_18, pass_17, deps_1_17, model_3_13) => {
        switch (msg_3_18.tag) {
            case 1: {
                console.error(some(`State tree error: ${msg_3_18.fields[0]}`));
                return [model_3_13, Cmd_none_1(), void 0, void 0];
            }
            case 2: {
                return [new Internal_StateTreeModel$1(model_3_13.NodeModel, true), Cmd_none_1(), void 0, void 0];
            }
            case 3: {
                return [model_3_13, Cmd_none_1(), void 0, msg_3_18.fields[0]];
            }
            case 4: {
                return [model_3_13, Cmd_none_1(), msg_3_18.fields[0], void 0];
            }
            default: {
                const patternInput_1_17 = current_52.Update(msg_3_18.fields[0], pass_17, deps_1_17, model_3_13.NodeModel);
                return [new Internal_StateTreeModel$1(patternInput_1_17[0], model_3_13.Initialized), patternInput_1_17[1], void 0, void 0];
            }
        }
    }, (msg_4_14, deps_2_17, model_4_10) => {
        const patternInput_2_17 = config_20.UpdateInbound(msg_4_14, deps_2_17, model_4_10.NodeModel);
        return [new Internal_StateTreeModel$1(patternInput_2_17[0], model_4_10.Initialized), patternInput_2_17[1]];
    }, key_45, ofList(singleton([singleton(key_45), singleton(0)])))))))), (sibling_14) => {
        const current_53 = current_1_8;
        const sibling_15 = sibling_14;
        return new Internal_StateTreeRoot$6((indexPath_9, deps_20, maybeCachedModel_18, keepCachedSiblings_7) => {
            const maybeCachedCurrentModel_7 = bind((cachedModel_8) => cachedModel_8.MaybeCurrentModel, maybeCachedModel_18);
            const maybeCachedSiblingModel_7 = bind((cachedModel_1_7) => cachedModel_1_7.MaybeSiblingModel, maybeCachedModel_18);
            let pattern_matching_result_37, x_10, xs_13;
            if (isEmpty(indexPath_9)) {
                pattern_matching_result_37 = 1;
            }
            else if (head(indexPath_9) === 0) {
                if (isEmpty(tail(indexPath_9))) {
                    pattern_matching_result_37 = 0;
                }
                else {
                    pattern_matching_result_37 = 1;
                }
            }
            else {
                pattern_matching_result_37 = 2;
                x_10 = head(indexPath_9);
                xs_13 = tail(indexPath_9);
            }
            switch (pattern_matching_result_37) {
                case 0: {
                    const patternInput_26 = current_53.Init(indexPath_9, deps_20, maybeCachedCurrentModel_7, false);
                    return [new Internal_BranchModelSibling$2(patternInput_26[0], keepCachedSiblings_7 ? maybeCachedSiblingModel_7 : (void 0)), Cmd_map((arg0_26) => (new Internal_BranchMsgSibling$2(0, arg0_26)), patternInput_26[1])];
                }
                case 1: {
                    console.error(some("Failed to initialize state, invalid index"));
                    return [defaultArg(maybeCachedModel_18, new Internal_BranchModelSibling$2(void 0, void 0)), Cmd_none_1()];
                }
                case 2: {
                    const patternInput_1_18 = sibling_15.Init(cons(x_10 - 1, xs_13), deps_20, maybeCachedSiblingModel_7, keepCachedSiblings_7);
                    return [new Internal_BranchModelSibling$2(keepCachedSiblings_7 ? maybeCachedCurrentModel_7 : (void 0), patternInput_1_18[0]), Cmd_map((arg0_1_8) => (new Internal_BranchMsgSibling$2(1, arg0_1_8)), patternInput_1_18[1])];
                }
            }
        }, (indexPath_1_7, dispatch_18, model_22) => (isEmpty(indexPath_1_7) ? "Woops, looks like you took a wrong turn..." : ((head(indexPath_1_7) === 0) ? (isEmpty(tail(indexPath_1_7)) ? defaultArg(map(partialApply(1, current_53.View, [indexPath_1_7, (arg_25) => {
            dispatch_18(new Internal_BranchMsgSibling$2(0, arg_25));
        }]), model_22.MaybeCurrentModel), "Loading...") : "Woops, looks like you took a wrong turn...") : defaultArg(map(partialApply(1, sibling_15.View, [cons(head(indexPath_1_7) - 1, tail(indexPath_1_7)), (arg_26) => {
            dispatch_18(new Internal_BranchMsgSibling$2(1, arg_26));
        }]), model_22.MaybeSiblingModel), "Loading..."))), (indexPath_2_7, branchMsg_7, pass_18, deps_1_18, model_1_19) => {
            const matchValue_21 = [branchMsg_7, indexPath_2_7];
            let pattern_matching_result_38, msg_1_19, msg_2_19, x_3_7, xs_3_7;
            if (matchValue_21[0].tag === 1) {
                if (!isEmpty(matchValue_21[1])) {
                    if (head(matchValue_21[1]) > 0) {
                        pattern_matching_result_38 = 1;
                        msg_2_19 = matchValue_21[0].fields[0];
                        x_3_7 = head(matchValue_21[1]);
                        xs_3_7 = tail(matchValue_21[1]);
                    }
                    else {
                        pattern_matching_result_38 = 2;
                    }
                }
                else {
                    pattern_matching_result_38 = 2;
                }
            }
            else if (!isEmpty(matchValue_21[1])) {
                if (head(matchValue_21[1]) === 0) {
                    pattern_matching_result_38 = 0;
                    msg_1_19 = matchValue_21[0].fields[0];
                }
                else {
                    pattern_matching_result_38 = 2;
                }
            }
            else {
                pattern_matching_result_38 = 2;
            }
            switch (pattern_matching_result_38) {
                case 0: {
                    const matchValue_1_8 = model_1_19.MaybeCurrentModel;
                    if (matchValue_1_8 == null) {
                        console.error(some("Failed to update state, not initialized"));
                        return [model_1_19, Cmd_none_1(), void 0, void 0];
                    }
                    else {
                        const patternInput_2_18 = current_53.Update(indexPath_2_7, msg_1_19, pass_18, deps_1_18, matchValue_1_8);
                        return [new Internal_BranchModelSibling$2(patternInput_2_18[0], model_1_19.MaybeSiblingModel), Cmd_map((arg0_5_7) => (new Internal_BranchMsgSibling$2(0, arg0_5_7)), patternInput_2_18[1]), patternInput_2_18[2], patternInput_2_18[3]];
                    }
                }
                case 1: {
                    const matchValue_2_7 = model_1_19.MaybeSiblingModel;
                    if (matchValue_2_7 == null) {
                        console.error(some("Failed to update state, not initialized"));
                        return [model_1_19, Cmd_none_1(), void 0, void 0];
                    }
                    else {
                        const patternInput_3_8 = sibling_15.Update(cons(x_3_7 - 1, xs_3_7), msg_2_19, pass_18, deps_1_18, matchValue_2_7);
                        return [new Internal_BranchModelSibling$2(model_1_19.MaybeCurrentModel, patternInput_3_8[0]), Cmd_map((arg0_7_7) => (new Internal_BranchMsgSibling$2(1, arg0_7_7)), patternInput_3_8[1]), patternInput_3_8[2], patternInput_3_8[3]];
                    }
                }
                case 2: {
                    console.warn(some("Got an update for a node that shouldn\u0027t be initialized, ignoring"));
                    return [model_1_19, Cmd_none_1(), void 0, void 0];
                }
            }
        }, (msg_3_19, deps_2_18, model_2_19) => {
            let patternInput_5_7;
            const matchValue_3_7 = model_2_19.MaybeCurrentModel;
            if (matchValue_3_7 == null) {
                patternInput_5_7 = [model_2_19.MaybeCurrentModel, Cmd_none_1()];
            }
            else {
                const patternInput_4_7 = current_53.UpdateInbound(msg_3_19, deps_2_18, matchValue_3_7);
                patternInput_5_7 = [patternInput_4_7[0], patternInput_4_7[1]];
            }
            let patternInput_7_7;
            const matchValue_4_7 = model_2_19.MaybeSiblingModel;
            if (matchValue_4_7 == null) {
                patternInput_7_7 = [model_2_19.MaybeSiblingModel, Cmd_none_1()];
            }
            else {
                const patternInput_6_7 = sibling_15.UpdateInbound(msg_3_19, deps_2_18, matchValue_4_7);
                patternInput_7_7 = [patternInput_6_7[0], patternInput_6_7[1]];
            }
            return [new Internal_BranchModelSibling$2(patternInput_5_7[0], patternInput_7_7[0]), Cmd_batch_1(ofArray([Cmd_map((arg0_8_7) => (new Internal_BranchMsgSibling$2(0, arg0_8_7)), patternInput_5_7[1]), Cmd_map((arg0_9_7) => (new Internal_BranchMsgSibling$2(1, arg0_9_7)), patternInput_7_7[1])]))];
        }, current_53.LastKey, Internal_combineSiblingIndexPathFromKeyPathMap(sibling_15.IndexPathFromKeyPath, current_53.IndexPathFromKeyPath));
    })), (arg_27) => siblingBuilder_14(f1_27(arg_27)))))((config_23 = defaultConfig_1(), (maybeAutoMessage_11 = config_23.MaybeSendMessageOnNavigation, (key_49 = "Report", (current_57 = patientReportView(currentUser), new Internal_StateTreeRoot$6((_arg2_12, deps_21, maybeCachedModel_19, _arg1_12) => {
        const matchValue_22 = [maybeCachedModel_19, config_23.ReInitOnNavigation, maybeAutoMessage_11];
        let pattern_matching_result_39, model_23, model_1_20, msg_49;
        if (matchValue_22[0] != null) {
            if (matchValue_22[1]) {
                pattern_matching_result_39 = 2;
            }
            else if (matchValue_22[2] != null) {
                pattern_matching_result_39 = 1;
                model_1_20 = matchValue_22[0];
                msg_49 = matchValue_22[2];
            }
            else {
                pattern_matching_result_39 = 0;
                model_23 = matchValue_22[0];
            }
        }
        else {
            pattern_matching_result_39 = 2;
        }
        switch (pattern_matching_result_39) {
            case 0: {
                return [model_23, Cmd_none_1()];
            }
            case 1: {
                return [model_1_20, Cmd_OfFunc_result(msg_49)];
            }
            case 2: {
                const patternInput_27 = current_57.Init(deps_21);
                return [new Internal_StateTreeModel$1(patternInput_27[0], false), Cmd_batch_1(toList(delay(() => append(singleton_1(patternInput_27[1]), delay(() => ofArray(toArray(map(Cmd_OfFunc_result, maybeAutoMessage_11))))))))];
            }
        }
    }, (_arg3_11, dispatch_19, model_2_20) => current_57.View(model_2_20.NodeModel, dispatch_19), (_arg4_11, msg_3_20, pass_19, deps_1_19, model_3_14) => {
        switch (msg_3_20.tag) {
            case 1: {
                console.error(some(`State tree error: ${msg_3_20.fields[0]}`));
                return [model_3_14, Cmd_none_1(), void 0, void 0];
            }
            case 2: {
                return [new Internal_StateTreeModel$1(model_3_14.NodeModel, true), Cmd_none_1(), void 0, void 0];
            }
            case 3: {
                return [model_3_14, Cmd_none_1(), void 0, msg_3_20.fields[0]];
            }
            case 4: {
                return [model_3_14, Cmd_none_1(), msg_3_20.fields[0], void 0];
            }
            default: {
                const patternInput_1_19 = current_57.Update(msg_3_20.fields[0], pass_19, deps_1_19, model_3_14.NodeModel);
                return [new Internal_StateTreeModel$1(patternInput_1_19[0], model_3_14.Initialized), patternInput_1_19[1], void 0, void 0];
            }
        }
    }, (msg_4_15, deps_2_19, model_4_11) => {
        const patternInput_2_19 = config_23.UpdateInbound(msg_4_15, deps_2_19, model_4_11.NodeModel);
        return [new Internal_StateTreeModel$1(patternInput_2_19[0], model_4_11.Initialized), patternInput_2_19[1]];
    }, key_49, ofList(singleton([singleton(key_49), singleton(0)])))))))), (current_61 = ((config_25 = config_24, (maybeAutoMessage_12 = config_25.MaybeSendMessageOnNavigation, (key_52 = "list", (current_60 = current_58, new Internal_StateTreeRoot$6((_arg2_13, deps_22, maybeCachedModel_20, _arg1_13) => {
        const matchValue_23 = [maybeCachedModel_20, config_25.ReInitOnNavigation, maybeAutoMessage_12];
        let pattern_matching_result_40, model_24, model_1_21, msg_52;
        if (matchValue_23[0] != null) {
            if (matchValue_23[1]) {
                pattern_matching_result_40 = 2;
            }
            else if (matchValue_23[2] != null) {
                pattern_matching_result_40 = 1;
                model_1_21 = matchValue_23[0];
                msg_52 = matchValue_23[2];
            }
            else {
                pattern_matching_result_40 = 0;
                model_24 = matchValue_23[0];
            }
        }
        else {
            pattern_matching_result_40 = 2;
        }
        switch (pattern_matching_result_40) {
            case 0: {
                return [model_24, Cmd_none_1()];
            }
            case 1: {
                return [model_1_21, Cmd_OfFunc_result(msg_52)];
            }
            case 2: {
                const patternInput_28 = current_60.Init(deps_22);
                return [new Internal_StateTreeModel$1(patternInput_28[0], false), Cmd_batch_1(toList(delay(() => append(singleton_1(patternInput_28[1]), delay(() => ofArray(toArray(map(Cmd_OfFunc_result, maybeAutoMessage_12))))))))];
            }
        }
    }, (_arg3_12, dispatch_20, model_2_21) => current_60.View(model_2_21.NodeModel, dispatch_20), (_arg4_12, msg_3_21, pass_20, deps_1_20, model_3_15) => {
        switch (msg_3_21.tag) {
            case 1: {
                console.error(some(`State tree error: ${msg_3_21.fields[0]}`));
                return [model_3_15, Cmd_none_1(), void 0, void 0];
            }
            case 2: {
                return [new Internal_StateTreeModel$1(model_3_15.NodeModel, true), Cmd_none_1(), void 0, void 0];
            }
            case 3: {
                return [model_3_15, Cmd_none_1(), void 0, msg_3_21.fields[0]];
            }
            case 4: {
                return [model_3_15, Cmd_none_1(), msg_3_21.fields[0], void 0];
            }
            default: {
                const patternInput_1_20 = current_60.Update(msg_3_21.fields[0], pass_20, deps_1_20, model_3_15.NodeModel);
                return [new Internal_StateTreeModel$1(patternInput_1_20[0], model_3_15.Initialized), patternInput_1_20[1], void 0, void 0];
            }
        }
    }, (msg_4_16, deps_2_20, model_4_12) => {
        const patternInput_2_20 = config_25.UpdateInbound(msg_4_16, deps_2_20, model_4_12.NodeModel);
        return [new Internal_StateTreeModel$1(patternInput_2_20[0], model_4_12.Initialized), patternInput_2_20[1]];
    }, key_52, ofList(singleton([singleton(key_52), singleton(0)])))))))), new Internal_StateTreeRoot$6((indexPath_10, deps_23, maybeCachedModel_21, _arg1_14) => {
        const maybeCachedCurrentModel_8 = bind((cachedModel_9) => cachedModel_9.MaybeCurrentModel, maybeCachedModel_21);
        const maybeCachedChildModel_3 = bind((cachedModel_1_8) => cachedModel_1_8.MaybeChildModel, maybeCachedModel_21);
        const matchValue_24 = [indexPath_10, maybeCachedCurrentModel_8];
        let pattern_matching_result_41, currentModel_1_8, xs_14;
        if (!isEmpty(matchValue_24[0])) {
            if (head(matchValue_24[0]) === 0) {
                if (isEmpty(tail(matchValue_24[0]))) {
                    pattern_matching_result_41 = 0;
                }
                else if (matchValue_24[1] != null) {
                    pattern_matching_result_41 = 1;
                    currentModel_1_8 = matchValue_24[1];
                    xs_14 = tail(matchValue_24[0]);
                }
                else {
                    pattern_matching_result_41 = 0;
                }
            }
            else {
                pattern_matching_result_41 = 2;
            }
        }
        else {
            pattern_matching_result_41 = 2;
        }
        switch (pattern_matching_result_41) {
            case 0: {
                const patternInput_29 = current_61.Init(indexPath_10, deps_23, maybeCachedCurrentModel_8, false);
                return [new Internal_BranchModelChild$2(patternInput_29[0], keepCachedChildren_7 ? maybeCachedChildModel_3 : (void 0)), Cmd_map((arg0_27) => (new Internal_BranchMsgChild$2(0, arg0_27)), patternInput_29[1])];
            }
            case 1: {
                const patternInput_1_21 = child_12.Init(xs_14, currentModel_1_8.NodeModel, maybeCachedChildModel_3, keepCachedChildren_7);
                return [new Internal_BranchModelChild$2(currentModel_1_8, patternInput_1_21[0]), Cmd_map((arg0_2_9) => (new Internal_BranchMsgChild$2(1, arg0_2_9)), patternInput_1_21[1])];
            }
            case 2: {
                console.error(some("Failed to initialize state, invalid index"));
                return [defaultArg(maybeCachedModel_21, new Internal_BranchModelChild$2(void 0, void 0)), Cmd_none_1()];
            }
        }
    }, (indexPath_1_8, dispatch_21, model_25) => {
        let pattern_matching_result_42, xs_1_8;
        if (!isEmpty(indexPath_1_8)) {
            if (head(indexPath_1_8) === 0) {
                if (isEmpty(tail(indexPath_1_8))) {
                    pattern_matching_result_42 = 0;
                }
                else {
                    pattern_matching_result_42 = 1;
                    xs_1_8 = tail(indexPath_1_8);
                }
            }
            else {
                pattern_matching_result_42 = 2;
            }
        }
        else {
            pattern_matching_result_42 = 2;
        }
        switch (pattern_matching_result_42) {
            case 0: {
                return defaultArg(map(partialApply(1, current_61.View, [indexPath_1_8, (arg_28) => {
                    dispatch_21(new Internal_BranchMsgChild$2(0, arg_28));
                }]), model_25.MaybeCurrentModel), "Loading...");
            }
            case 1: {
                const children_6 = toList(delay(() => append(config_24.VisibleToChildren ? singleton_1(defaultArg(map(partialApply(1, current_61.View, [indexPath_1_8, (arg_29) => {
                    dispatch_21(new Internal_BranchMsgChild$2(0, arg_29));
                }]), model_25.MaybeCurrentModel), "Loading...")) : empty_1(), delay(() => singleton_1(defaultArg(map(partialApply(1, child_12.View, [xs_1_8, (arg_30) => {
                    dispatch_21(new Internal_BranchMsgChild$2(1, arg_30));
                }]), model_25.MaybeChildModel), "Loading..."))))));
                return react.createElement("div", {}, ...children_6);
            }
            case 2: {
                return "Woops, looks like you took a wrong turn...";
            }
        }
    }, (indexPath_2_8, branchMsg_8, pass_21, deps_1_21, model_1_22) => {
        let newNodeModel_3;
        const matchValue_1_9 = [branchMsg_8, indexPath_2_8];
        let pattern_matching_result_43, msg_55, xs_2_8, msg_1_22, xs_3_8;
        if (matchValue_1_9[0].tag === 1) {
            if (!isEmpty(matchValue_1_9[1])) {
                if (head(matchValue_1_9[1]) === 0) {
                    pattern_matching_result_43 = 1;
                    msg_1_22 = matchValue_1_9[0].fields[0];
                    xs_3_8 = tail(matchValue_1_9[1]);
                }
                else {
                    pattern_matching_result_43 = 2;
                }
            }
            else {
                pattern_matching_result_43 = 2;
            }
        }
        else if (!isEmpty(matchValue_1_9[1])) {
            if (head(matchValue_1_9[1]) === 0) {
                pattern_matching_result_43 = 0;
                msg_55 = matchValue_1_9[0].fields[0];
                xs_2_8 = tail(matchValue_1_9[1]);
            }
            else {
                pattern_matching_result_43 = 2;
            }
        }
        else {
            pattern_matching_result_43 = 2;
        }
        switch (pattern_matching_result_43) {
            case 0: {
                const matchValue_2_8 = model_1_22.MaybeCurrentModel;
                if (matchValue_2_8 == null) {
                    console.error(some("Failed to update state, not initialized"));
                    return [model_1_22, Cmd_none_1(), void 0, void 0];
                }
                else {
                    const currentModel_2_8 = matchValue_2_8;
                    const patternInput_2_21 = current_61.Update(indexPath_2_8, msg_55, pass_21, deps_1_21, currentModel_2_8);
                    const newCurrentModel_9 = patternInput_2_21[0];
                    const maybeOutboundMsg_9 = patternInput_2_21[3];
                    const maybeNavMsg_9 = patternInput_2_21[2];
                    const currentCmd_1_8 = patternInput_2_21[1];
                    let pattern_matching_result_44;
                    if (msg_55.tag === 2) {
                        if (!equals(xs_2_8, empty())) {
                            pattern_matching_result_44 = 0;
                        }
                        else {
                            pattern_matching_result_44 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_44 = 1;
                    }
                    switch (pattern_matching_result_44) {
                        case 0: {
                            const patternInput_3_9 = child_12.Init(xs_2_8, currentModel_2_8.NodeModel, model_1_22.MaybeChildModel, false);
                            return [new Internal_BranchModelChild$2(newCurrentModel_9, patternInput_3_9[0]), Cmd_batch_1(ofArray([Cmd_map((arg0_8_8) => (new Internal_BranchMsgChild$2(0, arg0_8_8)), currentCmd_1_8), Cmd_map((arg0_9_8) => (new Internal_BranchMsgChild$2(1, arg0_9_8)), patternInput_3_9[1])])), maybeNavMsg_9, maybeOutboundMsg_9];
                        }
                        case 1: {
                            return [new Internal_BranchModelChild$2(newCurrentModel_9, model_1_22.MaybeChildModel), Cmd_map((arg0_11_3) => (new Internal_BranchMsgChild$2(0, arg0_11_3)), currentCmd_1_8), maybeNavMsg_9, maybeOutboundMsg_9];
                        }
                    }
                }
            }
            case 1: {
                const matchValue_3_8 = [model_1_22.MaybeCurrentModel, model_1_22.MaybeChildModel];
                let pattern_matching_result_45, childModel_1_3, currentModel_3_4;
                if (matchValue_3_8[0] != null) {
                    if (matchValue_3_8[1] != null) {
                        pattern_matching_result_45 = 0;
                        childModel_1_3 = matchValue_3_8[1];
                        currentModel_3_4 = matchValue_3_8[0];
                    }
                    else {
                        pattern_matching_result_45 = 1;
                    }
                }
                else {
                    pattern_matching_result_45 = 1;
                }
                switch (pattern_matching_result_45) {
                    case 0: {
                        const patternInput_4_8 = child_12.Update(xs_3_8, msg_1_22, pass_21, currentModel_3_4.NodeModel, childModel_1_3);
                        const maybeNavMsg_1_8 = patternInput_4_8[2];
                        return [new Internal_BranchModelChild$2((maybeNavMsg_1_8 != null) ? ((maybeNavMsg_1_8.tag === 2) ? ((newNodeModel_3 = maybeNavMsg_1_8.fields[0], map((model_2_22) => (new Internal_StateTreeModel$1(newNodeModel_3, model_2_22.Initialized)), model_1_22.MaybeCurrentModel))) : model_1_22.MaybeCurrentModel) : model_1_22.MaybeCurrentModel, patternInput_4_8[0]), Cmd_map((arg0_13_3) => (new Internal_BranchMsgChild$2(1, arg0_13_3)), patternInput_4_8[1]), map(NavigationMsg$1_destructiveCast_Z33BEA92B, maybeNavMsg_1_8), patternInput_4_8[3]];
                    }
                    case 1: {
                        console.error(some("Failed to update state, parent not initialized"));
                        return [model_1_22, Cmd_none_1(), void 0, void 0];
                    }
                }
            }
            case 2: {
                console.warn(some("Got an update for a node that shouldn\u0027t be initialized, ignoring"));
                return [model_1_22, Cmd_none_1(), void 0, void 0];
            }
        }
    }, (msg_2_22, deps_2_21, model_3_16) => {
        let patternInput_6_8;
        const matchValue_4_8 = model_3_16.MaybeCurrentModel;
        if (matchValue_4_8 == null) {
            patternInput_6_8 = [model_3_16.MaybeCurrentModel, Cmd_none_1()];
        }
        else {
            const patternInput_5_8 = current_61.UpdateInbound(msg_2_22, deps_2_21, matchValue_4_8);
            patternInput_6_8 = [patternInput_5_8[0], patternInput_5_8[1]];
        }
        let patternInput_8_4;
        const matchValue_5_4 = [model_3_16.MaybeChildModel, model_3_16.MaybeCurrentModel];
        let pattern_matching_result_46, childModel_2_3, currentModel_5_3;
        if (matchValue_5_4[0] != null) {
            if (matchValue_5_4[1] != null) {
                pattern_matching_result_46 = 0;
                childModel_2_3 = matchValue_5_4[0];
                currentModel_5_3 = matchValue_5_4[1];
            }
            else {
                pattern_matching_result_46 = 1;
            }
        }
        else {
            pattern_matching_result_46 = 1;
        }
        switch (pattern_matching_result_46) {
            case 0: {
                const patternInput_7_8 = child_12.UpdateInbound(msg_2_22, currentModel_5_3.NodeModel, childModel_2_3);
                patternInput_8_4 = [patternInput_7_8[0], patternInput_7_8[1]];
                break;
            }
            case 1: {
                patternInput_8_4 = [model_3_16.MaybeChildModel, Cmd_none_1()];
                break;
            }
        }
        return [new Internal_BranchModelChild$2(patternInput_6_8[0], patternInput_8_4[0]), Cmd_batch_1(ofArray([Cmd_map((arg0_14_3) => (new Internal_BranchMsgChild$2(0, arg0_14_3)), patternInput_6_8[1]), Cmd_map((arg0_15_3) => (new Internal_BranchMsgChild$2(1, arg0_15_3)), patternInput_8_4[1])]))];
    }, current_61.LastKey, Internal_combineChildIndexPathFromKeyPathMap(current_61.LastKey, child_12.IndexPathFromKeyPath, current_61.IndexPathFromKeyPath)))))))), (config_26 = defaultConfig(), siblingBuilder_18((keepCachedChildren_9 = config_26.KeepCachedChildren, (child_16 = child_13, (current_66 = ((config_27 = config_26, (maybeAutoMessage_13 = config_27.MaybeSendMessageOnNavigation, (key_56 = "RestrictedModal", (current_65 = current_5, new Internal_StateTreeRoot$6((_arg2_14, deps_24, maybeCachedModel_22, _arg1_15) => {
        const matchValue_25 = [maybeCachedModel_22, config_27.ReInitOnNavigation, maybeAutoMessage_13];
        let pattern_matching_result_47, model_26, model_1_23, msg_56;
        if (matchValue_25[0] != null) {
            if (matchValue_25[1]) {
                pattern_matching_result_47 = 2;
            }
            else if (matchValue_25[2] != null) {
                pattern_matching_result_47 = 1;
                model_1_23 = matchValue_25[0];
                msg_56 = matchValue_25[2];
            }
            else {
                pattern_matching_result_47 = 0;
                model_26 = matchValue_25[0];
            }
        }
        else {
            pattern_matching_result_47 = 2;
        }
        switch (pattern_matching_result_47) {
            case 0: {
                return [model_26, Cmd_none_1()];
            }
            case 1: {
                return [model_1_23, Cmd_OfFunc_result(msg_56)];
            }
            case 2: {
                const patternInput_30 = current_65.Init(deps_24);
                return [new Internal_StateTreeModel$1(patternInput_30[0], false), Cmd_batch_1(toList(delay(() => append(singleton_1(patternInput_30[1]), delay(() => ofArray(toArray(map(Cmd_OfFunc_result, maybeAutoMessage_13))))))))];
            }
        }
    }, (_arg3_13, dispatch_22, model_2_23) => current_65.View(model_2_23.NodeModel, dispatch_22), (_arg4_13, msg_3_22, pass_22, deps_1_22, model_3_17) => {
        switch (msg_3_22.tag) {
            case 1: {
                console.error(some(`State tree error: ${msg_3_22.fields[0]}`));
                return [model_3_17, Cmd_none_1(), void 0, void 0];
            }
            case 2: {
                return [new Internal_StateTreeModel$1(model_3_17.NodeModel, true), Cmd_none_1(), void 0, void 0];
            }
            case 3: {
                return [model_3_17, Cmd_none_1(), void 0, msg_3_22.fields[0]];
            }
            case 4: {
                return [model_3_17, Cmd_none_1(), msg_3_22.fields[0], void 0];
            }
            default: {
                const patternInput_1_22 = current_65.Update(msg_3_22.fields[0], pass_22, deps_1_22, model_3_17.NodeModel);
                return [new Internal_StateTreeModel$1(patternInput_1_22[0], model_3_17.Initialized), patternInput_1_22[1], void 0, void 0];
            }
        }
    }, (msg_4_17, deps_2_22, model_4_13) => {
        const patternInput_2_22 = config_27.UpdateInbound(msg_4_17, deps_2_22, model_4_13.NodeModel);
        return [new Internal_StateTreeModel$1(patternInput_2_22[0], model_4_13.Initialized), patternInput_2_22[1]];
    }, key_56, ofList(singleton([singleton(key_56), singleton(0)])))))))), new Internal_StateTreeRoot$6((indexPath_11, deps_25, maybeCachedModel_23, _arg1_16) => {
        const maybeCachedCurrentModel_9 = bind((cachedModel_10) => cachedModel_10.MaybeCurrentModel, maybeCachedModel_23);
        const maybeCachedChildModel_4 = bind((cachedModel_1_9) => cachedModel_1_9.MaybeChildModel, maybeCachedModel_23);
        const matchValue_26 = [indexPath_11, maybeCachedCurrentModel_9];
        let pattern_matching_result_48, currentModel_1_9, xs_15;
        if (!isEmpty(matchValue_26[0])) {
            if (head(matchValue_26[0]) === 0) {
                if (isEmpty(tail(matchValue_26[0]))) {
                    pattern_matching_result_48 = 0;
                }
                else if (matchValue_26[1] != null) {
                    pattern_matching_result_48 = 1;
                    currentModel_1_9 = matchValue_26[1];
                    xs_15 = tail(matchValue_26[0]);
                }
                else {
                    pattern_matching_result_48 = 0;
                }
            }
            else {
                pattern_matching_result_48 = 2;
            }
        }
        else {
            pattern_matching_result_48 = 2;
        }
        switch (pattern_matching_result_48) {
            case 0: {
                const patternInput_31 = current_66.Init(indexPath_11, deps_25, maybeCachedCurrentModel_9, false);
                return [new Internal_BranchModelChild$2(patternInput_31[0], keepCachedChildren_9 ? maybeCachedChildModel_4 : (void 0)), Cmd_map((arg0_28) => (new Internal_BranchMsgChild$2(0, arg0_28)), patternInput_31[1])];
            }
            case 1: {
                const patternInput_1_23 = child_16.Init(xs_15, currentModel_1_9.NodeModel, maybeCachedChildModel_4, keepCachedChildren_9);
                return [new Internal_BranchModelChild$2(currentModel_1_9, patternInput_1_23[0]), Cmd_map((arg0_2_10) => (new Internal_BranchMsgChild$2(1, arg0_2_10)), patternInput_1_23[1])];
            }
            case 2: {
                console.error(some("Failed to initialize state, invalid index"));
                return [defaultArg(maybeCachedModel_23, new Internal_BranchModelChild$2(void 0, void 0)), Cmd_none_1()];
            }
        }
    }, (indexPath_1_9, dispatch_23, model_27) => {
        let pattern_matching_result_49, xs_1_9;
        if (!isEmpty(indexPath_1_9)) {
            if (head(indexPath_1_9) === 0) {
                if (isEmpty(tail(indexPath_1_9))) {
                    pattern_matching_result_49 = 0;
                }
                else {
                    pattern_matching_result_49 = 1;
                    xs_1_9 = tail(indexPath_1_9);
                }
            }
            else {
                pattern_matching_result_49 = 2;
            }
        }
        else {
            pattern_matching_result_49 = 2;
        }
        switch (pattern_matching_result_49) {
            case 0: {
                return defaultArg(map(partialApply(1, current_66.View, [indexPath_1_9, (arg_31) => {
                    dispatch_23(new Internal_BranchMsgChild$2(0, arg_31));
                }]), model_27.MaybeCurrentModel), "Loading...");
            }
            case 1: {
                const children_8 = toList(delay(() => append(config_26.VisibleToChildren ? singleton_1(defaultArg(map(partialApply(1, current_66.View, [indexPath_1_9, (arg_32) => {
                    dispatch_23(new Internal_BranchMsgChild$2(0, arg_32));
                }]), model_27.MaybeCurrentModel), "Loading...")) : empty_1(), delay(() => singleton_1(defaultArg(map(partialApply(1, child_16.View, [xs_1_9, (arg_33) => {
                    dispatch_23(new Internal_BranchMsgChild$2(1, arg_33));
                }]), model_27.MaybeChildModel), "Loading..."))))));
                return react.createElement("div", {}, ...children_8);
            }
            case 2: {
                return "Woops, looks like you took a wrong turn...";
            }
        }
    }, (indexPath_2_9, branchMsg_9, pass_23, deps_1_23, model_1_24) => {
        let newNodeModel_4;
        const matchValue_1_10 = [branchMsg_9, indexPath_2_9];
        let pattern_matching_result_50, msg_59, xs_2_9, msg_1_24, xs_3_9;
        if (matchValue_1_10[0].tag === 1) {
            if (!isEmpty(matchValue_1_10[1])) {
                if (head(matchValue_1_10[1]) === 0) {
                    pattern_matching_result_50 = 1;
                    msg_1_24 = matchValue_1_10[0].fields[0];
                    xs_3_9 = tail(matchValue_1_10[1]);
                }
                else {
                    pattern_matching_result_50 = 2;
                }
            }
            else {
                pattern_matching_result_50 = 2;
            }
        }
        else if (!isEmpty(matchValue_1_10[1])) {
            if (head(matchValue_1_10[1]) === 0) {
                pattern_matching_result_50 = 0;
                msg_59 = matchValue_1_10[0].fields[0];
                xs_2_9 = tail(matchValue_1_10[1]);
            }
            else {
                pattern_matching_result_50 = 2;
            }
        }
        else {
            pattern_matching_result_50 = 2;
        }
        switch (pattern_matching_result_50) {
            case 0: {
                const matchValue_2_9 = model_1_24.MaybeCurrentModel;
                if (matchValue_2_9 == null) {
                    console.error(some("Failed to update state, not initialized"));
                    return [model_1_24, Cmd_none_1(), void 0, void 0];
                }
                else {
                    const currentModel_2_9 = matchValue_2_9;
                    const patternInput_2_23 = current_66.Update(indexPath_2_9, msg_59, pass_23, deps_1_23, currentModel_2_9);
                    const newCurrentModel_10 = patternInput_2_23[0];
                    const maybeOutboundMsg_10 = patternInput_2_23[3];
                    const maybeNavMsg_10 = patternInput_2_23[2];
                    const currentCmd_1_9 = patternInput_2_23[1];
                    let pattern_matching_result_51;
                    if (msg_59.tag === 2) {
                        if (!equals(xs_2_9, empty())) {
                            pattern_matching_result_51 = 0;
                        }
                        else {
                            pattern_matching_result_51 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_51 = 1;
                    }
                    switch (pattern_matching_result_51) {
                        case 0: {
                            const patternInput_3_10 = child_16.Init(xs_2_9, currentModel_2_9.NodeModel, model_1_24.MaybeChildModel, false);
                            return [new Internal_BranchModelChild$2(newCurrentModel_10, patternInput_3_10[0]), Cmd_batch_1(ofArray([Cmd_map((arg0_8_9) => (new Internal_BranchMsgChild$2(0, arg0_8_9)), currentCmd_1_9), Cmd_map((arg0_9_9) => (new Internal_BranchMsgChild$2(1, arg0_9_9)), patternInput_3_10[1])])), maybeNavMsg_10, maybeOutboundMsg_10];
                        }
                        case 1: {
                            return [new Internal_BranchModelChild$2(newCurrentModel_10, model_1_24.MaybeChildModel), Cmd_map((arg0_11_4) => (new Internal_BranchMsgChild$2(0, arg0_11_4)), currentCmd_1_9), maybeNavMsg_10, maybeOutboundMsg_10];
                        }
                    }
                }
            }
            case 1: {
                const matchValue_3_9 = [model_1_24.MaybeCurrentModel, model_1_24.MaybeChildModel];
                let pattern_matching_result_52, childModel_1_4, currentModel_3_5;
                if (matchValue_3_9[0] != null) {
                    if (matchValue_3_9[1] != null) {
                        pattern_matching_result_52 = 0;
                        childModel_1_4 = matchValue_3_9[1];
                        currentModel_3_5 = matchValue_3_9[0];
                    }
                    else {
                        pattern_matching_result_52 = 1;
                    }
                }
                else {
                    pattern_matching_result_52 = 1;
                }
                switch (pattern_matching_result_52) {
                    case 0: {
                        const patternInput_4_9 = child_16.Update(xs_3_9, msg_1_24, pass_23, currentModel_3_5.NodeModel, childModel_1_4);
                        const maybeNavMsg_1_9 = patternInput_4_9[2];
                        return [new Internal_BranchModelChild$2((maybeNavMsg_1_9 != null) ? ((maybeNavMsg_1_9.tag === 2) ? ((newNodeModel_4 = maybeNavMsg_1_9.fields[0], map((model_2_24) => (new Internal_StateTreeModel$1(newNodeModel_4, model_2_24.Initialized)), model_1_24.MaybeCurrentModel))) : model_1_24.MaybeCurrentModel) : model_1_24.MaybeCurrentModel, patternInput_4_9[0]), Cmd_map((arg0_13_4) => (new Internal_BranchMsgChild$2(1, arg0_13_4)), patternInput_4_9[1]), map(NavigationMsg$1_destructiveCast_Z33BEA92B, maybeNavMsg_1_9), patternInput_4_9[3]];
                    }
                    case 1: {
                        console.error(some("Failed to update state, parent not initialized"));
                        return [model_1_24, Cmd_none_1(), void 0, void 0];
                    }
                }
            }
            case 2: {
                console.warn(some("Got an update for a node that shouldn\u0027t be initialized, ignoring"));
                return [model_1_24, Cmd_none_1(), void 0, void 0];
            }
        }
    }, (msg_2_24, deps_2_23, model_3_18) => {
        let patternInput_6_9;
        const matchValue_4_9 = model_3_18.MaybeCurrentModel;
        if (matchValue_4_9 == null) {
            patternInput_6_9 = [model_3_18.MaybeCurrentModel, Cmd_none_1()];
        }
        else {
            const patternInput_5_9 = current_66.UpdateInbound(msg_2_24, deps_2_23, matchValue_4_9);
            patternInput_6_9 = [patternInput_5_9[0], patternInput_5_9[1]];
        }
        let patternInput_8_5;
        const matchValue_5_5 = [model_3_18.MaybeChildModel, model_3_18.MaybeCurrentModel];
        let pattern_matching_result_53, childModel_2_4, currentModel_5_4;
        if (matchValue_5_5[0] != null) {
            if (matchValue_5_5[1] != null) {
                pattern_matching_result_53 = 0;
                childModel_2_4 = matchValue_5_5[0];
                currentModel_5_4 = matchValue_5_5[1];
            }
            else {
                pattern_matching_result_53 = 1;
            }
        }
        else {
            pattern_matching_result_53 = 1;
        }
        switch (pattern_matching_result_53) {
            case 0: {
                const patternInput_7_9 = child_16.UpdateInbound(msg_2_24, currentModel_5_4.NodeModel, childModel_2_4);
                patternInput_8_5 = [patternInput_7_9[0], patternInput_7_9[1]];
                break;
            }
            case 1: {
                patternInput_8_5 = [model_3_18.MaybeChildModel, Cmd_none_1()];
                break;
            }
        }
        return [new Internal_BranchModelChild$2(patternInput_6_9[0], patternInput_8_5[0]), Cmd_batch_1(ofArray([Cmd_map((arg0_14_4) => (new Internal_BranchMsgChild$2(0, arg0_14_4)), patternInput_6_9[1]), Cmd_map((arg0_15_4) => (new Internal_BranchMsgChild$2(1, arg0_15_4)), patternInput_8_5[1])]))];
    }, current_66.LastKey, Internal_combineChildIndexPathFromKeyPathMap(current_66.LastKey, child_16.IndexPathFromKeyPath, current_66.IndexPathFromKeyPath))))))))))), (config_28 = defaultConfig(), (keepCachedChildren_11 = config_28.KeepCachedChildren, (child_19 = child_17, (current_71 = ((config_29 = config_28, (maybeAutoMessage_14 = config_29.MaybeSendMessageOnNavigation, (key_60 = "SearchForm", (current_70 = current_67, new Internal_StateTreeRoot$6((_arg2_15, deps_26, maybeCachedModel_24, _arg1_17) => {
        const matchValue_27 = [maybeCachedModel_24, config_29.ReInitOnNavigation, maybeAutoMessage_14];
        let pattern_matching_result_54, model_28, model_1_25, msg_60;
        if (matchValue_27[0] != null) {
            if (matchValue_27[1]) {
                pattern_matching_result_54 = 2;
            }
            else if (matchValue_27[2] != null) {
                pattern_matching_result_54 = 1;
                model_1_25 = matchValue_27[0];
                msg_60 = matchValue_27[2];
            }
            else {
                pattern_matching_result_54 = 0;
                model_28 = matchValue_27[0];
            }
        }
        else {
            pattern_matching_result_54 = 2;
        }
        switch (pattern_matching_result_54) {
            case 0: {
                return [model_28, Cmd_none_1()];
            }
            case 1: {
                return [model_1_25, Cmd_OfFunc_result(msg_60)];
            }
            case 2: {
                const patternInput_32 = current_70.Init(deps_26);
                return [new Internal_StateTreeModel$1(patternInput_32[0], false), Cmd_batch_1(toList(delay(() => append(singleton_1(patternInput_32[1]), delay(() => ofArray(toArray(map(Cmd_OfFunc_result, maybeAutoMessage_14))))))))];
            }
        }
    }, (_arg3_14, dispatch_24, model_2_25) => current_70.View(model_2_25.NodeModel, dispatch_24), (_arg4_14, msg_3_23, pass_24, deps_1_24, model_3_19) => {
        switch (msg_3_23.tag) {
            case 1: {
                console.error(some(`State tree error: ${msg_3_23.fields[0]}`));
                return [model_3_19, Cmd_none_1(), void 0, void 0];
            }
            case 2: {
                return [new Internal_StateTreeModel$1(model_3_19.NodeModel, true), Cmd_none_1(), void 0, void 0];
            }
            case 3: {
                return [model_3_19, Cmd_none_1(), void 0, msg_3_23.fields[0]];
            }
            case 4: {
                return [model_3_19, Cmd_none_1(), msg_3_23.fields[0], void 0];
            }
            default: {
                const patternInput_1_24 = current_70.Update(msg_3_23.fields[0], pass_24, deps_1_24, model_3_19.NodeModel);
                return [new Internal_StateTreeModel$1(patternInput_1_24[0], model_3_19.Initialized), patternInput_1_24[1], void 0, void 0];
            }
        }
    }, (msg_4_18, deps_2_24, model_4_14) => {
        const patternInput_2_24 = config_29.UpdateInbound(msg_4_18, deps_2_24, model_4_14.NodeModel);
        return [new Internal_StateTreeModel$1(patternInput_2_24[0], model_4_14.Initialized), patternInput_2_24[1]];
    }, key_60, ofList(singleton([singleton(key_60), singleton(0)])))))))), new Internal_StateTreeRoot$6((indexPath_12, deps_27, maybeCachedModel_25, _arg1_18) => {
        const maybeCachedCurrentModel_10 = bind((cachedModel_11) => cachedModel_11.MaybeCurrentModel, maybeCachedModel_25);
        const maybeCachedChildModel_5 = bind((cachedModel_1_10) => cachedModel_1_10.MaybeChildModel, maybeCachedModel_25);
        const matchValue_28 = [indexPath_12, maybeCachedCurrentModel_10];
        let pattern_matching_result_55, currentModel_1_10, xs_16;
        if (!isEmpty(matchValue_28[0])) {
            if (head(matchValue_28[0]) === 0) {
                if (isEmpty(tail(matchValue_28[0]))) {
                    pattern_matching_result_55 = 0;
                }
                else if (matchValue_28[1] != null) {
                    pattern_matching_result_55 = 1;
                    currentModel_1_10 = matchValue_28[1];
                    xs_16 = tail(matchValue_28[0]);
                }
                else {
                    pattern_matching_result_55 = 0;
                }
            }
            else {
                pattern_matching_result_55 = 2;
            }
        }
        else {
            pattern_matching_result_55 = 2;
        }
        switch (pattern_matching_result_55) {
            case 0: {
                const patternInput_33 = current_71.Init(indexPath_12, deps_27, maybeCachedCurrentModel_10, false);
                return [new Internal_BranchModelChild$2(patternInput_33[0], keepCachedChildren_11 ? maybeCachedChildModel_5 : (void 0)), Cmd_map((arg0_29) => (new Internal_BranchMsgChild$2(0, arg0_29)), patternInput_33[1])];
            }
            case 1: {
                const patternInput_1_25 = child_19.Init(xs_16, currentModel_1_10.NodeModel, maybeCachedChildModel_5, keepCachedChildren_11);
                return [new Internal_BranchModelChild$2(currentModel_1_10, patternInput_1_25[0]), Cmd_map((arg0_2_11) => (new Internal_BranchMsgChild$2(1, arg0_2_11)), patternInput_1_25[1])];
            }
            case 2: {
                console.error(some("Failed to initialize state, invalid index"));
                return [defaultArg(maybeCachedModel_25, new Internal_BranchModelChild$2(void 0, void 0)), Cmd_none_1()];
            }
        }
    }, (indexPath_1_10, dispatch_25, model_29) => {
        let pattern_matching_result_56, xs_1_10;
        if (!isEmpty(indexPath_1_10)) {
            if (head(indexPath_1_10) === 0) {
                if (isEmpty(tail(indexPath_1_10))) {
                    pattern_matching_result_56 = 0;
                }
                else {
                    pattern_matching_result_56 = 1;
                    xs_1_10 = tail(indexPath_1_10);
                }
            }
            else {
                pattern_matching_result_56 = 2;
            }
        }
        else {
            pattern_matching_result_56 = 2;
        }
        switch (pattern_matching_result_56) {
            case 0: {
                return defaultArg(map(partialApply(1, current_71.View, [indexPath_1_10, (arg_34) => {
                    dispatch_25(new Internal_BranchMsgChild$2(0, arg_34));
                }]), model_29.MaybeCurrentModel), "Loading...");
            }
            case 1: {
                const children_10 = toList(delay(() => append(config_28.VisibleToChildren ? singleton_1(defaultArg(map(partialApply(1, current_71.View, [indexPath_1_10, (arg_35) => {
                    dispatch_25(new Internal_BranchMsgChild$2(0, arg_35));
                }]), model_29.MaybeCurrentModel), "Loading...")) : empty_1(), delay(() => singleton_1(defaultArg(map(partialApply(1, child_19.View, [xs_1_10, (arg_36) => {
                    dispatch_25(new Internal_BranchMsgChild$2(1, arg_36));
                }]), model_29.MaybeChildModel), "Loading..."))))));
                return react.createElement("div", {}, ...children_10);
            }
            case 2: {
                return "Woops, looks like you took a wrong turn...";
            }
        }
    }, (indexPath_2_10, branchMsg_10, pass_25, deps_1_25, model_1_26) => {
        let newNodeModel_5;
        const matchValue_1_11 = [branchMsg_10, indexPath_2_10];
        let pattern_matching_result_57, msg_63, xs_2_10, msg_1_26, xs_3_10;
        if (matchValue_1_11[0].tag === 1) {
            if (!isEmpty(matchValue_1_11[1])) {
                if (head(matchValue_1_11[1]) === 0) {
                    pattern_matching_result_57 = 1;
                    msg_1_26 = matchValue_1_11[0].fields[0];
                    xs_3_10 = tail(matchValue_1_11[1]);
                }
                else {
                    pattern_matching_result_57 = 2;
                }
            }
            else {
                pattern_matching_result_57 = 2;
            }
        }
        else if (!isEmpty(matchValue_1_11[1])) {
            if (head(matchValue_1_11[1]) === 0) {
                pattern_matching_result_57 = 0;
                msg_63 = matchValue_1_11[0].fields[0];
                xs_2_10 = tail(matchValue_1_11[1]);
            }
            else {
                pattern_matching_result_57 = 2;
            }
        }
        else {
            pattern_matching_result_57 = 2;
        }
        switch (pattern_matching_result_57) {
            case 0: {
                const matchValue_2_10 = model_1_26.MaybeCurrentModel;
                if (matchValue_2_10 == null) {
                    console.error(some("Failed to update state, not initialized"));
                    return [model_1_26, Cmd_none_1(), void 0, void 0];
                }
                else {
                    const currentModel_2_10 = matchValue_2_10;
                    const patternInput_2_25 = current_71.Update(indexPath_2_10, msg_63, pass_25, deps_1_25, currentModel_2_10);
                    const newCurrentModel_11 = patternInput_2_25[0];
                    const maybeOutboundMsg_11 = patternInput_2_25[3];
                    const maybeNavMsg_11 = patternInput_2_25[2];
                    const currentCmd_1_10 = patternInput_2_25[1];
                    let pattern_matching_result_58;
                    if (msg_63.tag === 2) {
                        if (!equals(xs_2_10, empty())) {
                            pattern_matching_result_58 = 0;
                        }
                        else {
                            pattern_matching_result_58 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_58 = 1;
                    }
                    switch (pattern_matching_result_58) {
                        case 0: {
                            const patternInput_3_11 = child_19.Init(xs_2_10, currentModel_2_10.NodeModel, model_1_26.MaybeChildModel, false);
                            return [new Internal_BranchModelChild$2(newCurrentModel_11, patternInput_3_11[0]), Cmd_batch_1(ofArray([Cmd_map((arg0_8_10) => (new Internal_BranchMsgChild$2(0, arg0_8_10)), currentCmd_1_10), Cmd_map((arg0_9_10) => (new Internal_BranchMsgChild$2(1, arg0_9_10)), patternInput_3_11[1])])), maybeNavMsg_11, maybeOutboundMsg_11];
                        }
                        case 1: {
                            return [new Internal_BranchModelChild$2(newCurrentModel_11, model_1_26.MaybeChildModel), Cmd_map((arg0_11_5) => (new Internal_BranchMsgChild$2(0, arg0_11_5)), currentCmd_1_10), maybeNavMsg_11, maybeOutboundMsg_11];
                        }
                    }
                }
            }
            case 1: {
                const matchValue_3_10 = [model_1_26.MaybeCurrentModel, model_1_26.MaybeChildModel];
                let pattern_matching_result_59, childModel_1_5, currentModel_3_6;
                if (matchValue_3_10[0] != null) {
                    if (matchValue_3_10[1] != null) {
                        pattern_matching_result_59 = 0;
                        childModel_1_5 = matchValue_3_10[1];
                        currentModel_3_6 = matchValue_3_10[0];
                    }
                    else {
                        pattern_matching_result_59 = 1;
                    }
                }
                else {
                    pattern_matching_result_59 = 1;
                }
                switch (pattern_matching_result_59) {
                    case 0: {
                        const patternInput_4_10 = child_19.Update(xs_3_10, msg_1_26, pass_25, currentModel_3_6.NodeModel, childModel_1_5);
                        const maybeNavMsg_1_10 = patternInput_4_10[2];
                        return [new Internal_BranchModelChild$2((maybeNavMsg_1_10 != null) ? ((maybeNavMsg_1_10.tag === 2) ? ((newNodeModel_5 = maybeNavMsg_1_10.fields[0], map((model_2_26) => (new Internal_StateTreeModel$1(newNodeModel_5, model_2_26.Initialized)), model_1_26.MaybeCurrentModel))) : model_1_26.MaybeCurrentModel) : model_1_26.MaybeCurrentModel, patternInput_4_10[0]), Cmd_map((arg0_13_5) => (new Internal_BranchMsgChild$2(1, arg0_13_5)), patternInput_4_10[1]), map(NavigationMsg$1_destructiveCast_Z33BEA92B, maybeNavMsg_1_10), patternInput_4_10[3]];
                    }
                    case 1: {
                        console.error(some("Failed to update state, parent not initialized"));
                        return [model_1_26, Cmd_none_1(), void 0, void 0];
                    }
                }
            }
            case 2: {
                console.warn(some("Got an update for a node that shouldn\u0027t be initialized, ignoring"));
                return [model_1_26, Cmd_none_1(), void 0, void 0];
            }
        }
    }, (msg_2_26, deps_2_25, model_3_20) => {
        let patternInput_6_10;
        const matchValue_4_10 = model_3_20.MaybeCurrentModel;
        if (matchValue_4_10 == null) {
            patternInput_6_10 = [model_3_20.MaybeCurrentModel, Cmd_none_1()];
        }
        else {
            const patternInput_5_10 = current_71.UpdateInbound(msg_2_26, deps_2_25, matchValue_4_10);
            patternInput_6_10 = [patternInput_5_10[0], patternInput_5_10[1]];
        }
        let patternInput_8_6;
        const matchValue_5_6 = [model_3_20.MaybeChildModel, model_3_20.MaybeCurrentModel];
        let pattern_matching_result_60, childModel_2_5, currentModel_5_5;
        if (matchValue_5_6[0] != null) {
            if (matchValue_5_6[1] != null) {
                pattern_matching_result_60 = 0;
                childModel_2_5 = matchValue_5_6[0];
                currentModel_5_5 = matchValue_5_6[1];
            }
            else {
                pattern_matching_result_60 = 1;
            }
        }
        else {
            pattern_matching_result_60 = 1;
        }
        switch (pattern_matching_result_60) {
            case 0: {
                const patternInput_7_10 = child_19.UpdateInbound(msg_2_26, currentModel_5_5.NodeModel, childModel_2_5);
                patternInput_8_6 = [patternInput_7_10[0], patternInput_7_10[1]];
                break;
            }
            case 1: {
                patternInput_8_6 = [model_3_20.MaybeChildModel, Cmd_none_1()];
                break;
            }
        }
        return [new Internal_BranchModelChild$2(patternInput_6_10[0], patternInput_8_6[0]), Cmd_batch_1(ofArray([Cmd_map((arg0_14_5) => (new Internal_BranchMsgChild$2(0, arg0_14_5)), patternInput_6_10[1]), Cmd_map((arg0_15_5) => (new Internal_BranchMsgChild$2(1, arg0_15_5)), patternInput_8_6[1])]))];
    }, current_71.LastKey, Internal_combineChildIndexPathFromKeyPathMap(current_71.LastKey, child_19.IndexPathFromKeyPath, current_71.IndexPathFromKeyPath)))))))));
}

