import { Record } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { record_type, list_type, string_type, option_type, class_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";
import { empty } from "../../fable_modules/fable-library.3.7.12/List.js";

export class ScreenshotInfoViewModel extends Record {
    constructor(screenshotId, sessionId, name, description, s3Keys) {
        super();
        this.screenshotId = screenshotId;
        this.sessionId = sessionId;
        this.name = name;
        this.description = description;
        this.s3Keys = s3Keys;
    }
}

export function ScreenshotInfoViewModel$reflection() {
    return record_type("Optiscan.Models.View.ScreenshotInfoViewModel.ScreenshotInfoViewModel", [], ScreenshotInfoViewModel, () => [["screenshotId", option_type(class_type("System.Guid"))], ["sessionId", option_type(class_type("System.Guid"))], ["name", option_type(string_type)], ["description", option_type(string_type)], ["s3Keys", list_type(string_type)]]);
}

export function ScreenshotInfoViewModel_get_empty() {
    return new ScreenshotInfoViewModel(void 0, void 0, void 0, void 0, empty());
}

export function ScreenshotInfoViewModel_get_name_() {
    return [(m) => m.name, (v) => ((m_1) => (new ScreenshotInfoViewModel(m_1.screenshotId, m_1.sessionId, v, m_1.description, m_1.s3Keys)))];
}

export function ScreenshotInfoViewModel_get_description_() {
    return [(m) => m.description, (v) => ((m_1) => (new ScreenshotInfoViewModel(m_1.screenshotId, m_1.sessionId, m_1.name, v, m_1.s3Keys)))];
}

export function ScreenshotInfoViewModel_get_s3Key_() {
    return [(m) => m.s3Keys, (v) => ((m_1) => (new ScreenshotInfoViewModel(m_1.screenshotId, m_1.sessionId, m_1.name, m_1.description, v)))];
}

