import { Record, Union } from "../fable_modules/fable-library.3.7.12/Types.js";
import { tuple_type, lambda_type, class_type, bool_type, list_type, option_type, record_type, string_type, union_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { ofArray } from "../fable_modules/fable-library.3.7.12/List.js";
import { SiteViewModel$reflection } from "../Optiscan.Models/View/Site.js";
import { PatientViewModel$reflection } from "../Optiscan.Models/View/Patient.js";
import { SessionViewModel$reflection } from "../Optiscan.Models/View/Session.js";
import { ListDisplayModification_ListDisplayModification$2$reflection, Sort_SortInfo$1$reflection } from "../Optiscan.SharedUI/Sort.js";
import { CrudListModel$1$reflection, CrudListModification$reflection } from "../Optiscan.SharedUI/Crud.js";
import { PatientNoteViewModel$reflection } from "../Optiscan.Models/View/PatientNote.js";
import { AuthorizedUserInformation$reflection } from "../Optiscan.Models/View/AuthorizedUserInformation.js";
import { ErrorMessage$reflection } from "../Optiscan.Models/ErrorMessage.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.12/Choice.js";
import { KvsConfiguration$reflection, Notification$reflection } from "../Optiscan.Models/Api/Api.js";
import { StateTree$3$reflection, ErasedModel$reflection, ErasedMsg$2$reflection } from "../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { OptiscanUserData$reflection, UserRole$reflection } from "../Optiscan.Models/Security.js";
import { Security_UserInfo$2$reflection } from "../fable_modules/Webbler.Models.1.2.6/Api.fs.js";
import { S3Context$reflection } from "../Common/AwsCommon.js";

export class SearchType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["PatientInfo", "PatientId"];
    }
}

export function SearchType$reflection() {
    return union_type("Client.Patients.PatientSearch.Types.SearchType", [], SearchType, () => [[], []]);
}

export function SearchType_get_all() {
    return ofArray([new SearchType(0), new SearchType(1)]);
}

export function SearchType_get_describe() {
    return (_arg1) => ((_arg1.tag === 1) ? "Patient ID" : "Patient Info");
}

export class PatientInfoModel extends Record {
    constructor(firstName, lastName) {
        super();
        this.firstName = firstName;
        this.lastName = lastName;
    }
}

export function PatientInfoModel$reflection() {
    return record_type("Client.Patients.PatientSearch.Types.PatientInfoModel", [], PatientInfoModel, () => [["firstName", string_type], ["lastName", string_type]]);
}

export function PatientInfoModel_get_firstName_() {
    return [(model) => model.firstName, (firstName) => ((model_1) => (new PatientInfoModel(firstName, model_1.lastName)))];
}

export function PatientInfoModel_get_lastName_() {
    return [(model) => model.lastName, (lastName) => ((model_1) => (new PatientInfoModel(model_1.firstName, lastName)))];
}

export function PatientInfoModel_get_empty() {
    return new PatientInfoModel("", "");
}

export class SearchModel extends Record {
    constructor(searchType, patientInfo, patientId, selectedSite, patient, sites, authorizedSites, loadingSites, loadingAuthorizedSites, maybeRedirectPatientId, maybeRedirectSessionId, searchFailedMsg) {
        super();
        this.searchType = searchType;
        this.patientInfo = patientInfo;
        this.patientId = patientId;
        this.selectedSite = selectedSite;
        this.patient = patient;
        this.sites = sites;
        this.authorizedSites = authorizedSites;
        this.loadingSites = loadingSites;
        this.loadingAuthorizedSites = loadingAuthorizedSites;
        this.maybeRedirectPatientId = maybeRedirectPatientId;
        this.maybeRedirectSessionId = maybeRedirectSessionId;
        this.searchFailedMsg = searchFailedMsg;
    }
}

export function SearchModel$reflection() {
    return record_type("Client.Patients.PatientSearch.Types.SearchModel", [], SearchModel, () => [["searchType", option_type(SearchType$reflection())], ["patientInfo", PatientInfoModel$reflection()], ["patientId", string_type], ["selectedSite", option_type(SiteViewModel$reflection())], ["patient", PatientViewModel$reflection()], ["sites", list_type(SiteViewModel$reflection())], ["authorizedSites", list_type(SiteViewModel$reflection())], ["loadingSites", bool_type], ["loadingAuthorizedSites", bool_type], ["maybeRedirectPatientId", option_type(class_type("System.Guid"))], ["maybeRedirectSessionId", option_type(class_type("System.Guid"))], ["searchFailedMsg", string_type]]);
}

export function SearchModel_get_patientInfo_() {
    return [(m) => m.patientInfo, (v) => ((m_1) => (new SearchModel(m_1.searchType, v, m_1.patientId, m_1.selectedSite, m_1.patient, m_1.sites, m_1.authorizedSites, m_1.loadingSites, m_1.loadingAuthorizedSites, m_1.maybeRedirectPatientId, m_1.maybeRedirectSessionId, m_1.searchFailedMsg)))];
}

export function SearchModel_get_patientId_() {
    return [(m) => m.patientId, (v) => ((m_1) => (new SearchModel(m_1.searchType, m_1.patientInfo, v, m_1.selectedSite, m_1.patient, m_1.sites, m_1.authorizedSites, m_1.loadingSites, m_1.loadingAuthorizedSites, m_1.maybeRedirectPatientId, m_1.maybeRedirectSessionId, m_1.searchFailedMsg)))];
}

export function SearchModel_get_selectedSite_() {
    return [(m) => m.selectedSite, (v) => ((m_1) => (new SearchModel(m_1.searchType, m_1.patientInfo, m_1.patientId, v, m_1.patient, m_1.sites, m_1.authorizedSites, m_1.loadingSites, m_1.loadingAuthorizedSites, m_1.maybeRedirectPatientId, m_1.maybeRedirectSessionId, m_1.searchFailedMsg)))];
}

export function SearchModel_get_searchType_() {
    return [(m) => m.searchType, (v) => ((m_1) => (new SearchModel(v, m_1.patientInfo, m_1.patientId, m_1.selectedSite, m_1.patient, m_1.sites, m_1.authorizedSites, m_1.loadingSites, m_1.loadingAuthorizedSites, m_1.maybeRedirectPatientId, m_1.maybeRedirectSessionId, m_1.searchFailedMsg)))];
}

export function SearchModel_get_patient_() {
    return [(m) => m.patient, (v) => ((m_1) => (new SearchModel(m_1.searchType, m_1.patientInfo, m_1.patientId, m_1.selectedSite, v, m_1.sites, m_1.authorizedSites, m_1.loadingSites, m_1.loadingAuthorizedSites, m_1.maybeRedirectPatientId, m_1.maybeRedirectSessionId, m_1.searchFailedMsg)))];
}

export class SearchFailedModal extends Record {
    constructor(searchFailedMsg) {
        super();
        this.searchFailedMsg = searchFailedMsg;
    }
}

export function SearchFailedModal$reflection() {
    return record_type("Client.Patients.PatientSearch.Types.SearchFailedModal", [], SearchFailedModal, () => [["searchFailedMsg", string_type]]);
}

export class RestrictedSearchModel extends Record {
    constructor(accessRequested, patient, maybeRedirectPatientId, maybeRedirectSessionId) {
        super();
        this.accessRequested = accessRequested;
        this.patient = patient;
        this.maybeRedirectPatientId = maybeRedirectPatientId;
        this.maybeRedirectSessionId = maybeRedirectSessionId;
    }
}

export function RestrictedSearchModel$reflection() {
    return record_type("Client.Patients.PatientSearch.Types.RestrictedSearchModel", [], RestrictedSearchModel, () => [["accessRequested", bool_type], ["patient", PatientViewModel$reflection()], ["maybeRedirectPatientId", option_type(class_type("System.Guid"))], ["maybeRedirectSessionId", option_type(class_type("System.Guid"))]]);
}

export class SearchResultModel extends Record {
    constructor(patient, accessRequested, sessions, activeSession, maybeSelectedSession, sessionScheduledListSortInfo, sessionCompletedListSortInfo, sessionScheduledListModifications, sessionCompletedListModifications, patientNotesCrudModel, maybeRedirectSessionId, authroizedUsers) {
        super();
        this.patient = patient;
        this.accessRequested = accessRequested;
        this.sessions = sessions;
        this.activeSession = activeSession;
        this.maybeSelectedSession = maybeSelectedSession;
        this.sessionScheduledListSortInfo = sessionScheduledListSortInfo;
        this.sessionCompletedListSortInfo = sessionCompletedListSortInfo;
        this.sessionScheduledListModifications = sessionScheduledListModifications;
        this.sessionCompletedListModifications = sessionCompletedListModifications;
        this.patientNotesCrudModel = patientNotesCrudModel;
        this.maybeRedirectSessionId = maybeRedirectSessionId;
        this.authroizedUsers = authroizedUsers;
    }
}

export function SearchResultModel$reflection() {
    return record_type("Client.Patients.PatientSearch.Types.SearchResultModel", [], SearchResultModel, () => [["patient", PatientViewModel$reflection()], ["accessRequested", bool_type], ["sessions", list_type(SessionViewModel$reflection())], ["activeSession", option_type(SessionViewModel$reflection())], ["maybeSelectedSession", option_type(SessionViewModel$reflection())], ["sessionScheduledListSortInfo", Sort_SortInfo$1$reflection(SessionViewModel$reflection())], ["sessionCompletedListSortInfo", Sort_SortInfo$1$reflection(SessionViewModel$reflection())], ["sessionScheduledListModifications", list_type(ListDisplayModification_ListDisplayModification$2$reflection(SessionViewModel$reflection(), CrudListModification$reflection()))], ["sessionCompletedListModifications", list_type(ListDisplayModification_ListDisplayModification$2$reflection(SessionViewModel$reflection(), CrudListModification$reflection()))], ["patientNotesCrudModel", CrudListModel$1$reflection(PatientNoteViewModel$reflection())], ["maybeRedirectSessionId", option_type(class_type("System.Guid"))], ["authroizedUsers", list_type(AuthorizedUserInformation$reflection())]]);
}

export function SearchResultModel_get_patientNotesCrudModel_() {
    return [(m) => m.patientNotesCrudModel, (v) => ((m_1) => (new SearchResultModel(m_1.patient, m_1.accessRequested, m_1.sessions, m_1.activeSession, m_1.maybeSelectedSession, m_1.sessionScheduledListSortInfo, m_1.sessionCompletedListSortInfo, m_1.sessionScheduledListModifications, m_1.sessionCompletedListModifications, v, m_1.maybeRedirectSessionId, m_1.authroizedUsers)))];
}

export function SearchResultModel_get_authroizedUsers_() {
    return [(m) => m.authroizedUsers, (v) => ((m_1) => (new SearchResultModel(m_1.patient, m_1.accessRequested, m_1.sessions, m_1.activeSession, m_1.maybeSelectedSession, m_1.sessionScheduledListSortInfo, m_1.sessionCompletedListSortInfo, m_1.sessionScheduledListModifications, m_1.sessionCompletedListModifications, m_1.patientNotesCrudModel, m_1.maybeRedirectSessionId, v)))];
}

export class PatientSearchMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Initialize", "SearchForPatient", "SearchForPatientIdResult", "SearchForPatientNameResult", "GetPatientResult", "SetSearchField", "ClearSearchFields", "GetSitesResult", "GetAuthorizedSites", "GetAuthorizedSitesResult"];
    }
}

export function PatientSearchMsg$reflection() {
    return union_type("Client.Patients.PatientSearch.Types.PatientSearchMsg", [], PatientSearchMsg, () => [[], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [option_type(PatientViewModel$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", option_type(PatientViewModel$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(PatientViewModel$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(PatientViewModel$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [PatientViewModel$reflection(), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", PatientViewModel$reflection()]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["setter", lambda_type(SearchModel$reflection(), SearchModel$reflection())]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(SiteViewModel$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(SiteViewModel$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["siteId", class_type("System.Guid")]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(SiteViewModel$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(SiteViewModel$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]]]);
}

export class RestrictedModalMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Cancel", "Confirm", "SetRestrictedSearchAccessRequested"];
    }
}

export function RestrictedModalMsg$reflection() {
    return union_type("Client.Patients.PatientSearch.Types.RestrictedModalMsg", [], RestrictedModalMsg, () => [[], [], [["Item", bool_type]]]);
}

export class PatientsInboundMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["NotesUpdated", "StreamEnded", "StreamStarted"];
    }
}

export function PatientsInboundMsg$reflection() {
    return union_type("Client.Patients.PatientSearch.Types.PatientsInboundMsg", [], PatientsInboundMsg, () => [[["patientId", class_type("System.Guid")]], [["deviceSerial", string_type]], [["deviceSerial", string_type]]]);
}

export class PatientsOutboundMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ChangeDefaultSidebarCollapseState"];
    }
}

export function PatientsOutboundMsg$reflection() {
    return union_type("Client.Patients.PatientSearch.Types.PatientsOutboundMsg", [], PatientsOutboundMsg, () => [[["defaultCollapsed", bool_type]]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ExceptionError", "Authenticated", "Notification", "PatientSearchTreeMsg"];
    }
}

export function Msg$reflection() {
    return union_type("Client.Patients.PatientSearch.Types.Msg", [], Msg, () => [[["Item", class_type("System.Exception")]], [], [["Item", Notification$reflection()]], [["Item", ErasedMsg$2$reflection(PatientsInboundMsg$reflection(), PatientsOutboundMsg$reflection())]]]);
}

export class Model extends Record {
    constructor(patientSearchModel, stateTree, maybeRedirectPatientId, maybeRedirectSessionId) {
        super();
        this.patientSearchModel = patientSearchModel;
        this.stateTree = stateTree;
        this.maybeRedirectPatientId = maybeRedirectPatientId;
        this.maybeRedirectSessionId = maybeRedirectSessionId;
    }
}

export function Model$reflection() {
    return record_type("Client.Patients.PatientSearch.Types.Model", [], Model, () => [["patientSearchModel", ErasedModel$reflection()], ["stateTree", option_type(StateTree$3$reflection(PatientsInboundMsg$reflection(), PatientsOutboundMsg$reflection(), tuple_type(string_type, Security_UserInfo$2$reflection(UserRole$reflection(), OptiscanUserData$reflection()), option_type(class_type("Fable.Import.Aws.Credentials.Credentials")), option_type(S3Context$reflection()), option_type(KvsConfiguration$reflection()))))], ["maybeRedirectPatientId", option_type(class_type("System.Guid"))], ["maybeRedirectSessionId", option_type(class_type("System.Guid"))]]);
}

