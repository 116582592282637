import { Record, Union } from "../fable_modules/fable-library.3.7.12/Types.js";
import { string_type, record_type, bool_type, list_type, class_type, union_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { choose, filter, reduce, head, tail, isEmpty, contains, exists, map, empty, tryFind, ofArray } from "../fable_modules/fable-library.3.7.12/List.js";
import { List_except } from "../fable_modules/fable-library.3.7.12/Seq2.js";
import { safeHash, equals } from "../fable_modules/fable-library.3.7.12/Util.js";
import { AssignedSiteId$reflection, AssignedSiteId } from "./Shared.js";
import { Optic_Get, Optic_Get_op_HatDot_21762A61 } from "../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { Security_UserViewModel$2_empty_1505 } from "../fable_modules/Webbler.Models.1.2.6/Api.fs.js";
import { tryFind as tryFind_1, ofList } from "../fable_modules/fable-library.3.7.12/Map.js";
import { map as map_1, defaultArg } from "../fable_modules/fable-library.3.7.12/Option.js";

export class UserRole extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["OptiscanAdmin", "AccountAdmin", "SiteAdmin", "ClinicalStaff"];
    }
    toString() {
        const x = this;
        return UserRole_get_describe()(x);
    }
}

export function UserRole$reflection() {
    return union_type("Optiscan.Models.Api.Security.UserRole", [], UserRole, () => [[], [], [], []]);
}

export function UserRole_get_all() {
    return ofArray([new UserRole(0), new UserRole(1), new UserRole(2), new UserRole(3)]);
}

export function UserRole_get_describe() {
    return (_arg1) => ((_arg1.tag === 1) ? "Account Admin" : ((_arg1.tag === 2) ? "Site Admin" : ((_arg1.tag === 3) ? "Clinical Staff" : "Optiscan Admin")));
}

export function UserRole_tryParse_Z721C83C5(role) {
    return tryFind((r) => (UserRole_get_describe()(r).toLowerCase() === role.toLowerCase()), UserRole_get_all());
}

export function UserRole_get_selectableRoles() {
    return (_arg2) => ((_arg2.tag === 1) ? List_except([new UserRole(0)], UserRole_get_all(), {
        Equals: equals,
        GetHashCode: safeHash,
    }) : ((_arg2.tag === 2) ? List_except([new UserRole(0), new UserRole(1)], UserRole_get_all(), {
        Equals: equals,
        GetHashCode: safeHash,
    }) : ((_arg2.tag === 3) ? empty() : UserRole_get_all())));
}

export function AssignedSiteId_ofSite(site) {
    return new AssignedSiteId(site.siteId, site.accountId);
}

export const AssignedSiteId_accountId_ = [(m) => m.accountId, (v) => ((m_1) => (new AssignedSiteId(m_1.siteId, v)))];

export const AssignedSiteId_siteId_ = [(m) => m.siteId, (v) => ((m_1) => (new AssignedSiteId(v, m_1.accountId)))];

export class OptiscanUserData extends Record {
    constructor(accountIds, siteIds, isLockedOut) {
        super();
        this.accountIds = accountIds;
        this.siteIds = siteIds;
        this.isLockedOut = isLockedOut;
    }
}

export function OptiscanUserData$reflection() {
    return record_type("Optiscan.Models.Api.Security.OptiscanUserData", [], OptiscanUserData, () => [["accountIds", list_type(class_type("System.Guid"))], ["siteIds", list_type(AssignedSiteId$reflection())], ["isLockedOut", bool_type]]);
}

export function OptiscanUserData_get_accountIds_() {
    return [(m) => m.accountIds, (v) => ((m_1) => (new OptiscanUserData(v, m_1.siteIds, m_1.isLockedOut)))];
}

export function OptiscanUserData_get_siteIds_() {
    return [(m) => m.siteIds, (v) => ((m_1) => (new OptiscanUserData(m_1.accountIds, v, m_1.isLockedOut)))];
}

export function OptiscanUserData_get_siteIdSiteIds() {
    let f1;
    const optic = OptiscanUserData_get_siteIds_();
    f1 = ((target) => Optic_Get_op_HatDot_21762A61(new Optic_Get(0), optic)(target));
    return (arg) => map((target_2) => Optic_Get_op_HatDot_21762A61(new Optic_Get(0), AssignedSiteId_siteId_)(target_2), f1(arg));
}

export const OptiscanUserViewModelModule_empty = Security_UserViewModel$2_empty_1505(new OptiscanUserData(empty(), empty(), false));

export class Permission extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AccessAllUsers", "AccessUsersByAccount", "AccessUsersBySite", "CreateUser", "EditUser", "DeleteUser", "ViewOwnProfile", "LockoutUsers", "ViewUsersPage", "AccessUsersByIds", "AccessAllAccounts", "AccessAccountsByAccount", "CreateAccount", "EditAccount", "DeleteAccount", "ViewAccountsPage", "AccessAllSites", "AccessSitesByAccount", "AccessSitesBySite", "CreateSite", "EditSite", "DeleteSite", "ViewSitesPage", "SearchForSites", "AccessAllDevices", "EditDevice", "ViewDevicesPage", "ViewPatientsPage", "ViewPatientSearchPage", "CreateAuthorizedSite", "DeleteAuthorizedSite", "ViewAuthorizedSites", "ViewAuthorizedSitesPage", "CreateNote", "EditNote", "ViewNotes", "CreateSession", "EditSession", "ViewDashboardPage"];
    }
}

export function Permission$reflection() {
    return union_type("Optiscan.Models.Api.Security.Permission", [], Permission, () => [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []]);
}

const permissionsByRole = ofList(map((r) => [r, (r.tag === 1) ? ofArray([new Permission(3), new Permission(4), new Permission(1), new Permission(6), new Permission(7), new Permission(13), new Permission(11), new Permission(16), new Permission(19), new Permission(20), new Permission(21), new Permission(23), new Permission(17), new Permission(24), new Permission(25), new Permission(8), new Permission(15), new Permission(22), new Permission(26), new Permission(27), new Permission(29), new Permission(30), new Permission(31), new Permission(32)]) : ((r.tag === 2) ? ofArray([new Permission(3), new Permission(4), new Permission(2), new Permission(9), new Permission(6), new Permission(7), new Permission(11), new Permission(20), new Permission(18), new Permission(23), new Permission(24), new Permission(25), new Permission(8), new Permission(15), new Permission(22), new Permission(26), new Permission(27), new Permission(28), new Permission(29), new Permission(30), new Permission(31), new Permission(32), new Permission(33), new Permission(35), new Permission(34)]) : ((r.tag === 3) ? ofArray([new Permission(6), new Permission(9), new Permission(11), new Permission(18), new Permission(15), new Permission(22), new Permission(27), new Permission(28), new Permission(31), new Permission(33), new Permission(35), new Permission(34), new Permission(36), new Permission(38), new Permission(37)]) : ofArray([new Permission(0), new Permission(3), new Permission(4), new Permission(5), new Permission(6), new Permission(7), new Permission(10), new Permission(12), new Permission(13), new Permission(14), new Permission(16), new Permission(19), new Permission(20), new Permission(21), new Permission(23), new Permission(24), new Permission(25), new Permission(8), new Permission(15), new Permission(22), new Permission(26), new Permission(29), new Permission(30), new Permission(31), new Permission(32)])))], UserRole_get_all()));

export function isAllowed(permission) {
    return (list) => exists((role) => defaultArg(map_1((source) => contains(permission, source, {
        Equals: equals,
        GetHashCode: safeHash,
    }), tryFind_1(role, permissionsByRole)), false), list);
}

export function isAllowedOneOf(permissions, roles) {
    const matchValue = map((p) => isAllowed(p)(roles), permissions);
    if (!isEmpty(matchValue)) {
        if (isEmpty(tail(matchValue))) {
            return head(matchValue);
        }
        else {
            return reduce((e1, e2) => (e1 || e2), matchValue);
        }
    }
    else {
        return true;
    }
}

export function rolesWithPermission(permission) {
    return filter((role) => defaultArg(map_1((source) => contains(permission, source, {
        Equals: equals,
        GetHashCode: safeHash,
    }), tryFind_1(role, permissionsByRole)), false), UserRole_get_all());
}

export function collectPermissions(permissionChooser, role) {
    return defaultArg(map_1((list) => choose(permissionChooser, list), tryFind_1(role, permissionsByRole)), empty());
}

export class GuestInfo extends Record {
    constructor(guestAccessToken, guestName, guestLinkCode, sessionId) {
        super();
        this.guestAccessToken = guestAccessToken;
        this.guestName = guestName;
        this.guestLinkCode = guestLinkCode;
        this.sessionId = sessionId;
    }
}

export function GuestInfo$reflection() {
    return record_type("Optiscan.Models.Api.Security.GuestInfo", [], GuestInfo, () => [["guestAccessToken", string_type], ["guestName", string_type], ["guestLinkCode", class_type("System.Guid")], ["sessionId", class_type("System.Guid")]]);
}

export const GuestInfoModule_guestAccessToken_ = [(m) => m.guestAccessToken, (v) => ((m_1) => (new GuestInfo(v, m_1.guestName, m_1.guestLinkCode, m_1.sessionId)))];

export const GuestInfoModule_guestName_ = [(m) => m.guestName, (v) => ((m_1) => (new GuestInfo(m_1.guestAccessToken, v, m_1.guestLinkCode, m_1.sessionId)))];

export const GuestInfoModule_guestLinkCode_ = [(m) => m.guestLinkCode, (v) => ((m_1) => (new GuestInfo(m_1.guestAccessToken, m_1.guestName, v, m_1.sessionId)))];

export const GuestInfoModule_sessionId_ = [(m) => m.sessionId, (v) => ((m_1) => (new GuestInfo(m_1.guestAccessToken, m_1.guestName, m_1.guestLinkCode, v)))];

