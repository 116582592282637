import { Record } from "../../../fable_modules/fable-library.3.7.12/Types.js";
import { PatientNoteViewModel$reflection } from "../../../Optiscan.Models/View/PatientNote.js";
import { record_type, list_type } from "../../../fable_modules/fable-library.3.7.12/Reflection.js";
import { ofArray, map } from "../../../fable_modules/fable-library.3.7.12/List.js";
import { toString } from "../../../fable_modules/fable-library.3.7.12/Date.js";
import { Common_Name_display_4140EA02 } from "../../../fable_modules/Webbler.Models.1.2.6/Api.fs.js";
import { Csv_CsvRowMulti } from "../../../Optiscan.Models/Common.js";
import { AsyncResultComputationExpression_asyncResult, AsyncResultComputationExpression_AsyncResultBuilder__Return_1505, AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B } from "../../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { Pdf_Spacing_topMargin, Pdf_Table_Basic_make, Pdf_Table_ofCsvRows } from "../PatientReportHelpers.js";

export class Args extends Record {
    constructor(notes) {
        super();
        this.notes = notes;
    }
}

export function Args$reflection() {
    return record_type("Client.Patients.PatientReport.Sections.NotesSection.Args", [], Args, () => [["notes", list_type(PatientNoteViewModel$reflection())]]);
}

function convertToCsvRows(notes) {
    return map((note) => (new Csv_CsvRowMulti(toString(note.dateCreated, "yyyy-MM-dd HH:mm"), ofArray([Common_Name_display_4140EA02(note.createdByName), note.note]))), notes);
}

export function create(doc, args) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => {
        const patternInput = Pdf_Table_ofCsvRows(ofArray(["Date Created", "Created By", "Note"]), convertToCsvRows(args.notes));
        return AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, Pdf_Table_Basic_make("Notes", patternInput[0], patternInput[1], Pdf_Spacing_topMargin, doc));
    });
}

