import { Record } from "../../fable_modules/fable-library.3.7.12/Types.js";
import { record_type, string_type, enum_type, int32_type, class_type } from "../../fable_modules/fable-library.3.7.12/Reflection.js";

export class AssessmentViewModel extends Record {
    constructor(patientId, assessmentId, assessmentType, assessment) {
        super();
        this.patientId = patientId;
        this.assessmentId = assessmentId;
        this.assessmentType = (assessmentType | 0);
        this.assessment = assessment;
    }
}

export function AssessmentViewModel$reflection() {
    return record_type("Optiscan.Models.View.Assessment.AssessmentViewModel", [], AssessmentViewModel, () => [["patientId", class_type("System.Guid")], ["assessmentId", class_type("System.Guid")], ["assessmentType", enum_type("Optiscan.Models.Shared.Assessment.Type", int32_type, [["SummaryDiagnosis", 0], ["ClinicalHistoryInformation", 1], ["GeneralNotes", 2], ["Macroscopic", 3], ["Microscopic", 4]])], ["assessment", string_type]]);
}

export function AssessmentViewModel_get_assessment_() {
    return [(m) => m.assessment, (v) => ((m_1) => (new AssessmentViewModel(m_1.patientId, m_1.assessmentId, m_1.assessmentType, v.trim())))];
}

