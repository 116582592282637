import { Union, Record } from "../../../fable_modules/fable-library.3.7.12/Types.js";
import { AdjustedTimeZoneViewModel$reflection, SessionViewModel$reflection } from "../../../Optiscan.Models/View/Session.js";
import { union_type, record_type, int32_type, string_type, list_type, class_type, option_type } from "../../../fable_modules/fable-library.3.7.12/Reflection.js";
import { AuthorizedUserInformation$reflection } from "../../../Optiscan.Models/View/AuthorizedUserInformation.js";
import { ErrorMessage$reflection } from "../../../Optiscan.Models/ErrorMessage.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library.3.7.12/Choice.js";
import { CrudEditMsg$2$reflection } from "../../../Optiscan.SharedUI/Crud.js";

export class ScheduledSessionViewModel extends Record {
    constructor(maybeSelectedSession, patientId, timeZones, sessionName, selectedUsers, availableUsers, date, time, timeZone) {
        super();
        this.maybeSelectedSession = maybeSelectedSession;
        this.patientId = patientId;
        this.timeZones = timeZones;
        this.sessionName = sessionName;
        this.selectedUsers = selectedUsers;
        this.availableUsers = availableUsers;
        this.date = date;
        this.time = time;
        this.timeZone = timeZone;
    }
}

export function ScheduledSessionViewModel$reflection() {
    return record_type("Client.Patients.PatientSessions.Types.ScheduledSessionViewModel", [], ScheduledSessionViewModel, () => [["maybeSelectedSession", option_type(SessionViewModel$reflection())], ["patientId", class_type("System.Guid")], ["timeZones", option_type(list_type(AdjustedTimeZoneViewModel$reflection()))], ["sessionName", string_type], ["selectedUsers", list_type(AuthorizedUserInformation$reflection())], ["availableUsers", list_type(AuthorizedUserInformation$reflection())], ["date", option_type(class_type("System.DateTimeOffset"))], ["time", option_type(int32_type)], ["timeZone", option_type(AdjustedTimeZoneViewModel$reflection())]]);
}

export function ScheduledSessionViewModel_get_sessionName_() {
    return [(m) => m.sessionName, (v) => ((m_1) => (new ScheduledSessionViewModel(m_1.maybeSelectedSession, m_1.patientId, m_1.timeZones, v, m_1.selectedUsers, m_1.availableUsers, m_1.date, m_1.time, m_1.timeZone)))];
}

export function ScheduledSessionViewModel_get_date_() {
    return [(m) => m.date, (v) => ((m_1) => (new ScheduledSessionViewModel(m_1.maybeSelectedSession, m_1.patientId, m_1.timeZones, m_1.sessionName, m_1.selectedUsers, m_1.availableUsers, v, m_1.time, m_1.timeZone)))];
}

export function ScheduledSessionViewModel_get_time_() {
    return [(m) => m.time, (v) => ((m_1) => (new ScheduledSessionViewModel(m_1.maybeSelectedSession, m_1.patientId, m_1.timeZones, m_1.sessionName, m_1.selectedUsers, m_1.availableUsers, m_1.date, v, m_1.timeZone)))];
}

export function ScheduledSessionViewModel_get_timeZone_() {
    return [(m) => m.timeZone, (v) => ((m_1) => (new ScheduledSessionViewModel(m_1.maybeSelectedSession, m_1.patientId, m_1.timeZones, m_1.sessionName, m_1.selectedUsers, m_1.availableUsers, m_1.date, m_1.time, v)))];
}

export function ScheduledSessionViewModel_get_selectedUsers_() {
    return [(m) => m.selectedUsers, (v) => ((m_1) => (new ScheduledSessionViewModel(m_1.maybeSelectedSession, m_1.patientId, m_1.timeZones, m_1.sessionName, v, m_1.availableUsers, m_1.date, m_1.time, m_1.timeZone)))];
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["GetTimeZones", "GotTimeZonesResult", "UpdateSelectedUsers", "CrudEditMsg"];
    }
}

export function Msg$reflection() {
    return union_type("Client.Patients.PatientSessions.Types.Msg", [], Msg, () => [[["Item", class_type("System.DateTime")]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(AdjustedTimeZoneViewModel$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(AdjustedTimeZoneViewModel$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["Item", list_type(AuthorizedUserInformation$reflection())]], [["Item", CrudEditMsg$2$reflection(ScheduledSessionViewModel$reflection(), ErrorMessage$reflection())]]]);
}

