import { Record } from "../../../fable_modules/fable-library.3.7.12/Types.js";
import { SessionAnnotationViewModel$reflection } from "../../../Optiscan.Models/View/SessionAnnotationViewModel.js";
import { record_type, list_type } from "../../../fable_modules/fable-library.3.7.12/Reflection.js";
import { join } from "../../../fable_modules/fable-library.3.7.12/String.js";
import { filter, ofArray, map } from "../../../fable_modules/fable-library.3.7.12/List.js";
import { defaultArg } from "../../../fable_modules/fable-library.3.7.12/Option.js";
import { Csv_CsvRowMulti } from "../../../Optiscan.Models/Common.js";
import { AsyncResultComputationExpression_asyncResult, AsyncResultComputationExpression_AsyncResultBuilder__Return_1505, AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B } from "../../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { Pdf_Spacing_topMargin, Pdf_Table_Basic_make, Pdf_Table_ofCsvRows } from "../PatientReportHelpers.js";

const notAvailable = "N/A";

export class Args extends Record {
    constructor(annotations) {
        super();
        this.annotations = annotations;
    }
}

export function Args$reflection() {
    return record_type("Client.Patients.PatientReport.Sections.AnnotationsSection.Args", [], Args, () => [["annotations", list_type(SessionAnnotationViewModel$reflection())]]);
}

function createMeasurementsString(measurements) {
    if (measurements.length === 0) {
        return notAvailable;
    }
    else {
        return join("\n", measurements);
    }
}

function convertToCsvRows(annotations) {
    return map((annotation_1) => (new Csv_CsvRowMulti(defaultArg(annotation_1.annotationName, notAvailable), ofArray([createMeasurementsString(annotation_1.measurements), defaultArg(annotation_1.note, notAvailable)]))), filter((annotation) => (annotation.measurements.length > 0), annotations));
}

export function create(doc, args) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => {
        const patternInput = Pdf_Table_ofCsvRows(ofArray(["Name", "Measurements", "Note"]), convertToCsvRows(args.annotations));
        return AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, Pdf_Table_Basic_make("Annotations", patternInput[0], patternInput[1], Pdf_Spacing_topMargin, doc));
    });
}

