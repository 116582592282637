import { singleton } from "../../../fable_modules/fable-library.3.7.12/AsyncBuilder.js";
import { sleep } from "../../../fable_modules/fable-library.3.7.12/Async.js";
import { PatientHistoricalComparisonModel, ComparisonLayout, HistoricalViewport, PatientHistoricalComparisonMsg } from "./HistoricalComparisonTypes.js";
import { StateTreeNode$6, NavigationMsg$1, StateTreeMsg$4 } from "../../../fable_modules/Webbler.StateTree.Core.1.2.6/StateTree.fs.js";
import { head, tail, isEmpty, tryFindIndex, removeAt, cons, filter, tryFind, append as append_1, initialize, updateAt, indexed, ofSeq, choose, concat, iterate, tryItem, toArray, mapIndexed, map as map_1, ofArray, singleton as singleton_1, length, empty, item as item_2 } from "../../../fable_modules/fable-library.3.7.12/List.js";
import { dataUrlToBlob, SessionAnnotationNoteState, FileMetadata, defaultFrameFile, LoadingState, SelectedSequence } from "../HistoricalCommon.js";
import { safeHash, equals, uncurry, max, comparePrimitives, min } from "../../../fable_modules/fable-library.3.7.12/Util.js";
import { FlaggedImages_flagFrame, FlaggedImages_tryGetFrameNumber, FlaggedImages_mkFlaggedImageListItemFromSelected, customEvent, Thumbnails_loadThumbnailFromS3, Dicoms_loadDicomFromS3, FlaggedImages_loadFlaggedImagesPromise, Dicoms_loadAndParseFileList, Dicoms_clearDicomCache } from "../HistoricalHelpers.js";
import { Sort_SortInfo$1, Sort_SortDirection, Sort_SortableColumn$1_toSortableColumnView_45D2C9EC } from "../../../Optiscan.SharedUI/Sort.js";
import { toArray as toArray_2, some, bind, map, defaultArg } from "../../../fable_modules/fable-library.3.7.12/Option.js";
import { flaggedImagesTableCard, flaggedImagesTableColumns } from "../FlaggedImagesView.js";
import { sessionAnnotationsTableCard, sessionAnnotationDeleteModal, sessionAnnotationsEditModal, sessionAnnotationsTableColumns } from "../SessionAnnotationsView.js";
import { ofList, toList as toList_1, FSharpMap__get_IsEmpty, empty as empty_1 } from "../../../fable_modules/fable-library.3.7.12/Map.js";
import { ScreenshotInfoViewModel, ScreenshotInfoViewModel_get_description_, ScreenshotInfoViewModel_get_name_, ScreenshotInfoViewModel_get_empty } from "../../../Optiscan.Models/View/ScreenshotInfoViewModel.js";
import { Cmd_ofSub, Cmd_OfPromise_either, Cmd_none, Cmd_batch } from "../../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Cmd_OfAsyncWith_result, Cmd_OfAsyncWith_attempt, Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "../../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { PatientsOutboundMsg } from "../../PatientSearchTypes.js";
import { Option, columns } from "../../../fable_modules/Fulma.2.16.0/Layouts/Columns.fs.js";
import { iterate as iterate_1, empty as empty_2, toArray as toArray_1, singleton as singleton_2, append, delay, toList } from "../../../fable_modules/fable-library.3.7.12/Seq.js";
import { Helpers_nothing } from "../../../fable_modules/Fable.React.7.4.3/Fable.React.Helpers.fs.js";
import { Card_foot, Card_body, Card_title, Card_head, Card_card, Option as Option_1, modal } from "../../../fable_modules/Fulma.2.16.0/Components/Modal.fs.js";
import { Color_IColor, Modifier_IModifier, Screen, Common_GenericOption } from "../../../fable_modules/Fulma.2.16.0/Common.fs.js";
import { DOMAttr, HTMLAttr } from "../../../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { ISize, Option as Option_6, column } from "../../../fable_modules/Fulma.2.16.0/Layouts/Column.fs.js";
import * as react from "react";
import { FormField_ReactMenu_menuItem, FormField_ReactMenu_subMenu, FormField_ReactMenu_menu, FormField_ReactSelect_CallbackSingle$1, FormField_ReactSelect_single, FormField_textArea, FormField_onChangeSet, FormField_text } from "../../../Optiscan.SharedUI/Forms.js";
import { Option as Option_2 } from "../../../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { Optic_Set, Optic_Set_op_HatEquals_2170E4F5 } from "../../../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { Functions_flip } from "../../../fable_modules/Webbler.Models.1.2.6/Common.fs.js";
import { Option as Option_3 } from "../../../fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { Option as Option_4 } from "../../../fable_modules/Fulma.2.16.0/Elements/Form/Textarea.fs.js";
import { keyValueList } from "../../../fable_modules/fable-library.3.7.12/MapUtil.js";
import { Option as Option_5, button } from "../../../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { join, replace, isNullOrWhiteSpace } from "../../../fable_modules/fable-library.3.7.12/String.js";
import { End_div, Item_Option, Item_div, Start_div, Option as Option_7, navbar } from "../../../fable_modules/Fulma.2.16.0/Components/Navbar.fs.js";
import { ThumbnailSliderProps } from "../ThumbnailSliderBindings.js";
import ThumbnailSlider from "../../../../../src/Optiscan.Client/Patients/Historical/js/ThumbnailSlider.tsx";
import { Fa_ISize, Fa_IconOption, Fa_i } from "../../../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { SetFrameEvent, eventNames, IHistoricalViewProps } from "../HistoricalBindings.js";
import HistoricalView from "../../../../../src/Optiscan.Client/Patients/Historical/js/HistoricalView.tsx";
import { map as map_2, unzip, equalsWith } from "../../../fable_modules/fable-library.3.7.12/Array.js";
import { MenuItemProps, SubMenuProps, MenuProps } from "../../../Optiscan.SharedUI/bindings/ReactMenuBind.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library.3.7.12/Choice.js";
import { List_distinct } from "../../../fable_modules/fable-library.3.7.12/Seq2.js";
import { ErrorMessage_get_describe, ErrorMessage } from "../../../Optiscan.Models/ErrorMessage.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { promise } from "../../../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { securedApi } from "../../../Api.js";
import { TelemetryAction, FileOperationContext } from "../../../Optiscan.Models/Api/Api.js";
import { Navigation_newUrl } from "../../../fable_modules/Fable.Elmish.Browser.3.0.4/navigation.fs.js";
import { Page, pageHash } from "../../../Pages.js";
import { SessionAnnotationViewModel } from "../../../Optiscan.Models/View/SessionAnnotationViewModel.js";
import { error } from "../../../Optiscan.SharedUI/Toast.js";
import { create, match } from "../../../fable_modules/fable-library.3.7.12/RegExp.js";
import { parse } from "../../../fable_modules/fable-library.3.7.12/Int32.js";
import { utcNow } from "../../../fable_modules/fable-library.3.7.12/DateOffset.js";
import { newGuid } from "../../../fable_modules/fable-library.3.7.12/Guid.js";
import { S3_PutObjectRequestParams, S3_PutObjectRequestParams_get_create } from "../../../bindings/Aws/Fable.Helpers.Aws.js";

export function playbackTick(viewportIndex) {
    return singleton.Delay(() => singleton.Bind(sleep(1000), () => singleton.Return(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(43, viewportIndex)))));
}

function getFrameInformationString(model, viewportIndex) {
    const location = item_2(viewportIndex, model.viewports).selectedSequence.locationIndex | 0;
    const site = item_2(viewportIndex, model.viewports).selectedSequence.siteIndex | 0;
    const sequence = item_2(viewportIndex, model.viewports).selectedSequence.sequenceIndex | 0;
    const frame = item_2(viewportIndex, model.viewports).frameIndex | 0;
    const locationName = item_2(location, model.fileMetadata.locationNames);
    const siteName = item_2(site, item_2(location, model.fileMetadata.siteNames));
    const sequenceName = item_2(sequence, item_2(site, item_2(location, model.fileMetadata.sequenceNames)));
    return `Viewport ${(viewportIndex + 1)}:
	${locationName}
	${siteName}
	${sequenceName}
	Frame ${frame}`;
}

export const emptyViewport = new HistoricalViewport(new SelectedSequence(0, 0, 0), 0, new LoadingState(0), [], empty(), true);

export function clampFrameIndex(model, selectedSequence, frameIndex) {
    return min(comparePrimitives, length(item_2(selectedSequence.sequenceIndex, item_2(selectedSequence.siteIndex, item_2(selectedSequence.locationIndex, model.fileMetadata.frames)))) - 1, max(comparePrimitives, 0, frameIndex));
}

export function patientHistoricalComparisonView(currentUser) {
    return new StateTreeNode$6((deps) => {
        Dicoms_clearDicomCache();
        const initialSortInfo = (lastColumnClicked) => (new Sort_SortInfo$1(Sort_SortableColumn$1_toSortableColumnView_45D2C9EC(lastColumnClicked), new Sort_SortDirection(0)));
        return [new PatientHistoricalComparisonModel(deps.patient, defaultArg(map((s) => s.sessionId, deps.maybeSelectedSession), "00000000-0000-0000-0000-000000000000"), defaultArg(map((s_1) => s_1.sessionName, deps.maybeSelectedSession), "--"), new FileMetadata(singleton_1("Loading"), singleton_1(singleton_1("Loading")), singleton_1(singleton_1(singleton_1("Loading"))), singleton_1(singleton_1(singleton_1(singleton_1(defaultFrameFile)))), empty(), empty()), singleton_1(emptyViewport), void 0, new ComparisonLayout(0), initialSortInfo(item_2(0, flaggedImagesTableColumns)), initialSortInfo(item_2(0, sessionAnnotationsTableColumns)), empty(), true, false, false, false, void 0, new SessionAnnotationNoteState(2), empty_1(), ScreenshotInfoViewModel_get_empty()), Cmd_batch(ofArray([Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(1))), Cmd_OfFunc_result(new StateTreeMsg$4(3, new PatientsOutboundMsg(0, true)))]))];
    }, (model, dispatch) => columns(singleton_1(new Option(8, model.loadingScreenshots ? "is-loading" : "")), toList(delay(() => {
        let matchValue, annotation_1;
        return append((matchValue = [model.sessionAnnotationNoteState, model.selectedAnnotation], (matchValue[0].tag === 0) ? ((matchValue[1] != null) ? singleton_2(sessionAnnotationsEditModal(matchValue[1], model.savingAnnotations ? true : model.loadingAnnotations, (arg_1) => {
            dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(23, arg_1)));
        }, (_arg1) => {
            dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(25)));
        }, (_arg2) => {
            dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(24, new SessionAnnotationNoteState(2))));
        })) : singleton_2(Helpers_nothing)) : ((matchValue[0].tag === 1) ? ((matchValue[1] != null) ? ((annotation_1 = matchValue[1], singleton_2(sessionAnnotationDeleteModal((_arg3) => {
            dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(19, annotation_1.annotationId)));
        }, (_arg4) => {
            dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(24, new SessionAnnotationNoteState(2))));
        })))) : singleton_2(Helpers_nothing)) : singleton_2(Helpers_nothing))), delay(() => {
            let props_6, children_5;
            return append(singleton_2(modal(ofArray([new Option_1(1, !FSharpMap__get_IsEmpty(model.screenshotDataURLs)), new Option_1(0, singleton_1(["style", {
                minWidth: "100vw",
            }]))]), singleton_1(Card_card(empty(), ofArray([Card_head(empty(), singleton_1(Card_title(empty(), singleton_1("Comparison Screenshot")))), Card_body(singleton_1(new Common_GenericOption(1, singleton_1(["style", {
                overflowY: "auto",
            }]))), singleton_1((props_6 = [new HTMLAttr(64, model.savingScreenshots ? "is-loading" : "")], (children_5 = toList(delay(() => {
                const setter = (arg_3) => {
                    dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(27, arg_3)));
                };
                return append(singleton_2(columns(singleton_1(new Option(2)), singleton_1(column(empty(), map_1((tupledArg) => {
                    let children;
                    const children_2 = [react.createElement("img", {
                        src: tupledArg[1],
                    }), (children = [`Viewport ${(tupledArg[0] + 1)}`], react.createElement("figcaption", {}, ...children))];
                    return react.createElement("figure", {}, ...children_2);
                }, toList_1(model.screenshotDataURLs)))))), delay(() => {
                    let f, optic;
                    return append(singleton_2(FormField_text(defaultArg(model.screenshotInfo.name, ""), empty(), [new Option_2(4, model.savingScreenshots)], FormField_onChangeSet(setter, uncurry(2, (f = ((optic = ScreenshotInfoViewModel_get_name_(), (value_3) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic)(value_3))), (b) => ((a) => Functions_flip(uncurry(2, f), b, a))))), "SessionAnnotation", "Screenshot Name")([])), delay(() => {
                        let arg70, f_1, optic_2;
                        return singleton_2((arg70 = singleton_1(new Option_3(11, singleton_1(["style", {
                            minHeight: "unset",
                        }]))), FormField_textArea("Screenshot Description", defaultArg(model.screenshotInfo.description, ""), empty(), [new Option_4(9, model.savingScreenshots)], FormField_onChangeSet(setter, uncurry(2, (f_1 = ((optic_2 = ScreenshotInfoViewModel_get_description_(), (value_6) => Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), optic_2)(value_6))), (b_1) => ((a_1) => Functions_flip(uncurry(2, f_1), b_1, a_1))))), "SessionAnnotation", "Description")(arg70)));
                    }));
                }));
            })), react.createElement("div", keyValueList(props_6, 1), ...children_5))))), Card_foot(empty(), ofArray([button(ofArray([new Option_5(18, (_arg5) => {
                dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(30)));
            }), new Option_5(16, model.savingScreenshots ? true : isNullOrWhiteSpace(defaultArg(model.screenshotInfo.name, "")))]), singleton_1("Save")), button(ofArray([new Option_5(18, (_arg6) => {
                dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(29)));
            }), new Option_5(16, model.savingScreenshots)]), singleton_1("Cancel"))]))]))))), delay(() => append(singleton_2(column(ofArray([new Option_6(2, "session-details-column full-page-content"), new Option_6(4, singleton_1(new Modifier_IModifier(24, new Screen(0), !(model.maybeMaximizedViewportIndex == null)))), new Option_6(0, new Screen(0), new ISize(7))]), ofArray([flaggedImagesTableCard(model.fileMetadata, model.flaggedImagesSortInfo, (item, _arg7) => {
                dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(35, item)));
            }, (sortInfo, _arg8) => {
                dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(38, sortInfo)));
            }), sessionAnnotationsTableCard(model.sessionAnnotations, model.selectedAnnotation, model.sessionAnnotationsSortInfo, (_arg9) => {
                dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(24, new SessionAnnotationNoteState(0))));
            }, (_arg10) => {
                dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(24, new SessionAnnotationNoteState(1))));
            }, (item_1, _arg11) => {
                dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(36, item_1)));
            }, (sortInfo_1, _arg12) => {
                dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(39, sortInfo_1)));
            })]))), delay(() => {
                let children_39;
                return singleton_2(column(singleton_1(new Option_6(2, "full-page-content historical-column")), ofArray([navbar(ofArray([new Option_7(7, "columns tele-nav"), new Option_7(8, singleton_1(new Modifier_IModifier(24, new Screen(0), !(model.maybeMaximizedViewportIndex == null))))]), ofArray([Start_div(empty(), ofArray([Item_div(singleton_1(new Item_Option(6, "tele-nav-back-button no-margin")), singleton_1(button(singleton_1(new Option_5(18, (_arg13) => {
                    Dicoms_clearDicomCache();
                    dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(13)));
                })), singleton_1("Back")))), Item_div(empty(), singleton_1(button(ofArray([new Option_5(18, (_arg14) => {
                    dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(34)));
                }), new Option_5(0, new Color_IColor(6))]), singleton_1("Generate Report"))))])), End_div(ofArray([new Common_GenericOption(0, "column is-2"), new Common_GenericOption(1, singleton_1(["style", {
                    padding: 0,
                }]))]), singleton_1(Item_div(empty(), singleton_1(FormField_ReactSelect_single([new ComparisonLayout(0), new ComparisonLayout(1, 1), new ComparisonLayout(1, 2), new ComparisonLayout(2, 1), new ComparisonLayout(2, 2)], [], (_arg1_1) => ((_arg1_1.tag === 1) ? (`2x${_arg1_1.fields[0]}`) : ((_arg1_1.tag === 2) ? (`3x${_arg1_1.fields[0]}`) : "Single")), model.layout, new FormField_ReactSelect_CallbackSingle$1(0, (arg_6) => {
                    dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(14, defaultArg(arg_6, model.layout))));
                }), "Layout", false, "Historical", [new Option_3(11, singleton_1(["style", {
                    flexGrow: 1,
                }]))])))))])), (children_39 = toList(delay(() => append(mapIndexed((viewportIdx, viewport) => {
                    let props_18, children_15, children_29, children_27, children_25, children_19, children_21, children_23;
                    const matchValue_1 = viewport.loadingState;
                    switch (matchValue_1.tag) {
                        case 0:
                        case 1:
                        case 3: {
                            let loadingClass;
                            const matchValue_2 = viewport.loadingState;
                            switch (matchValue_2.tag) {
                                case 0:
                                case 1: {
                                    loadingClass = "is-loading";
                                    break;
                                }
                                default: {
                                    loadingClass = "";
                                }
                            }
                            let layoutClasses;
                            const matchValue_3 = model.layout;
                            switch (matchValue_3.tag) {
                                case 1: {
                                    layoutClasses = "row-double";
                                    break;
                                }
                                case 2: {
                                    layoutClasses = "row-triple";
                                    break;
                                }
                                default: {
                                    layoutClasses = "row-single";
                                }
                            }
                            let layoutHeightClasses;
                            const matchValue_4 = model.layout;
                            let pattern_matching_result;
                            if (matchValue_4.tag === 1) {
                                if (matchValue_4.fields[0] === 1) {
                                    pattern_matching_result = 0;
                                }
                                else {
                                    pattern_matching_result = 1;
                                }
                            }
                            else if (matchValue_4.tag === 2) {
                                if (matchValue_4.fields[0] === 1) {
                                    pattern_matching_result = 0;
                                }
                                else {
                                    pattern_matching_result = 1;
                                }
                            }
                            else {
                                pattern_matching_result = 0;
                            }
                            switch (pattern_matching_result) {
                                case 0: {
                                    layoutHeightClasses = "tall";
                                    break;
                                }
                                case 1: {
                                    layoutHeightClasses = "";
                                    break;
                                }
                            }
                            let selectedClass;
                            const matchValue_5 = model.maybeMaximizedViewportIndex;
                            selectedClass = ((matchValue_5 == null) ? "" : ((viewportIdx === matchValue_5) ? "historical-container--selected" : "historical-container--hidden"));
                            const props_34 = [new HTMLAttr(64, `historical-container ${loadingClass} ${layoutClasses} ${layoutHeightClasses} ${selectedClass}`)];
                            const children_31 = [(props_18 = [["style", {
                                width: "100%",
                                flex: "1",
                                display: "flex",
                            }]], (children_15 = toList(delay(() => {
                                let props_16, children_13, testExpr;
                                const matchValue_6 = [viewport.loadedDicomFiles, model.loadingAnnotations];
                                let pattern_matching_result_1;
                                if ((testExpr = matchValue_6[0], (!equalsWith(equals, testExpr, null)) && (testExpr.length === 0))) {
                                    pattern_matching_result_1 = 0;
                                }
                                else if (matchValue_6[1]) {
                                    pattern_matching_result_1 = 0;
                                }
                                else {
                                    pattern_matching_result_1 = 1;
                                }
                                switch (pattern_matching_result_1) {
                                    case 0: {
                                        return singleton_2(Helpers_nothing);
                                    }
                                    case 1: {
                                        const saveSpinnerHidden = (!model.savingAnnotations) ? "is-hidden" : "";
                                        const viewportContainerId = `historical-viewport-${viewportIdx}`;
                                        return singleton_2((props_16 = [new HTMLAttr(64, "historical-view-container"), new HTMLAttr(99, viewportContainerId), ["style", {
                                            display: "flex",
                                        }]], (children_13 = toList(delay(() => {
                                            let props_8, props_9;
                                            return append(singleton_2((props_8 = ofArray([new ThumbnailSliderProps(0, (viewport.loadingState.tag === 3) ? toArray(defaultArg(bind((list_3) => tryItem(viewport.selectedSequence.sequenceIndex, list_3), bind((list_2) => tryItem(viewport.selectedSequence.siteIndex, list_2), tryItem(viewport.selectedSequence.locationIndex, model.fileMetadata.frames))), singleton_1(defaultFrameFile))) : [defaultFrameFile]), new ThumbnailSliderProps(1, toArray(viewport.thumbnailUrls)), new ThumbnailSliderProps(2, viewport.frameIndex), new ThumbnailSliderProps(3, (i_2) => {
                                                iterate(dispatch, ofArray([new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(42, viewportIdx)), new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(11, viewportIdx, i_2))]));
                                            }), new ThumbnailSliderProps(4, document.getElementById(viewportContainerId)), new ThumbnailSliderProps(5, equals(model.layout, new ComparisonLayout(0)) ? true : (model.maybeMaximizedViewportIndex != null))]), (props_9 = keyValueList(props_8, 1), react.createElement(ThumbnailSlider, props_9)))), delay(() => {
                                                let props_10;
                                                return append(singleton_2((props_10 = [new HTMLAttr(64, `save-spinner ${saveSpinnerHidden}`)], react.createElement("div", keyValueList(props_10, 1), "Saving..."))), delay(() => append(singleton_2(button(ofArray([new Option_5(19, "historical-container__close-button"), new Option_5(20, singleton_1(new Modifier_IModifier(24, new Screen(0), model.maybeMaximizedViewportIndex == null))), new Option_5(18, (_arg15) => {
                                                    dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(44, void 0)));
                                                })]), singleton_1(Fa_i(ofArray([new Fa_IconOption(11, "fas fa-times"), new Fa_IconOption(0, new Fa_ISize(3)), new Fa_IconOption(15, "historical-container__close-button-icon")]), [])))), delay(() => {
                                                    let props_14, children_11, props_12, props_13;
                                                    return singleton_2((props_14 = [new DOMAttr(64, (evt) => {
                                                        let tupledArg_1;
                                                        dispatch(new StateTreeMsg$4(0, (tupledArg_1 = [viewportIdx, evt.deltaY], new PatientHistoricalComparisonMsg(12, tupledArg_1[0], tupledArg_1[1]))));
                                                    }), ["style", {
                                                        display: "flex",
                                                        flex: "1 1 0",
                                                    }]], (children_11 = [(props_12 = ofArray([new IHistoricalViewProps(0, viewportIdx), new IHistoricalViewProps(1, length(model.viewports)), new IHistoricalViewProps(2, viewport.frameIndex), new IHistoricalViewProps(3, matchValue_6[0]), new IHistoricalViewProps(4, toArray(concat(map_1((viewport_1) => {
                                                        const s_9 = viewport_1.selectedSequence;
                                                        return map_1((frame) => frame.filePath, item_2(s_9.sequenceIndex, item_2(s_9.siteIndex, item_2(s_9.locationIndex, model.fileMetadata.frames))));
                                                    }, model.viewports)))), new IHistoricalViewProps(5, () => {
                                                    }), new IHistoricalViewProps(6, () => {
                                                        dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(41, viewportIdx)));
                                                    }), new IHistoricalViewProps(7, () => {
                                                        dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(42, viewportIdx)));
                                                    }), new IHistoricalViewProps(8, (maybeViewportIndex) => {
                                                        dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(44, maybeViewportIndex)));
                                                    }), new IHistoricalViewProps(9, eventNames), new IHistoricalViewProps(10, () => {
                                                        dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(40, viewportIdx)));
                                                    }), new IHistoricalViewProps(11, (a_2) => {
                                                        dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(15, viewportIdx, a_2)));
                                                    }), new IHistoricalViewProps(12, (a_3) => {
                                                        dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(17, viewportIdx, a_3)));
                                                    }), new IHistoricalViewProps(17, toArray(choose((x_1) => {
                                                        if (((x_1.sequenceIndex === viewport.selectedSequence.sequenceIndex) && (x_1.siteIndex === viewport.selectedSequence.siteIndex)) && (x_1.locationIndex === viewport.selectedSequence.locationIndex)) {
                                                            return x_1.annotationData;
                                                        }
                                                        else {
                                                            return void 0;
                                                        }
                                                    }, model.sessionAnnotations))), new IHistoricalViewProps(15, (a_4) => {
                                                        dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(26, ofSeq(a_4))));
                                                    }), new IHistoricalViewProps(14, (a_5) => {
                                                        dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(37, a_5)));
                                                    }), new IHistoricalViewProps(16, (isLoading) => {
                                                        dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(28, isLoading)));
                                                    }), new IHistoricalViewProps(18, equals(model.layout, new ComparisonLayout(0)) ? true : (model.maybeMaximizedViewportIndex != null)), new IHistoricalViewProps(19, model.maybeMaximizedViewportIndex != null)]), (props_13 = keyValueList(props_12, 1), react.createElement(HistoricalView, props_13)))], react.createElement("div", keyValueList(props_14, 1), ...children_11))));
                                                }))));
                                            }));
                                        })), react.createElement("div", keyValueList(props_16, 1), ...children_13))));
                                    }
                                }
                            })), react.createElement("div", keyValueList(props_18, 1), ...children_15))), (children_29 = [(children_27 = [FormField_ReactMenu_menu(ofArray([new MenuProps(0, "end"), new MenuProps(1, "bottom"), new MenuProps(2, 8)]), singleton_1((children_25 = [react.createElement("div", {
                                className: "sequence-menu__label__item",
                            }, model.sessionName), (children_19 = [item_2(viewport.selectedSequence.locationIndex, model.fileMetadata.locationNames)], react.createElement("div", {
                                className: "sequence-menu__label__item",
                            }, ...children_19)), (children_21 = [item_2(viewport.selectedSequence.siteIndex, item_2(viewport.selectedSequence.locationIndex, model.fileMetadata.siteNames))], react.createElement("div", {
                                className: "sequence-menu__label__item",
                            }, ...children_21)), (children_23 = [item_2(viewport.selectedSequence.sequenceIndex, item_2(viewport.selectedSequence.siteIndex, item_2(viewport.selectedSequence.locationIndex, model.fileMetadata.sequenceNames)))], react.createElement("div", {
                                className: "sequence-menu__label__item",
                            }, ...children_23))], react.createElement("div", {
                                className: "sequence-menu__label",
                            }, ...children_25))), singleton_1(toArray(mapIndexed((j, location) => FormField_ReactMenu_subMenu(ofArray([new SubMenuProps(1, location), new SubMenuProps(0, location)]), singleton_1(toArray(mapIndexed((k, site) => FormField_ReactMenu_subMenu(ofArray([new SubMenuProps(1, site), new SubMenuProps(0, site)]), singleton_1(toArray(mapIndexed((l, sequence) => FormField_ReactMenu_menuItem(ofArray([new MenuItemProps(0, sequence), new MenuItemProps(1, () => {
                                dispatch(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(10, viewportIdx, new SelectedSequence(j, k, l), 0)));
                            })]), singleton_1(sequence)), item_2(k, item_2(j, model.fileMetadata.sequenceNames)))))), item_2(j, model.fileMetadata.siteNames))))), model.fileMetadata.locationNames))))], react.createElement("div", {
                                className: "react-menu__button__container",
                            }, ...children_27))], react.createElement("div", {
                                className: "multi-historical-footer",
                            }, ...children_29))];
                            return react.createElement("div", keyValueList(props_34, 1), ...children_31);
                        }
                        case 4: {
                            const children_35 = [react.createElement("h4", {
                                className: "title",
                            }, "Failed to load DICOM files")];
                            return react.createElement("div", {
                                className: "historical-container",
                            }, ...children_35);
                        }
                        default: {
                            return Helpers_nothing;
                        }
                    }
                }, model.viewports), delay(() => {
                    let props_40;
                    return singleton_2((props_40 = [["style", {
                        height: "5rem",
                    }]], react.createElement("div", keyValueList(props_40, 1))));
                })))), react.createElement("div", {
                    className: "historical-grid",
                }, ...children_39))])));
            }))));
        }));
    }))), uncurry(4, (msg_4) => ((tupledArg_2) => {
        const token = tupledArg_2[0];
        const maybeS3Ctx = tupledArg_2[3];
        return (deps_1) => ((model_1) => {
            let inputRecord, inputRecord_1, tupledArg_6, matchValue_9, inputRecord_2, inputRecord_3, inputRecord_4, inputRecord_5, inputRecord_6, tupledArg_7, inputRecord_7, ctx, inputRecord_8;
            let pattern_matching_result_2, e, parsedFileNamesList, err, meta, err_1, selectedSequence_1, fileArray, filePathsArray, selectedSequence_2, err_2, selectedSequence_3, selectedSequence_4, i_4, selectedSequence_5, url_1, filePathsArray_1, frameIndex, selectedSequence_6, viewportIndex_2, frameIndex_1, viewportIndex_3, delta, viewportIndex_4, layout, annotation_2, viewportIndex_5, annotation_3, viewportIndex_6, annotation_4, annotationUID, deletedAnnotationUID, annotations, err_3, err_4, state, setter_1, image, annotation_6, annotationUID_2, sortInfo_2, sortInfo_3, viewportIndex_7, viewportIndex_8, viewportIndex_9, viewportIndex_10, canvasInfo, setter_2, s3Keys, screenshotId_1, isLoading_1, maybeViewportIndex_1;
            if (msg_4.tag === 1) {
                pattern_matching_result_2 = 1;
            }
            else if (msg_4.tag === 2) {
                if (msg_4.fields[0].tag === 1) {
                    pattern_matching_result_2 = 3;
                    err = msg_4.fields[0].fields[0];
                }
                else {
                    pattern_matching_result_2 = 2;
                    parsedFileNamesList = msg_4.fields[0].fields[0];
                }
            }
            else if (msg_4.tag === 3) {
                pattern_matching_result_2 = 4;
            }
            else if (msg_4.tag === 4) {
                if (msg_4.fields[0].tag === 1) {
                    pattern_matching_result_2 = 6;
                    err_1 = msg_4.fields[0].fields[0];
                }
                else {
                    pattern_matching_result_2 = 5;
                    meta = msg_4.fields[0].fields[0];
                }
            }
            else if (msg_4.tag === 5) {
                pattern_matching_result_2 = 7;
                selectedSequence_1 = msg_4.fields[0];
            }
            else if (msg_4.tag === 6) {
                if (msg_4.fields[0].tag === 1) {
                    pattern_matching_result_2 = 9;
                    err_2 = msg_4.fields[0].fields[0];
                    selectedSequence_3 = msg_4.fields[1];
                }
                else {
                    pattern_matching_result_2 = 8;
                    fileArray = msg_4.fields[0].fields[0][0];
                    filePathsArray = msg_4.fields[0].fields[0][1];
                    selectedSequence_2 = msg_4.fields[1];
                }
            }
            else if (msg_4.tag === 7) {
                pattern_matching_result_2 = 10;
                selectedSequence_4 = msg_4.fields[0];
            }
            else if (msg_4.tag === 8) {
                pattern_matching_result_2 = 11;
                i_4 = msg_4.fields[0];
                selectedSequence_5 = msg_4.fields[2];
                url_1 = msg_4.fields[1];
            }
            else if (msg_4.tag === 9) {
                pattern_matching_result_2 = 12;
                filePathsArray_1 = msg_4.fields[0];
            }
            else if (msg_4.tag === 10) {
                pattern_matching_result_2 = 13;
                frameIndex = msg_4.fields[2];
                selectedSequence_6 = msg_4.fields[1];
                viewportIndex_2 = msg_4.fields[0];
            }
            else if (msg_4.tag === 11) {
                pattern_matching_result_2 = 14;
                frameIndex_1 = msg_4.fields[1];
                viewportIndex_3 = msg_4.fields[0];
            }
            else if (msg_4.tag === 12) {
                pattern_matching_result_2 = 15;
                delta = msg_4.fields[1];
                viewportIndex_4 = msg_4.fields[0];
            }
            else if (msg_4.tag === 13) {
                pattern_matching_result_2 = 16;
            }
            else if (msg_4.tag === 14) {
                pattern_matching_result_2 = 17;
                layout = msg_4.fields[0];
            }
            else if (msg_4.tag === 15) {
                pattern_matching_result_2 = 18;
                annotation_2 = msg_4.fields[1];
                viewportIndex_5 = msg_4.fields[0];
            }
            else if (msg_4.tag === 16) {
                if (msg_4.fields[0].tag === 1) {
                    pattern_matching_result_2 = 27;
                    err_4 = msg_4.fields[0].fields[0];
                }
                else {
                    pattern_matching_result_2 = 19;
                }
            }
            else if (msg_4.tag === 17) {
                pattern_matching_result_2 = 20;
                annotation_3 = msg_4.fields[1];
                viewportIndex_6 = msg_4.fields[0];
            }
            else if (msg_4.tag === 18) {
                if (msg_4.fields[0].tag === 1) {
                    pattern_matching_result_2 = 27;
                    err_4 = msg_4.fields[0].fields[0];
                }
                else {
                    pattern_matching_result_2 = 21;
                    annotation_4 = msg_4.fields[0].fields[0];
                }
            }
            else if (msg_4.tag === 19) {
                pattern_matching_result_2 = 22;
                annotationUID = msg_4.fields[0];
            }
            else if (msg_4.tag === 20) {
                if (msg_4.fields[0].tag === 1) {
                    pattern_matching_result_2 = 27;
                    err_4 = msg_4.fields[0].fields[0];
                }
                else {
                    pattern_matching_result_2 = 23;
                    deletedAnnotationUID = msg_4.fields[0].fields[0];
                }
            }
            else if (msg_4.tag === 21) {
                pattern_matching_result_2 = 24;
            }
            else if (msg_4.tag === 22) {
                if (msg_4.fields[0].tag === 1) {
                    pattern_matching_result_2 = 27;
                    err_4 = msg_4.fields[0].fields[0];
                }
                else {
                    pattern_matching_result_2 = 25;
                    annotations = msg_4.fields[0].fields[0];
                }
            }
            else if (msg_4.tag === 33) {
                if (msg_4.fields[0].tag === 0) {
                    pattern_matching_result_2 = 46;
                }
                else {
                    pattern_matching_result_2 = 26;
                    err_3 = msg_4.fields[0].fields[0];
                }
            }
            else if (msg_4.tag === 24) {
                pattern_matching_result_2 = 28;
                state = msg_4.fields[0];
            }
            else if (msg_4.tag === 23) {
                pattern_matching_result_2 = 29;
                setter_1 = msg_4.fields[0];
            }
            else if (msg_4.tag === 25) {
                pattern_matching_result_2 = 30;
            }
            else if (msg_4.tag === 34) {
                pattern_matching_result_2 = 31;
            }
            else if (msg_4.tag === 35) {
                pattern_matching_result_2 = 32;
                image = msg_4.fields[0];
            }
            else if (msg_4.tag === 36) {
                pattern_matching_result_2 = 33;
                annotation_6 = msg_4.fields[0];
            }
            else if (msg_4.tag === 37) {
                pattern_matching_result_2 = 34;
                annotationUID_2 = msg_4.fields[0];
            }
            else if (msg_4.tag === 38) {
                pattern_matching_result_2 = 35;
                sortInfo_2 = msg_4.fields[0];
            }
            else if (msg_4.tag === 39) {
                pattern_matching_result_2 = 36;
                sortInfo_3 = msg_4.fields[0];
            }
            else if (msg_4.tag === 40) {
                pattern_matching_result_2 = 37;
                viewportIndex_7 = msg_4.fields[0];
            }
            else if (msg_4.tag === 41) {
                pattern_matching_result_2 = 38;
                viewportIndex_8 = msg_4.fields[0];
            }
            else if (msg_4.tag === 42) {
                pattern_matching_result_2 = 39;
                viewportIndex_9 = msg_4.fields[0];
            }
            else if (msg_4.tag === 43) {
                pattern_matching_result_2 = 40;
                viewportIndex_10 = msg_4.fields[0];
            }
            else if (msg_4.tag === 26) {
                pattern_matching_result_2 = 41;
                canvasInfo = msg_4.fields[0];
            }
            else if (msg_4.tag === 27) {
                pattern_matching_result_2 = 42;
                setter_2 = msg_4.fields[0];
            }
            else if (msg_4.tag === 30) {
                pattern_matching_result_2 = 43;
            }
            else if (msg_4.tag === 31) {
                pattern_matching_result_2 = 44;
                s3Keys = msg_4.fields[1];
                screenshotId_1 = msg_4.fields[0];
            }
            else if (msg_4.tag === 32) {
                pattern_matching_result_2 = 45;
            }
            else if (msg_4.tag === 28) {
                pattern_matching_result_2 = 47;
                isLoading_1 = msg_4.fields[0];
            }
            else if (msg_4.tag === 29) {
                pattern_matching_result_2 = 48;
            }
            else if (msg_4.tag === 44) {
                pattern_matching_result_2 = 49;
                maybeViewportIndex_1 = msg_4.fields[0];
            }
            else {
                pattern_matching_result_2 = 0;
                e = msg_4.fields[0];
            }
            switch (pattern_matching_result_2) {
                case 0: {
                    console.error(some(`Error: ${e}`));
                    return [model_1, Cmd_none()];
                }
                case 1: {
                    return (maybeS3Ctx == null) ? [model_1, Cmd_none()] : [model_1, Cmd_OfPromise_either((tupledArg_3) => Dicoms_loadAndParseFileList(tupledArg_3[0], tupledArg_3[1], tupledArg_3[2]), [model_1.patient.id, model_1.sessionId, maybeS3Ctx], (arg_8) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(2, new FSharpResult$2(0, arg_8)))), (arg_10) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(2, new FSharpResult$2(1, arg_10)))))];
                }
                case 2: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, parsedFileNamesList, map_1((viewport_2) => (new HistoricalViewport(viewport_2.selectedSequence, viewport_2.frameIndex, new LoadingState(1), viewport_2.loadedDicomFiles, viewport_2.thumbnailUrls, viewport_2.isPlaybackPaused)), model_1.viewports), model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.savingScreenshots, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState, model_1.screenshotDataURLs, model_1.screenshotInfo), Cmd_batch(toList(delay(() => append(map_1((selectedSequence) => Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(5, selectedSequence))), List_distinct(map_1((viewport_3) => viewport_3.selectedSequence, model_1.viewports), {
                        Equals: equals,
                        GetHashCode: safeHash,
                    })), delay(() => singleton_2(Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(3)))))))))];
                }
                case 3: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, map_1((viewport_4) => (new HistoricalViewport(viewport_4.selectedSequence, viewport_4.frameIndex, new LoadingState(4), viewport_4.loadedDicomFiles, viewport_4.thumbnailUrls, viewport_4.isPlaybackPaused)), model_1.viewports), model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.savingScreenshots, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState, model_1.screenshotDataURLs, model_1.screenshotInfo), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(0, err)))];
                }
                case 4: {
                    return [model_1, Cmd_OfAsyncWith_either((x_3) => {
                        Cmd_OfAsync_start(x_3);
                    }, (tupledArg_4) => FlaggedImages_loadFlaggedImagesPromise(tupledArg_4[0], tupledArg_4[1], tupledArg_4[2]), [model_1.sessionId, model_1.fileMetadata, token], (arg_11) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(4, arg_11))), (arg_14) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(4, new FSharpResult$2(1, new ErrorMessage(2, arg_14))))))];
                }
                case 5: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, meta, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.savingScreenshots, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState, model_1.screenshotDataURLs, model_1.screenshotInfo), Cmd_none()];
                }
                case 6: {
                    return [model_1, Cmd_OfFunc_result(new StateTreeMsg$4(1, err_1))];
                }
                case 7: {
                    if (maybeS3Ctx != null) {
                        const s3Ctx_2 = maybeS3Ctx;
                        return [model_1, Cmd_OfPromise_either(() => {
                            let pr_1;
                            const pr = map_1((frame_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                                const fullFilePath = `${model_1.patient.id}/${model_1.sessionId}/${frame_1.filePath}`;
                                return Dicoms_loadDicomFromS3(s3Ctx_2, fullFilePath).then((_arg2_1) => {
                                    const maybeBlob = _arg2_1;
                                    return Promise.resolve([new File(toArray_1(delay(() => {
                                        if (maybeBlob == null) {
                                            return empty_2();
                                        }
                                        else {
                                            return singleton_2(maybeBlob);
                                        }
                                    })), (`${frame_1.filePath}`)), fullFilePath]);
                                });
                            })), item_2(selectedSequence_1.sequenceIndex, item_2(selectedSequence_1.siteIndex, item_2(selectedSequence_1.locationIndex, model_1.fileMetadata.frames))));
                            pr_1 = (Promise.all(pr));
                            return pr_1.then(unzip);
                        }, void 0, (arg_17) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(6, new FSharpResult$2(0, arg_17), selectedSequence_1))), (arg_19) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(6, new FSharpResult$2(1, arg_19), selectedSequence_1))))];
                    }
                    else {
                        return [model_1, Cmd_none()];
                    }
                }
                case 8: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, map_1((viewport_5) => {
                        if (equals(viewport_5.selectedSequence, selectedSequence_2) && (!equals(viewport_5.loadingState, new LoadingState(3)))) {
                            return new HistoricalViewport(viewport_5.selectedSequence, viewport_5.frameIndex, new LoadingState(3), fileArray, ofArray(map_2((_arg16) => (void 0), fileArray)), viewport_5.isPlaybackPaused);
                        }
                        else {
                            return viewport_5;
                        }
                    }, model_1.viewports), model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.savingScreenshots, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState, model_1.screenshotDataURLs, model_1.screenshotInfo), Cmd_batch(ofArray([Cmd_OfAsyncWith_attempt((x_4) => {
                        Cmd_OfAsync_start(x_4);
                    }, securedApi(token).telemetry, new TelemetryAction(1, new FileOperationContext(0), ofArray(filePathsArray)), (arg_20) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(0, arg_20)))), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(7, selectedSequence_2))), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(21)))]))];
                }
                case 9: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, map_1((viewport_6) => {
                        if (equals(viewport_6.selectedSequence, selectedSequence_3)) {
                            return new HistoricalViewport(viewport_6.selectedSequence, viewport_6.frameIndex, new LoadingState(4), viewport_6.loadedDicomFiles, viewport_6.thumbnailUrls, viewport_6.isPlaybackPaused);
                        }
                        else {
                            return viewport_6;
                        }
                    }, model_1.viewports), model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.savingScreenshots, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState, model_1.screenshotDataURLs, model_1.screenshotInfo), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(0, err_2)))];
                }
                case 10: {
                    if (maybeS3Ctx == null) {
                        return [model_1, Cmd_none()];
                    }
                    else {
                        const s3Ctx_3 = maybeS3Ctx;
                        return [model_1, Cmd_ofSub((dispatch_1) => {
                            let pr_3;
                            const pr_2 = map_1((tupledArg_5) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                                const thumbPath = replace(`${model_1.patient.id}/${model_1.sessionId}/${tupledArg_5[1].filePath}`, ".dcm", "-thumbnail.jpg");
                                return Thumbnails_loadThumbnailFromS3(s3Ctx_3, thumbPath).then((_arg3_1) => {
                                    iterate_1((url) => {
                                        dispatch_1(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(8, tupledArg_5[0], url, selectedSequence_4)));
                                    }, toArray_2(_arg3_1));
                                    return Promise.resolve(thumbPath);
                                });
                            })), indexed(item_2(selectedSequence_4.sequenceIndex, item_2(selectedSequence_4.siteIndex, item_2(selectedSequence_4.locationIndex, model_1.fileMetadata.frames)))));
                            pr_3 = (Promise.all(pr_2));
                            void (pr_3.then((arg_23) => {
                                dispatch_1(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(9, arg_23)));
                            }));
                        })];
                    }
                }
                case 11: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, map_1((viewport_7) => {
                        if (equals(viewport_7.selectedSequence, selectedSequence_5)) {
                            return new HistoricalViewport(viewport_7.selectedSequence, viewport_7.frameIndex, viewport_7.loadingState, viewport_7.loadedDicomFiles, updateAt(i_4, url_1, viewport_7.thumbnailUrls), viewport_7.isPlaybackPaused);
                        }
                        else {
                            return viewport_7;
                        }
                    }, model_1.viewports), model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.savingScreenshots, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState, model_1.screenshotDataURLs, model_1.screenshotInfo), Cmd_none()];
                }
                case 12: {
                    return [model_1, Cmd_OfAsyncWith_attempt((x_5) => {
                        Cmd_OfAsync_start(x_5);
                    }, securedApi(token).telemetry, new TelemetryAction(1, new FileOperationContext(1), ofArray(filePathsArray_1)), (arg_24) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(0, arg_24))))];
                }
                case 13: {
                    return (equals(selectedSequence_6, item_2(viewportIndex_2, model_1.viewports).selectedSequence) && (frameIndex === item_2(viewportIndex_2, model_1.viewports).frameIndex)) ? [model_1, Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(42, viewportIndex_2)))] : [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, updateAt(viewportIndex_2, (inputRecord = item_2(viewportIndex_2, model_1.viewports), new HistoricalViewport(selectedSequence_6, clampFrameIndex(model_1, selectedSequence_6, frameIndex), new LoadingState(1), inputRecord.loadedDicomFiles, inputRecord.thumbnailUrls, inputRecord.isPlaybackPaused)), model_1.viewports), model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.savingScreenshots, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState, model_1.screenshotDataURLs, model_1.screenshotInfo), Cmd_batch(ofArray([Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(5, selectedSequence_6))), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(42, viewportIndex_2)))]))];
                }
                case 14: {
                    const clampedIndex = clampFrameIndex(model_1, item_2(viewportIndex_3, model_1.viewports).selectedSequence, frameIndex_1) | 0;
                    if (clampedIndex !== item_2(viewportIndex_3, model_1.viewports).frameIndex) {
                        customEvent(eventNames.SetFrame, new SetFrameEvent(viewportIndex_3, clampedIndex));
                        return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, updateAt(viewportIndex_3, (inputRecord_1 = item_2(viewportIndex_3, model_1.viewports), new HistoricalViewport(inputRecord_1.selectedSequence, clampedIndex, inputRecord_1.loadingState, inputRecord_1.loadedDicomFiles, inputRecord_1.thumbnailUrls, inputRecord_1.isPlaybackPaused)), model_1.viewports), model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.savingScreenshots, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState, model_1.screenshotDataURLs, model_1.screenshotInfo), Cmd_none()];
                    }
                    else {
                        return [model_1, Cmd_none()];
                    }
                }
                case 15: {
                    return [model_1, Cmd_batch(ofArray([Cmd_OfFunc_result(new StateTreeMsg$4(0, (tupledArg_6 = [viewportIndex_4, item_2(viewportIndex_4, model_1.viewports).frameIndex + ((delta > 0) ? 1 : -1)], new PatientHistoricalComparisonMsg(11, tupledArg_6[0], tupledArg_6[1])))), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(42, viewportIndex_4)))]))];
                }
                case 16: {
                    return [model_1, Cmd_batch(ofArray([Navigation_newUrl(pageHash(new Page(6, model_1.patient.id, void 0))), Cmd_OfFunc_result(new StateTreeMsg$4(4, new NavigationMsg$1(1)))]))];
                }
                case 17: {
                    const newViewports = initialize((layout.tag === 1) ? (2 * layout.fields[0]) : ((layout.tag === 2) ? (3 * layout.fields[0]) : 1), (i_5) => defaultArg(map((viewport_8) => (new HistoricalViewport(viewport_8.selectedSequence, viewport_8.frameIndex, new LoadingState(2), viewport_8.loadedDicomFiles, viewport_8.thumbnailUrls, viewport_8.isPlaybackPaused)), tryItem(i_5, model_1.viewports)), emptyViewport));
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, newViewports, model_1.maybeMaximizedViewportIndex, layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.savingScreenshots, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState, model_1.screenshotDataURLs, model_1.screenshotInfo), Cmd_batch(toList(delay(() => append(map_1((selectedSequence_8) => Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(5, selectedSequence_8))), List_distinct(map_1((viewport_9) => viewport_9.selectedSequence, newViewports), {
                        Equals: equals,
                        GetHashCode: safeHash,
                    })), delay(() => mapIndexed((i_8, _arg17) => Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(42, i_8))), newViewports))))))];
                }
                case 18: {
                    const viewport_10 = item_2(viewportIndex_5, model_1.viewports);
                    const vm = new SessionAnnotationViewModel(model_1.sessionId, annotation_2.annotationUID, annotation_2.annotationData, viewport_10.selectedSequence.locationIndex, viewport_10.selectedSequence.siteIndex, viewport_10.selectedSequence.sequenceIndex, void 0, annotation_2.toolName, annotation_2.measurements);
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, append_1(model_1.sessionAnnotations, singleton_1(vm)), model_1.loadingAnnotations, true, model_1.savingScreenshots, model_1.loadingScreenshots, vm, new SessionAnnotationNoteState(0), model_1.screenshotDataURLs, model_1.screenshotInfo), Cmd_batch(ofArray([Cmd_OfAsyncWith_either((x_7) => {
                        Cmd_OfAsync_start(x_7);
                    }, securedApi(token).upsertSessionAnnotation, vm, (arg_26) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(16, arg_26))), (arg_27) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(0, arg_27)))), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(42, viewportIndex_5)))]))];
                }
                case 19: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotations, model_1.loadingAnnotations, false, model_1.savingScreenshots, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState, model_1.screenshotDataURLs, model_1.screenshotInfo), Cmd_none()];
                }
                case 20: {
                    const viewport_11 = item_2(viewportIndex_6, model_1.viewports);
                    let vm_1;
                    const matchValue_8 = [tryFind((sa) => (sa.annotationId === annotation_3.annotationUID), model_1.sessionAnnotations), model_1.selectedAnnotation];
                    let pattern_matching_result_3, a_10;
                    if (matchValue_8[0] == null) {
                        if (matchValue_8[1] != null) {
                            if (annotation_3.annotationUID === matchValue_8[1].annotationId) {
                                pattern_matching_result_3 = 0;
                                a_10 = matchValue_8[1];
                            }
                            else {
                                pattern_matching_result_3 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_3 = 1;
                        }
                    }
                    else if (annotation_3.annotationUID === matchValue_8[0].annotationId) {
                        pattern_matching_result_3 = 0;
                        a_10 = matchValue_8[0];
                    }
                    else {
                        pattern_matching_result_3 = 1;
                    }
                    switch (pattern_matching_result_3) {
                        case 0: {
                            vm_1 = (new SessionAnnotationViewModel(model_1.sessionId, annotation_3.annotationUID, annotation_3.annotationData, a_10.locationIndex, a_10.siteIndex, a_10.sequenceIndex, map((s_16) => s_16.trim(), a_10.note), map((s_17) => s_17.trim(), a_10.annotationName), annotation_3.measurements));
                            break;
                        }
                        case 1: {
                            vm_1 = (new SessionAnnotationViewModel(model_1.sessionId, annotation_3.annotationUID, annotation_3.annotationData, viewport_11.selectedSequence.locationIndex, viewport_11.selectedSequence.siteIndex, viewport_11.selectedSequence.sequenceIndex, void 0, void 0, annotation_3.measurements));
                            break;
                        }
                    }
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotations, model_1.loadingAnnotations, true, model_1.savingScreenshots, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState, model_1.screenshotDataURLs, model_1.screenshotInfo), Cmd_batch(ofArray([Cmd_OfAsyncWith_either((x_8) => {
                        Cmd_OfAsync_start(x_8);
                    }, securedApi(token).upsertSessionAnnotation, vm_1, (arg_29) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(18, arg_29))), (arg_30) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(0, arg_30)))), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(42, viewportIndex_6)))]))];
                }
                case 21: {
                    return [(matchValue_9 = tryFind((sa_1) => (sa_1.annotationId === annotation_4.annotationId), model_1.sessionAnnotations), (matchValue_9 == null) ? (new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotations, model_1.loadingAnnotations, false, model_1.savingScreenshots, model_1.loadingScreenshots, annotation_4, model_1.sessionAnnotationNoteState, model_1.screenshotDataURLs, model_1.screenshotInfo)) : (new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotations, model_1.loadingAnnotations, false, model_1.savingScreenshots, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState, model_1.screenshotDataURLs, model_1.screenshotInfo))), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(21)))];
                }
                case 22: {
                    return [model_1, Cmd_batch(toList(delay(() => append(singleton_2(Cmd_OfAsyncWith_either((x_9) => {
                        Cmd_OfAsync_start(x_9);
                    }, securedApi(token).deleteSessionAnnotation, [model_1.sessionId, annotationUID], (arg_32) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(20, arg_32))), (arg_33) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(0, arg_33))))), delay(() => mapIndexed((i_9, _arg18) => Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(42, i_9))), model_1.viewports))))))];
                }
                case 23: {
                    customEvent(eventNames.RemoveAnnotation, deletedAnnotationUID);
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, filter((sa_2) => (sa_2.annotationId !== deletedAnnotationUID), model_1.sessionAnnotations), model_1.loadingAnnotations, model_1.savingAnnotations, model_1.savingScreenshots, model_1.loadingScreenshots, model_1.selectedAnnotation, new SessionAnnotationNoteState(2), model_1.screenshotDataURLs, model_1.screenshotInfo), Cmd_none()];
                }
                case 24: {
                    return [model_1, Cmd_OfAsyncWith_either((x_10) => {
                        Cmd_OfAsync_start(x_10);
                    }, securedApi(token).getAllSessionAnnotationsForSessionId, model_1.sessionId, (arg_35) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(22, arg_35))), (arg_36) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(0, arg_36))))];
                }
                case 25: {
                    const matchValue_10 = model_1.selectedAnnotation;
                    if (matchValue_10 != null) {
                        const selectedAnnotation_2 = matchValue_10;
                        const node = document.getElementById(selectedAnnotation_2.annotationId);
                        if (equals(node, null)) {
                        }
                        else {
                            node.scrollIntoView();
                        }
                    }
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, annotations, false, model_1.savingAnnotations, model_1.savingScreenshots, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState, model_1.screenshotDataURLs, model_1.screenshotInfo), Cmd_none()];
                }
                case 26: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, false, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState, model_1.screenshotDataURLs, model_1.screenshotInfo), error(ErrorMessage_get_describe()(err_3))];
                }
                case 27: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotations, false, model_1.savingAnnotations, model_1.savingScreenshots, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState, model_1.screenshotDataURLs, model_1.screenshotInfo), error(ErrorMessage_get_describe()(err_4))];
                }
                case 28: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.savingScreenshots, model_1.loadingScreenshots, model_1.selectedAnnotation, state, model_1.screenshotDataURLs, model_1.screenshotInfo), Cmd_none()];
                }
                case 29: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.savingScreenshots, model_1.loadingScreenshots, map(setter_1, model_1.selectedAnnotation), model_1.sessionAnnotationNoteState, model_1.screenshotDataURLs, model_1.screenshotInfo), Cmd_none()];
                }
                case 30: {
                    const matchValue_11 = model_1.selectedAnnotation;
                    if (matchValue_11 == null) {
                        return [model_1, Cmd_none()];
                    }
                    else {
                        const annotation_5 = matchValue_11;
                        customEvent(eventNames.SetAnnotationText, {
                            annotationText: defaultArg(annotation_5.note, ""),
                            annotationUID: annotation_5.annotationId,
                        });
                        return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, map_1((sa_3) => {
                            if (sa_3.annotationId === annotation_5.annotationId) {
                                return annotation_5;
                            }
                            else {
                                return sa_3;
                            }
                        }, model_1.sessionAnnotations), model_1.loadingAnnotations, model_1.savingAnnotations, model_1.savingScreenshots, model_1.loadingScreenshots, model_1.selectedAnnotation, new SessionAnnotationNoteState(2), model_1.screenshotDataURLs, model_1.screenshotInfo), Cmd_batch(toList(delay(() => append(singleton_2(Cmd_OfAsyncWith_either((x_11) => {
                            Cmd_OfAsync_start(x_11);
                        }, securedApi(token).upsertSessionAnnotation, annotation_5, (arg_38) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(18, arg_38))), (arg_39) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(0, arg_39))))), delay(() => mapIndexed((i_10, _arg19) => Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(42, i_10))), model_1.viewports))))))];
                    }
                }
                case 31: {
                    return [model_1, Cmd_OfFunc_result(new StateTreeMsg$4(4, new NavigationMsg$1(5, "Report")))];
                }
                case 32: {
                    return [model_1, Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(10, 0, new SelectedSequence(image.locationIndex, image.siteIndex, image.sequenceIndex), image.frameIndex)))];
                }
                case 33: {
                    let targetIndex;
                    const matchValue_12 = match(create("\"sliceIndex\":(\\d+)"), annotation_6.annotationData);
                    targetIndex = ((matchValue_12.length === 2) ? parse(matchValue_12[1] || "", 511, false, 32) : 0);
                    customEvent(eventNames.SelectAnnotation, annotation_6.annotationId);
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.savingScreenshots, model_1.loadingScreenshots, annotation_6, model_1.sessionAnnotationNoteState, model_1.screenshotDataURLs, model_1.screenshotInfo), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(10, 0, new SelectedSequence(annotation_6.locationIndex, annotation_6.siteIndex, annotation_6.sequenceIndex), targetIndex)))];
                }
                case 34: {
                    const maybeAnnotationID = isNullOrWhiteSpace(annotationUID_2) ? (void 0) : annotationUID_2;
                    const node_1 = document.getElementById(`${annotationUID_2}`);
                    if (equals(node_1, null)) {
                    }
                    else {
                        node_1.scrollIntoView();
                    }
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.savingScreenshots, model_1.loadingScreenshots, tryFind((sa_4) => equals(sa_4.annotationId, maybeAnnotationID), model_1.sessionAnnotations), model_1.sessionAnnotationNoteState, model_1.screenshotDataURLs, model_1.screenshotInfo), Cmd_batch(mapIndexed((i_11, _arg20) => Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(42, i_11))), model_1.viewports))];
                }
                case 35: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, sortInfo_2, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.savingScreenshots, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState, model_1.screenshotDataURLs, model_1.screenshotInfo), Cmd_none()];
                }
                case 36: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, sortInfo_3, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.savingScreenshots, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState, model_1.screenshotDataURLs, model_1.screenshotInfo), Cmd_none()];
                }
                case 37: {
                    const viewport_12 = item_2(viewportIndex_7, model_1.viewports);
                    const flaggedImageListItem = FlaggedImages_mkFlaggedImageListItemFromSelected(model_1.fileMetadata, viewport_12.selectedSequence, viewport_12.frameIndex);
                    const maybeFrameNumber = FlaggedImages_tryGetFrameNumber(model_1.fileMetadata, flaggedImageListItem, viewport_12.frameIndex);
                    if (maybeFrameNumber == null) {
                        return [model_1, error("Failed to toggle image flag, something went wrong")];
                    }
                    else {
                        const frameNumber = maybeFrameNumber | 0;
                        return (!item_2(viewport_12.frameIndex, item_2(viewport_12.selectedSequence.sequenceIndex, item_2(viewport_12.selectedSequence.siteIndex, item_2(viewport_12.selectedSequence.locationIndex, model_1.fileMetadata.frames)))).flagged) ? [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, (inputRecord_2 = model_1.fileMetadata, new FileMetadata(inputRecord_2.locationNames, inputRecord_2.siteNames, inputRecord_2.sequenceNames, FlaggedImages_flagFrame(true, model_1.fileMetadata.frames, viewport_12.selectedSequence.locationIndex, viewport_12.selectedSequence.siteIndex, viewport_12.selectedSequence.sequenceIndex, viewport_12.frameIndex), cons(flaggedImageListItem, model_1.fileMetadata.flaggedImages), inputRecord_2.frameToSequenceMap)), model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.savingScreenshots, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState, model_1.screenshotDataURLs, model_1.screenshotInfo), Cmd_OfAsyncWith_attempt((x_12) => {
                            Cmd_OfAsync_start(x_12);
                        }, securedApi(token).flagSessionImage, [model_1.sessionId, frameNumber], (arg_41) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(0, arg_41))))] : [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, (inputRecord_3 = model_1.fileMetadata, new FileMetadata(inputRecord_3.locationNames, inputRecord_3.siteNames, inputRecord_3.sequenceNames, FlaggedImages_flagFrame(false, model_1.fileMetadata.frames, viewport_12.selectedSequence.locationIndex, viewport_12.selectedSequence.siteIndex, viewport_12.selectedSequence.sequenceIndex, viewport_12.frameIndex), defaultArg(map((i_12) => removeAt(i_12, model_1.fileMetadata.flaggedImages), tryFindIndex((f_2) => {
                            if (((f_2.locationIndex === viewport_12.selectedSequence.locationIndex) && (f_2.siteIndex === viewport_12.selectedSequence.siteIndex)) && (f_2.sequenceIndex === viewport_12.selectedSequence.sequenceIndex)) {
                                return f_2.frameIndex === viewport_12.frameIndex;
                            }
                            else {
                                return false;
                            }
                        }, model_1.fileMetadata.flaggedImages)), model_1.fileMetadata.flaggedImages), inputRecord_3.frameToSequenceMap)), model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.savingScreenshots, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState, model_1.screenshotDataURLs, model_1.screenshotInfo), Cmd_OfAsyncWith_attempt((x_13) => {
                            Cmd_OfAsync_start(x_13);
                        }, securedApi(token).unflagSessionImage, [model_1.sessionId, frameNumber], (arg_43) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(0, arg_43))))];
                    }
                }
                case 38: {
                    return item_2(viewportIndex_8, model_1.viewports).isPlaybackPaused ? [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, updateAt(viewportIndex_8, (inputRecord_4 = item_2(viewportIndex_8, model_1.viewports), new HistoricalViewport(inputRecord_4.selectedSequence, inputRecord_4.frameIndex, inputRecord_4.loadingState, inputRecord_4.loadedDicomFiles, inputRecord_4.thumbnailUrls, false)), model_1.viewports), model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.savingScreenshots, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState, model_1.screenshotDataURLs, model_1.screenshotInfo), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(43, viewportIndex_8)))] : [model_1, Cmd_none()];
                }
                case 39: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, updateAt(viewportIndex_9, (inputRecord_5 = item_2(viewportIndex_9, model_1.viewports), new HistoricalViewport(inputRecord_5.selectedSequence, inputRecord_5.frameIndex, inputRecord_5.loadingState, inputRecord_5.loadedDicomFiles, inputRecord_5.thumbnailUrls, true)), model_1.viewports), model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.savingScreenshots, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState, model_1.screenshotDataURLs, model_1.screenshotInfo), Cmd_none()];
                }
                case 40: {
                    const viewport_14 = item_2(viewportIndex_10, model_1.viewports);
                    const newIndex_1 = (viewport_14.frameIndex + 1) | 0;
                    return (viewport_14.isPlaybackPaused ? true : (newIndex_1 > (length(item_2(viewport_14.selectedSequence.sequenceIndex, item_2(viewport_14.selectedSequence.siteIndex, item_2(viewport_14.selectedSequence.locationIndex, model_1.fileMetadata.frames)))) - 1))) ? [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, updateAt(viewportIndex_10, (inputRecord_6 = item_2(viewportIndex_10, model_1.viewports), new HistoricalViewport(inputRecord_6.selectedSequence, inputRecord_6.frameIndex, inputRecord_6.loadingState, inputRecord_6.loadedDicomFiles, inputRecord_6.thumbnailUrls, true)), model_1.viewports), model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.savingScreenshots, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState, model_1.screenshotDataURLs, model_1.screenshotInfo), Cmd_none()] : [model_1, Cmd_batch(ofArray([Cmd_OfFunc_result(new StateTreeMsg$4(0, (tupledArg_7 = [viewportIndex_10, newIndex_1], new PatientHistoricalComparisonMsg(11, tupledArg_7[0], tupledArg_7[1])))), Cmd_OfAsyncWith_result((x_14) => {
                        Cmd_OfAsync_start(x_14);
                    }, playbackTick(viewportIndex_10))]))];
                }
                case 41: {
                    const canvasMap = ofList(map_1((ci) => [ci.viewportIndex, ci.canvasData], canvasInfo));
                    const defaultDescription = join("\n", map_1((arg_45) => getFrameInformationString(model_1, arg_45[0]), toList_1(canvasMap)));
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.savingScreenshots, false, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState, canvasMap, (inputRecord_7 = model_1.screenshotInfo, new ScreenshotInfoViewModel(inputRecord_7.screenshotId, inputRecord_7.sessionId, `${model_1.sessionName} - ${utcNow()}`, defaultDescription, inputRecord_7.s3Keys))), Cmd_none()];
                }
                case 42: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.savingScreenshots, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState, model_1.screenshotDataURLs, setter_2(model_1.screenshotInfo)), Cmd_none()];
                }
                case 43: {
                    const matchValue_15 = [maybeS3Ctx, model_1.screenshotDataURLs, model_1.screenshotInfo];
                    let pattern_matching_result_4, ctx_1, urls_1;
                    if (matchValue_15[2].name == null) {
                        pattern_matching_result_4 = 0;
                    }
                    else if (matchValue_15[0] != null) {
                        if ((ctx = matchValue_15[0], !FSharpMap__get_IsEmpty(matchValue_15[1]))) {
                            pattern_matching_result_4 = 1;
                            ctx_1 = matchValue_15[0];
                            urls_1 = matchValue_15[1];
                        }
                        else {
                            pattern_matching_result_4 = 2;
                        }
                    }
                    else {
                        pattern_matching_result_4 = 2;
                    }
                    switch (pattern_matching_result_4) {
                        case 0: {
                            return [model_1, error("Screenshot name is required")];
                        }
                        case 1: {
                            const screenshotId = newGuid();
                            const fileNames = map_1((tupledArg_8) => (`${model_1.patient.id}/${model_1.sessionId}/Reports/Screenshots/${screenshotId}-${tupledArg_8[0]}.png`), toList_1(urls_1));
                            return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, true, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState, model_1.screenshotDataURLs, model_1.screenshotInfo), Cmd_OfPromise_either(() => {
                                const pr_5 = map_1((tupledArg_9) => {
                                    const fileName_1 = (!isEmpty(fileNames)) ? (isEmpty(tail(fileNames)) ? head(fileNames) : item_2(tupledArg_9[0], fileNames)) : item_2(tupledArg_9[0], fileNames);
                                    const pr_4 = dataUrlToBlob(tupledArg_9[1]);
                                    return pr_4.then((blob_1) => {
                                        let upload;
                                        const arg00_2 = S3_PutObjectRequestParams_get_create()(ofArray([new S3_PutObjectRequestParams(2, ctx_1.ActiveBucket), new S3_PutObjectRequestParams(3, fileName_1), new S3_PutObjectRequestParams(1, blob_1)]));
                                        upload = ctx_1.S3Client.upload(arg00_2);
                                        return upload.promise();
                                    });
                                }, toList_1(urls_1));
                                return Promise.all(pr_5);
                            }, void 0, (_arg22) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(31, screenshotId, fileNames))), (arg_46) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(0, arg_46))))];
                        }
                        case 2: {
                            return [model_1, Cmd_none()];
                        }
                    }
                }
                case 44: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.savingScreenshots, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState, model_1.screenshotDataURLs, (inputRecord_8 = model_1.screenshotInfo, new ScreenshotInfoViewModel(screenshotId_1, inputRecord_8.sessionId, inputRecord_8.name, inputRecord_8.description, s3Keys))), Cmd_OfFunc_result(new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(32)))];
                }
                case 45: {
                    let vm_2;
                    const inputRecord_9 = model_1.screenshotInfo;
                    vm_2 = (new ScreenshotInfoViewModel(inputRecord_9.screenshotId, model_1.sessionId, map((n) => n.trim(), model_1.screenshotInfo.name), map((d) => d.trim(), model_1.screenshotInfo.description), inputRecord_9.s3Keys));
                    return [model_1, Cmd_OfAsyncWith_either((x_15) => {
                        Cmd_OfAsync_start(x_15);
                    }, securedApi(token).createScreenshotInfo, vm_2, (arg_47) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(33, arg_47))), (arg_48) => (new StateTreeMsg$4(0, new PatientHistoricalComparisonMsg(0, arg_48))))];
                }
                case 46: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, false, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState, empty_1(), model_1.screenshotInfo), Cmd_none()];
                }
                case 47: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.savingScreenshots, isLoading_1, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState, model_1.screenshotDataURLs, model_1.screenshotInfo), Cmd_none()];
                }
                case 48: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, model_1.viewports, model_1.maybeMaximizedViewportIndex, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.savingScreenshots, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState, empty_1(), model_1.screenshotInfo), Cmd_none()];
                }
                case 49: {
                    return [new PatientHistoricalComparisonModel(model_1.patient, model_1.sessionId, model_1.sessionName, model_1.fileMetadata, model_1.viewports, maybeViewportIndex_1, model_1.layout, model_1.flaggedImagesSortInfo, model_1.sessionAnnotationsSortInfo, model_1.sessionAnnotations, model_1.loadingAnnotations, model_1.savingAnnotations, model_1.savingScreenshots, model_1.loadingScreenshots, model_1.selectedAnnotation, model_1.sessionAnnotationNoteState, model_1.screenshotDataURLs, model_1.screenshotInfo), Cmd_none()];
                }
            }
        });
    })));
}

